<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="21px"
        viewBox="0 96 960 960"
        width="21px"
        fill="#000000"
    >
        <path
            d="m624.37 769.957 49.065-48.587L515.63 562.51V363.957h-65.26v224.894l174 181.106ZM480.033 981.978q-83.468 0-157.514-31.946t-129.32-87.211q-55.274-55.265-87.226-129.3-31.951-74.034-31.951-157.495t31.998-157.534q31.998-74.073 87.199-129.273 55.2-55.201 129.251-87.319 74.05-32.118 157.53-32.118T637.53 201.9q74.051 32.118 129.251 87.319 55.201 55.2 87.319 129.251 32.118 74.05 32.118 157.53T854.1 733.53q-32.118 74.051-87.319 129.251-55.2 55.201-129.24 87.199-74.04 31.998-157.508 31.998ZM480 576Zm-.122 337.848q139.165 0 238.568-99.163 99.402-99.163 99.402-238.565 0-139.403-99.371-238.685-99.37-99.283-238.475-99.283-139.285 0-238.567 99.253Q142.152 436.658 142.152 576q0 139.522 99.281 238.685 99.28 99.163 238.445 99.163Z"
        />
    </svg>
</template>

<script>
export default {
    name: 'ClockSvg'
}
</script>

<style scoped></style>

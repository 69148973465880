<template>
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center position-relative">
            <GroupsSvg class="field-icon" />
            <div v-if="numPpl" class="d-flex">
                <NumberIcon style="top: -7px; right: -7px;">
                    {{ numPpl }}
                </NumberIcon>
            </div>
        </div>
    </div>
</template>

<script>
import NumberIcon from '@/components/item/NumberIcon'
import GroupsSvg from '@/components/iconSvg/GroupsSvg.vue'

export default {
    name: 'OccupantCount',
    components: {
        GroupsSvg,
        NumberIcon
    },
    props: {
        numPpl: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>
.field-icon {
    color: #b5b3ab;
    fill: #b5b3ab;
}
</style>

<template>
    <transition name="modal">
        <div @click="close" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div
                        @click.stop
                        class="d-flex justify-content-center m-auto"
                        style="max-width: 500px;"
                    >
                        <div
                            class="bg-light cal-container text-center py-4 px-4"
                        >
                            <div class="text-20 text-dark text-bold mt-3">
                                {{ uspTagInfoButton?.popup_title }}
                            </div>
                            <ImageContainer
                                :src="uspTagInfoButton?.popup_image"
                                :alt="uspTagInfoButton?.popup_title"
                                height="60"
                                class="mt-4"
                            />
                            <div
                                class="text-14 text-606060 mt-4 text-left text-lg-center px-lg-4"
                            >
                                {{ uspTagInfoButton?.popup_body }}
                            </div>
                            <a
                                :href="uspTagInfoButton?.popup_button?.url"
                                target="_blank"
                                :aria-label="uspTagInfoButton?.popup_button?.text"
                                class="text-decoration-none"
                            >
                                <BookButton
                                    :book-button-text="uspTagInfoButton?.popup_button?.text"
                                    :button-class="'btn-yellow'"
                                    class="mt-4"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import BookButton from '@/components/item/BookButton.vue'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'BestPriceGuaranteeModal',
    components: {ImageContainer, BookButton },
    props: {
        uspTagInfoButton: {
            type: Object,
            required: false,
            default() {
                return {}
            }
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1038;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    transition: all 0.3s ease;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.cal-container {
    position: relative;
    border-radius: 10px;
}
/*.close-btn {
    font-size: 22px;
    position: absolute;
    right: -50px;
    top: 0;
    transform: rotate(45deg);
}*/

@media (max-width: 767.98px) {
    .cal-container {
        position: relative;
        border-radius: 10px;
        width: 90vw;
    }
/*    .close-btn {
        font-size: 22px;
        position: absolute;
        right: 0;
        top: -50px;
        transform: rotate(45deg);
    }*/
    .modal-wrapper {
        vertical-align: middle;
    }
}
</style>

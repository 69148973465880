<template>
    <div class="d-flex justify-content-between w-100 header-section pt-4 pb-3">
        <slot />
    </div>
</template>
<script>
export default {
    name: 'HeaderSection'
}
</script>
<style scoped>
@media (min-width: 992px) {
    .header-section {
        padding: 62px 0 22px !important;
    }
}
</style>

<template>
    <div>
        <div
            style="height: 28px;"
            class="d-flex align-items-center my-2 mt-lg-3 mb-1"
        >
            <div v-if="isRetrieveLocation" class="d-flex align-items-center">
                <b-spinner variant="primary" small />
                <span class="text-15 text-1e1e1e ml-2">
                    {{ $t('getting_location_information') }}
                </span>
            </div>
        </div>
        <SwiperContainer
            v-if="buttons.length > 0"
            :is-free-mode="true"
            :is-set-slides-per-view="true"
            name="fast-search-buttons"
            :width-adjustment="8"
        >
            <div
                v-for="(item, index) in buttons"
                :id="`-fast-search-buttons-swiper-slide`"
                :key="`fast-search-button-${index}`"
                class="swiper-slide card-item mt-lg-2"
                style="cursor: pointer;"
            >
                <client-only>
                    <NuxtLink
                        :to="computeFastButtonLink(item)"
                        :class="{ 'button-disabled': isRetrieveLocation || isBlockGooglebotFromButton }"
                        style="text-decoration: none;"
                    >
                        <div
                            class="position-relative"
                            @click.stop="onButtonClicked(item, $event, index)"
                        >
                            <ImageContainer
                                class="d-block d-lg-none button-image"
                                width="490"
                                height="300"
                                :alt="item.text"
                                :src="item.image.url.mobile"
                            />
                            <ImageContainer
                                class="d-none d-lg-block button-image"
                                width="810"
                                height="390"
                                :alt="item.text"
                                :src="item.image.url.desktop"
                            />
                            <p
                                class="position-absolute text-s-bold text-3c3c3c mx-sm-3 mt-sm-2 button-title"
                            >
                                {{ item.text }}
                            </p>
                            <p class="mt-1 mx-1 text-3c3c3c space-title">
                                {{
                                    $t(`home.${getSpaceTypeByNum(item.space_type)}`)
                                }}
                            </p>
                        </div>
                    </NuxtLink>
                    <template #placeholder>
                        <b-skeleton-wrapper :loading="true">
                            <template #loading>
                                <b-skeleton-img class="h-100 button-image"></b-skeleton-img>
                            </template>
                        </b-skeleton-wrapper>
                    </template>
                </client-only>
            </div>
        </SwiperContainer>
        <SearchPeriodModal
            v-if="isShowPeriodPrompt"
            @continueWithPeriodSet="continueWithPeriodSet"
            @closePeriodModal="isShowPeriodPrompt = false"
        />
    </div>
</template>

<script>
import analysis from '@/services/analysis'
import SwiperContainer from '@/components/ui/SwiperContainer'
import SearchPeriodModal from '@/components/modal/SearchPeriodModal'
import {DATE_TIME, QUERY_SOURCE} from '@/lib-flow-main-search/src/constants'
import { isBefore8pm } from '@/lib-flow-main-search/src/utils'
import { getRegionNameForLocationSeo, isWithinRegion } from '@/utils/locale'
import dateServices from '@/services/dateServices'
import ImageContainer from "@/components/ui/ImageContainer.vue";
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'FastSearchSection',
    components: {ImageContainer, SwiperContainer, SearchPeriodModal },
    props: {
        buttons: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 2.5,
                spaceBetween: 10,
                breakpoints: {
                    320: {
                        slidesPerView: 3.2,
                        spaceBetween: 5
                    },
                    400: {
                        slidesPerView: 3.2,
                        spaceBetween: 5
                    },
                    576: {
                        slidesPerView: 3.2,
                        spaceBetween: 5
                    },
                    660: {
                        slidesPerView: 3.2,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 3.2,
                        spaceBetween: 10
                    },
                    850: {
                        slidesPerView: 3.3,
                        spaceBetween: 10
                    },
                    992: {
                        slidesPerView: 3.5,
                        spaceBetween: 10
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 20
                    }
                }
            },
            locFilterKey: undefined,
            tempRouteObj: '',
            isRetrieveLocation: false,
            tempItem: undefined,
            isShowPeriodPrompt: false,
            selectedPeriods: [],
            btnPosition: 0,
            btnType: ''
        }
    },
    computed: {
        source() {
            return getGAEventName(this.landingPageName, 'fast button', this.btnType, this.btnPosition)
        }
    },
    methods: {
        computeFastButtonLink(item) {
            const spaceType = this.getGlobalSpaceTypeByNum(item.space_type)
            const query = {
                date: item.date,
                time: item.time,
                qs: QUERY_SOURCE.FAST_SEARCH_BUTTON
            }
            if (this.paramsRegion === 'vn' && this.isOverride) {
                query.override = true
            }
            if (item.filters) {
                for (const [key, value] of Object.entries(item.filters)) {
                    if (
                        (key === 'user_lat_long' ||
                            key === 'user_lat_long_optional') &&
                        value
                    ) {
                        // no-op
                    } else {
                        query[key] = value
                    }
                }
            }
            return {
                name: 'region-locale-space',
                params: {
                    space: spaceType,
                    source: this.source
                },
                query
            }
        },
        onButtonClicked(item, event, idx) {
            this.btnType = item.type
            this.tempItem = item
            this.btnPosition = idx
            event.preventDefault()
            if (item.is_request_period) {
                this.$store.dispatch('general/UPDATE_PERIOD_MODAL_DATE', { date: item.date })
                this.$store.dispatch('general/UPDATE_PERIOD_MODAL_SERVICE_TYPE', { type: 'rest' })
                this.isShowPeriodPrompt = true
            } else {
                this.requestLocationIfNeeded(item, event)
            }
        },
        async successHandler(position) {
            this.isRetrieveLocation = false
            if (
                isWithinRegion(
                    this.paramsRegion,
                    position.coords.latitude,
                    position.coords.longitude
                )
            ) {
                this.tempRouteObj.query.user_lat_long = `${position.coords.latitude},${position.coords.longitude}`
            } else {
                await this.okPopUpModal(
                    this.$t('fast_search.message_outside_region', {
                        region: getRegionNameForLocationSeo(
                            this.paramsRegion,
                            this.paramsLang
                        )
                    }),
                    this.$t('fast_search.title_outside_region', {
                        region: getRegionNameForLocationSeo(
                            this.paramsRegion,
                            this.paramsLang
                        )
                    })
                )
            }
            this.updateTimeQueryIfNeeded()
            await this.$router.push(this.tempRouteObj)
            this.locFilterKey = undefined
        },
        async errorHandler(error) {
            this.isRetrieveLocation = false
            let message = ''
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    message = this.$t('fast_search.message_no_location')
                    break
                case error.POSITION_UNAVAILABLE:
                    message = this.$t('fast_search.message_no_location')
                    break
                case error.TIMEOUT:
                    message = this.$t('err_gps_timeout')
                    break
                case error.UNKNOWN_ERROR:
                default:
                    message = this.$t('err_gps_unknown')
                    break
            }
            if (this.locFilterKey === 'user_lat_long_optional') {
                this.updateTimeQueryIfNeeded()
                await this.$router.push(this.tempRouteObj)
            } else {
                await this.okPopUpModal(
                    message,
                    this.$t('fast_search.title_no_location')
                )
                this.updateTimeQueryIfNeeded()
                await this.$router.push(this.tempRouteObj)
            }
            this.locFilterKey = undefined
        },
        continueWithPeriodSet(periods) {
            this.isShowPeriodPrompt = false
            this.selectedPeriods = periods
            this.requestLocationIfNeeded(this.tempItem, undefined)
        },
        requestLocationIfNeeded(item, event) {
            if (item.filters?.user_lat_long) {
                this.locFilterKey = 'user_lat_long'
            } else if (item.filters?.user_lat_long_optional) {
                this.locFilterKey = 'user_lat_long_optional'
            }
            if (this.locFilterKey !== undefined) {
                this.tempRouteObj = this.computeFastButtonLink(item)
                if (event) {
                    event.preventDefault()
                }
                if (navigator.geolocation) {
                    this.isRetrieveLocation = true
                    navigator.geolocation.getCurrentPosition(
                        this.successHandler,
                        this.errorHandler,
                        { enableHighAccuracy: true, maximumAge: 5000 }
                    )
                }
            } else {
                this.tempRouteObj = this.computeFastButtonLink(item)
                this.updateTimeQueryIfNeeded()
                this.$router.push(this.tempRouteObj)
            }
        },
        updateTimeQueryIfNeeded() {
            if (this.selectedPeriods.length > 0) {
                this.tempRouteObj.query.time = this.selectedPeriods.join(',')
                const now = new Date().valueOf()
                const today = dateServices.getToday()
                if (
                    this.tempRouteObj.query.date === today &&
                    !isBefore8pm(now, today, this.paramsRegion) &&
                    this.selectedPeriods.includes(DATE_TIME.MIDNIGHT_TIME)
                ) {
                    this.tempRouteObj.query.date = dateServices.getTomorrow()
                }
            }
        }
    }
}
</script>

<style scoped>
.swiper-slide {
    width: auto !important;
}
.button-disabled {
    pointer-events: none;
    filter: brightness(50%);
}
.button-image {
    object-fit: cover;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.button-title {
    margin: 0.3rem 0.4rem;
    top: 0;
}
@media (max-width: 576px) {
    .swiper-slide {
        height: 105px;
        margin-right: 5px;
        aspect-ratio: 1;
    }
    .button-title {
        font-size: 12px;
        line-height: 0.9rem;
    }
    .button-image {
        border-radius: 8px;
    }
    .space-title {
        font-size: 11px;
    }
}

@media (min-width: 577px) and (max-width: 991px) {
    .swiper-slide {
        height: 150px;
        margin-right: 10px;
        aspect-ratio: 285/233;
    }
    .space-title {
        font-size: 13px;
    }
    .button-image {
        border-radius: 15px;
    }
    .button-title {
        font-size: 16px;
    }
}
@media (min-width: 992px) {
    .swiper-slide {
        height: 165px;
        margin-right: 20px;
        aspect-ratio: 260/170;
    }
    .button-title {
        font-size: 17px;
    }
    .button-image {
        border-radius: 15px;
    }
    .space-title {
        font-size: 16px;
    }
}
</style>

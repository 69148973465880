<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        height="22px"
        width="22px"
        viewBox="0 0 25 25"
        style="enable-background:new 0 0 25 25; min-height: 22px; min-width: 22px;"
        xml:space="preserve"
    >
        <style type="text/css">
            .st0 {
                fill: #f2bc1f;
            }
            .st1 {
                fill: #ffffff;
            }
            .st2 {
                fill: #a9a9a9;
            }
        </style>
        <g>
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M0.3,24.9c-0.4-0.4-0.4-0.4-0.2-0.9c2.1-5.6,4.2-11.1,6.3-16.7c0-0.1,0.1-0.2,0.1-0.4C6.6,6.1,7.1,5.6,8,5.7
		C8.6,5.8,9.2,6,9.7,6.2c0.7,0.3,1.3,0.7,2,1.1C11.9,6.9,12,6.4,12,5.9c0.1-0.7,0-1.5-0.2-2.2c0-0.1-0.1-0.3,0-0.4
		c0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.4,0.1,0.5,0.4c0.2,0.7,0.3,1.4,0.3,2.1c0,0.8-0.2,1.5-0.5,2.3c0,0.1,0,0.2,0,0.3
		c0.7,0.7,1.5,1.3,2.2,1.9c0.8-0.4,1.5-0.8,2.3-1.1c2-0.8,4.1-0.8,6.3-0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0,0.4-0.1,0.5
		c-0.1,0.1-0.3,0.2-0.5,0.1c-0.6-0.1-1.2-0.3-1.8-0.3c-2.1-0.2-3.9,0.3-5.7,1.4l-0.1,0.1c0.4,0.5,0.9,1,1.3,1.5c0,0,0.2,0,0.3,0
		c1.4-0.5,2.8-0.6,4.3-0.2c0.4,0.1,0.6,0.4,0.4,0.7s-0.3,0.3-0.7,0.3c-0.9-0.3-1.9-0.3-2.9,0c-0.2,0.1-0.5,0.1-0.7,0.2
		c0.3,0.5,0.6,0.9,0.8,1.4c0.3,0.6,0.6,1.3,0.8,1.9c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.6-0.5,1-1.1,1.1c-0.3,0-0.6,0.1-0.9,0.2L1,24.7
		c-0.1,0-0.2,0.1-0.3,0.2H0.3z M1.3,23.6l5.1-1.9C6.1,20,6.7,18.9,8,18.4s2.4-0.1,3.5,1.4l1.8-0.7c-0.8-0.6-1.5-1.1-2.3-1.7
		c-0.4-0.3-0.5-0.6-0.3-0.8c0.2-0.3,0.5-0.2,0.9,0.1c0.9,0.6,1.8,1.3,2.7,1.9c0.1,0.1,0.2,0.1,0.3,0.1l1.5-0.5
		c-3.9-2.2-7-5.3-9.2-9.3c-0.2,0.4-0.3,0.9-0.5,1.3c-0.1,0.2-0.1,0.5,0.1,0.7c0.6,0.9,1.3,1.8,1.9,2.7c0.2,0.3,0.2,0.6,0,0.8
		S7.8,14.3,7.6,14c-0.5-0.7-1.1-1.5-1.6-2.2c0-0.1-0.1-0.1-0.2-0.2c-0.6,1.5-1.1,3-1.7,4.5l0.2,0.2c1.2,0.9,1.4,2.6,0.5,3.7
		c-0.5,0.7-1.4,1.1-2.3,1c-0.1,0-0.2,0-0.3,0.1C1.9,21.9,1.6,22.7,1.3,23.6z M11.3,8.3c-1-0.7-1.9-1.3-3-1.6c-0.8-0.2-1,0.1-0.8,0.8
		c0.2,0.8,0.6,1.4,1,2.1c1.7,2.6,3.9,4.8,6.4,6.5c0.8,0.6,1.6,1.1,2.6,1.3c0.7,0.2,0.9-0.1,0.7-0.7c-0.3-1.1-0.9-2.1-1.6-3.1
		l-1.1,0.9c-0.3,0.2-0.5,0.2-0.7,0s-0.2-0.5,0.1-0.7c0.4-0.3,0.8-0.7,1.2-1c-0.2-0.2-0.4-0.5-0.6-0.8l-0.7-0.7l-0.9,0.8
		c-0.2,0.2-0.5,0.2-0.7,0.1c-0.2-0.2-0.2-0.5,0-0.8c0.2-0.2,0.6-0.5,0.9-0.9l-2-1.8C12,9,11.9,9,11.8,9.1c-0.3,0.3-0.5,0.7-0.8,1
		c-0.1,0.1-0.3,0.2-0.5,0.1c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0.1-0.3,0.2-0.5C10.6,9,11,8.7,11.3,8.3z M10.5,20.2
		c-0.4-0.8-1.3-1.2-2.2-0.9c0,0,0,0,0,0c-0.8,0.4-1.2,1.3-0.9,2L10.5,20.2z M3.7,17.1c-0.4,1-0.7,1.9-1.1,2.9c0.6,0.1,1.4-0.4,1.6-1
		C4.5,18.4,4.3,17.5,3.7,17.1z"
            />
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M23.9,21c-0.5,0.3-0.9,0.6-1.4,0.9c-0.3,0.2-0.6,0.4-0.8,0.8c-0.2,0.4-0.4,0.7-0.6,1c-0.2,0.4-0.7,0.4-0.9,0
		c-0.3-0.4-0.4-0.7-0.6-1.1c-0.1-0.3-0.3-0.5-0.6-0.6c-0.4-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-0.5-0.7,0-0.9c0.5-0.3,0.7-0.4,1.1-0.6
		c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.7-0.5,1,0c0.3,0.5,0.5,0.8,0.7,1.2c0.1,0.2,0.2,0.3,0.4,0.4
		c0.3,0.2,0.6,0.4,1,0.5c0.2,0.1,0.4,0.3,0.6,0.5L23.9,21z M20.7,22.5c0.3-0.8,0.9-1.3,1.6-1.6c-0.8-0.3-1.3-0.8-1.6-1.6
		c-0.3,0.8-0.8,1.3-1.6,1.6C19.9,21.1,20.4,21.7,20.7,22.5L20.7,22.5z"
            />
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M3.6,1.3C3.7,1.4,3.9,1.5,4,1.7c0.3,0.4,0.5,0.9,0.7,1.3C4.8,3.1,4.9,3.2,5,3.3C5.5,3.5,5.9,3.8,6.3,4
		c0.4,0.2,0.4,0.7,0,0.9C5.9,5.2,5.5,5.4,5,5.7C4.9,5.8,4.8,5.9,4.7,6C4.5,6.4,4.2,6.8,4,7.3C3.9,7.5,3.7,7.7,3.5,7.6
		C3.3,7.6,3.2,7.4,3,7.3c0,0,0,0,0-0.1c-0.3-0.5-0.5-1-0.8-1.4S1.2,5.2,0.7,5C0.5,4.9,0.4,4.7,0.4,4.5c0-0.3,0.2-0.4,0.4-0.5
		c0.5-0.3,1-0.5,1.4-0.8S2.7,2.2,3,1.7C3.1,1.5,3.2,1.3,3.6,1.3z M3.5,6.1c0.3-0.8,0.8-1.3,1.7-1.6C4.8,4.3,4.4,4.1,4.1,3.9
		S3.7,3.2,3.5,2.8C3.2,3.7,2.7,4.2,1.9,4.5C2.7,4.8,3.2,5.3,3.5,6.1z"
            />
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M17.3,3c-0.1,1.9-0.7,3.5-2,4.9c-0.2,0.2-0.5,0.3-0.7,0.1c-0.2-0.2-0.2-0.5,0-0.7c1.2-1.2,1.8-2.7,1.7-4.4
		c0-0.4-0.1-0.8-0.2-1.2c-0.1-0.4,0-0.6,0.3-0.7s0.5,0.1,0.6,0.4c0.1,0.3,0.1,0.6,0.2,0.9C17.3,2.5,17.3,2.8,17.3,3z"
            />
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M9.5,14.9c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.4c0-0.3,0.2-0.5,0.4-0.5
		C9.4,14.9,9.5,14.9,9.5,14.9L9.5,14.9z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SpaceTypePartySvg',
    props: {
        isSelected: {
            type: Boolean,
            default: false,
            required: true
        },
        isChipBtn: {
            type: Boolean,
            default: true
        }
    }
}
</script>

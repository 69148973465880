<template>
    <SwiperContainer
        v-if="locations?.length > 0"
        :option="swiperOption"
        :name="'location-cards'"
    >
        <div
            v-for="(loc, index) in mobileLocations"
            :key="`mobile-location-card-${index}`"
            style="cursor: pointer;"
            class="swiper-slide d-lg-none d-flex flex-column justify-content-between first-card mobile-location-card"
        >
            <div v-for="(item, i) in loc" :key="i">
                <LocationCard
                    :is-first-card="index === 0"
                    :name="item.name"
                    :image="item.image.url.mobile"
                    :slug="item.slug"
                    :index="i"
                    class="d-block d-lg-none button-active"
                />
            </div>
        </div>
        <div
            v-for="(loc, index) in locations"
            :key="`desktop-location-card-${index}`"
            style="cursor: pointer;"
            class="swiper-slide d-none d-lg-block card-item mt-2"
        >
            <div class="first-card">
                <LocationCard
                    :is-first-card="true"
                    :name="loc.name"
                    :image="loc.image.url.desktop"
                    :slug="loc.slug"
                    :index="index"
                    class="d-none d-lg-block"
                />
            </div>
        </div>
    </SwiperContainer>
</template>

<script>
import SwiperContainer from '~/components/ui/SwiperContainer'
import LocationCard from '@/components/home/LocationCard.vue'
export default {
    name: 'LocationCards',
    components: { LocationCard, SwiperContainer },
    props: {
        option: {
            type: Object,
            default() {
                return {}
            }
        },
        locations: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            swiperOption: {},
            defaultOption: {
                slidesPerView: 2.2,
                spaceBetween: 10,
                breakpoints: {
                    1150: {
                        slidesPerView: 6.2,
                        spaceBetween: 20
                    },
                    1000: {
                        slidesPerView: 5.2,
                        spaceBetween: 20
                    },
                    680: {
                        slidesPerView: 3.3,
                        spaceBetween: 10
                    },
                    579.98: {
                        slidesPerView: 2.5,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    fetch() {
        this.setSwiperOption()
    },
    computed: {
        mobileLocations() {
            const arr = []
            let nestedArr = []
            this.locations.map((item, idx) => {
                if (idx === 0) {
                    arr.push([item])
                } else if (idx % 2 === 1) {
                    nestedArr = []
                    nestedArr.push(item)
                } else {
                    nestedArr.push(item)
                    arr.push(nestedArr)
                }
            })
            return arr
        },
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        }
    },
    methods: {
        setSwiperOption() {
            this.swiperOption = { ...this.defaultOption, ...this.option }
        }
    }
}
</script>

<style scoped>
.swiper-slide {
    margin-right: 10px;
}
.first-card {
    height: 220px;
}
.swiper-slide {
    height: 230px;
}
.mobile-location-card:active {
    filter: opacity(1);
}
</style>

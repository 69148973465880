<template>
    <b-btn
        :class="
            isSelected && isChipBtn
                ? `chip-btn-border ${selectedColor}`
                : isSelected
                ? `${selectedColor}`
                : ''
        "
        @click="handleButtonClick"
        class="space-type-btn text-14 button-active flex-center"
        variant="transparent"
    >
        <div :class="isHideMobileIcon ? 'd-none d-lg-block' : ''" class="mr-2">
            <SpaceTypeMeetSvg
                v-if="spaceType === 'meet'"
                :is-selected="isSelected"
                :is-chip-btn="isChipBtn"
            />
            <SpaceTypePartySvg
                v-else-if="spaceType === 'party'"
                :is-selected="isSelected"
                :is-chip-btn="isChipBtn"
            />
            <SpaceTypeRestSvg
                v-else-if="spaceType === 'rest'"
                :is-selected="isSelected"
                :is-chip-btn="isChipBtn"
            />
            <SpaceTypeWorkSvg
                v-else-if="spaceType === 'work'"
                :is-selected="isSelected"
                :is-chip-btn="isChipBtn"
            />
        </div>
        <div
            :class="
                isSelected && isChipBtn
                    ? `${selectedColor}`
                    : isSelected && !isChipBtn
                    ? `${selectedColor} nav-bar-btn`
                    : `${textColor}`
            "
            class="text-16 text-mid"
            style="white-space: nowrap"
        >
            {{ spaceTypeTitle }}
        </div>
    </b-btn>
</template>

<script>
import SpaceTypeMeetSvg from '@/components/iconSvg/SpaceTypeMeetSvg'
import SpaceTypePartySvg from '@/components/iconSvg/SpaceTypePartySvg'
import SpaceTypeRestSvg from '@/components/iconSvg/SpaceTypeRestSvg'
import SpaceTypeWorkSvg from '@/components/iconSvg/SpaceTypeWorkSvg'
export default {
    name: 'SpaceTypeChipButton',
    components: {
        SpaceTypeMeetSvg,
        SpaceTypePartySvg,
        SpaceTypeRestSvg,
        SpaceTypeWorkSvg
    },
    props: {
        spaceTypeTitle: {
            type: String,
            default: '',
            required: true
        },
        spaceType: {
            type: String,
            required: true
        },
        isSelected: {
            type: Boolean,
            default: false,
            required: true
        },
        selectedColor: {
            type: String,
            default: 'text-primary'
        },
        textColor: {
            type: String,
            default: 'text-white'
        },
        isChipBtn: {
            type: Boolean,
            default: true
        },
        isHideMobileIcon: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleButtonClick() {
            this.$emit('onButtonClicked')
        }
    }
}
</script>

<style scoped>
.space-type-btn {
    background-color: transparent;
    border-radius: 22px;
    border: 2px solid transparent;
    height: 38px;
    margin-right: 4px;
    padding: 0 22px;
}
.chip-btn-border {
    border-radius: 22px;
    border: 2px solid var(--primary);
}
.space-type-item {
    color: #ffffff;
}
@media (max-width: 600px) {
    .space-type-btn {
        border-radius: 22px;
        margin-right: 0;
        border: 1px solid transparent;
    }
    .chip-btn-border {
        border: 1px solid var(--primary);
    }
    .nav-bar-btn {
        border-bottom: 2px solid #ffc000;
    }
}
</style>

<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        height="22px"
        width="22px"
        viewBox="0 0 25 25"
        style="enable-background:new 0 0 25 25; min-height: 22px; min-width: 22px;"
        xml:space="preserve"
    >
        <style type="text/css">
            .st0 {
                fill: #f2bc1f;
            }
            .st1 {
                fill: #ffffff;
            }
            .st2 {
                fill: #a9a9a9;
            }
        </style>
        <g>
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M22.8,11.7c0-0.7-0.3-1.3-0.9-1.6c-0.8-0.5-2.1-0.4-2.8,0.5c-0.3,0.4-0.6,0.8-1,1.2c-0.2,0.3-0.5,0.6-0.7,0.9
		c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5,0-0.7,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1l0-0.4c0-0.7,0-1.4,0-2.1
		c0-1.4-1.2-2.5-2.6-2.6c-0.9,0-1.8,0-2.8,0c-1.1,0-2.2,0.9-2.5,2c-0.1,0.5-0.1,1.1-0.1,1.6c0,0.2,0,0.3,0,0.5
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5c0-0.1,0-0.1,0-0.2l0-0.1c0-0.2,0-0.3,0-0.5
		c0-0.3,0-0.5,0-0.8c0.1-0.7,0.7-1.3,1.4-1.4c1-0.1,2-0.1,3,0c0.6,0,1.3,0.7,1.3,1.3c0,0.5,0,1.1,0,1.6c0,0.3,0,0.7,0,1c0,0,0,0,0,0
		c-0.3,0-0.5,0.1-0.7,0.3c-0.4,0.3-0.6,0.8-0.5,1.3c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.7,0-1.4,0-2.1,0l-0.8,0
		c-0.1,0-0.2,0-0.2,0c0,0,0-0.1,0-0.2c0-0.9-0.6-1.5-1.5-1.5l-0.2,0c-0.5,0-0.9,0-1.4,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1
		c-0.5-0.7-1.1-1.4-1.7-2.1C5.5,10.1,5,9.9,4.5,9.8c-0.5,0-1.2,0-1.7,0.5c-0.3,0.3-0.5,0.6-0.6,1.1l0,6.1l0,0
		c0.2,1.1,1.1,1.9,2.3,1.9c0,0,0,0,0.1,0c0.4,0,0.8,0,1.2,0c0.4,0,0.7,0,1.1,0c0.2,0,0.2,0,0.3,0.2c0.3,0.6,0.5,1.2,0.8,1.9L8.1,22
		c0.2,0.4,0.4,0.4,0.6,0.4c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.3-0.3-0.7-0.4-1C8.5,20,8.2,19.4,8,18.8
		c-0.1-0.3-0.3-0.5-0.7-0.5c0,0,0,0,0,0c-0.7,0-1.4,0-2.1,0l-0.6,0c-0.2,0-0.4,0-0.6-0.1c-0.4-0.2-0.7-0.6-0.7-1.2l0-4.8
		c0-0.2,0-0.3,0-0.5c0-0.4,0.2-0.7,0.5-0.7c0.6-0.1,1,0,1.3,0.4c0.3,0.4,0.7,0.9,1,1.3c0.2,0.3,0.5,0.6,0.7,0.9
		c0.2,0.2,0.4,0.3,0.7,0.3c0.5,0,1,0,1.6,0l0.3,0c0.3,0,0.4,0.1,0.4,0.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0
		c-0.9,0-1.9,0-3.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1C6,14,5.7,13.6,5.4,13.2l-0.1-0.1c-0.2-0.3-0.5-0.3-0.6-0.3
		c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.6c0.3,0.5,0.7,0.9,1,1.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.4,0,0.8,0,1.1l0,0.1
		c0,0.5,0.2,0.7,0.7,0.7c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.7,0c0.5,0,0.7,0.2,0.8,0.6l0.4,1.2c0.3,0.9,0.6,1.9,0.9,2.8
		c0.1,0.2,0.1,0.4,0.4,0.4c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.4,0.1-0.6l-0.4-1.2c-0.3-1-0.6-1.9-1-2.9
		c-0.3-0.8-0.8-1.2-1.6-1.3c-0.3,0-0.7,0-1.1,0c-0.1,0-0.1,0-0.1,0c0,0,0,0,0-0.1c0-0.2,0-0.3,0-0.4c0-0.1,0-0.1,0-0.1
		c0,0,0,0,0.1,0c2.4,0,4.9,0,7.3,0l3.7,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.3,0c0.1,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0
		c-0.3,0-0.6,0-0.9,0c-0.9,0-1.6,0.5-1.9,1.4c-0.4,1.3-0.9,2.7-1.3,4c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.5,0.4,0.5
		c0.2,0.1,0.5,0,0.6-0.2c0.1-0.1,0.1-0.2,0.1-0.3l1.3-3.9c0.2-0.5,0.4-0.6,0.8-0.6h0.6c0.3,0,0.6,0,0.8,0c0.5,0,0.7-0.2,0.7-0.7
		c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0.1-0.2c0.3-0.4,0.7-0.9,1-1.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.2,0-0.5-0.2-0.6
		c-0.2-0.2-0.5-0.1-0.7,0c-0.1,0.1-0.1,0.1-0.2,0.2l-0.2,0.2c-0.3,0.4-0.5,0.7-0.8,1.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0,0,0,0,0
		c-0.7,0-1.3,0-2,0l-0.9,0c-0.2,0-0.3,0-0.5,0h0c0-0.2,0-0.3,0-0.4c0.1-0.1,0.2-0.2,0.4-0.2l0.3,0c0.5,0,1,0,1.5,0c0,0,0,0,0,0
		c0.3,0,0.5-0.1,0.7-0.3c0.3-0.4,0.6-0.7,0.8-1c0.3-0.4,0.6-0.7,0.9-1.1c0.3-0.4,0.6-0.5,1-0.5c0.6,0,0.8,0.3,0.8,0.8
		c0,1.8,0,3.6,0,5.3c0,0.3,0,0.4-0.1,0.6c-0.2,0.4-0.6,0.7-1.1,0.7l-0.7,0c-0.7,0-1.3,0-2,0c0,0,0,0,0,0c-0.3,0-0.6,0.1-0.7,0.5
		c-0.2,0.6-0.5,1.2-0.8,1.8c-0.1,0.3-0.3,0.6-0.4,0.9c0,0.1-0.1,0.2-0.1,0.3c0,0.3,0.1,0.5,0.4,0.6c0.3,0.1,0.5-0.1,0.6-0.4l0.2-0.4
		c0.3-0.7,0.6-1.4,0.9-2.1c0-0.1,0.1-0.1,0.2-0.1c0.7,0,1.6,0,2.5,0c1.2,0,2.2-1,2.2-2.2C22.8,15.3,22.8,13.3,22.8,11.7z"
            />
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M2.8,8.5c0.4,0.5,1,0.7,1.7,0.7c0,0,0,0,0,0c0.6,0,1.2-0.3,1.6-0.7c0.8-0.9,0.8-2.1,0-3.1
		C5.7,5,5.1,4.7,4.4,4.7c0,0,0,0,0,0C3.8,4.7,3.1,5,2.7,5.5C2.5,5.8,2.3,6.2,2.2,6.7l0,0l0,0.6C2.3,7.8,2.5,8.2,2.8,8.5z M3.3,7
		c0-0.6,0.5-1.1,1.1-1.1c0,0,0,0,0,0C5,5.8,5.6,6.3,5.6,7c0,0.3-0.1,0.6-0.3,0.8S4.7,8.1,4.4,8.1C4.1,8.1,3.8,8,3.6,7.8
		C3.4,7.6,3.3,7.3,3.3,7z"
            />
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M20.6,9.2C20.6,9.2,20.6,9.2,20.6,9.2c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-1.2-1-2.3-2.2-2.3
		c0,0,0,0,0,0c-0.6,0-1.1,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6C18.3,8.2,19.3,9.2,20.6,9.2z M20.6,5.8C20.6,5.8,20.6,5.8,20.6,5.8
		c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.6-0.5,1.1-1.1,1.2c0,0,0,0,0,0c-0.3,0-0.6-0.1-0.8-0.3
		c-0.2-0.2-0.3-0.5-0.3-0.8C19.4,6.3,19.9,5.8,20.6,5.8z"
            />
            <path
                :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                d="M12.5,7.1C12.5,7.1,12.5,7.1,12.5,7.1c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-1.2-1-2.2-2.2-2.2
		c0,0,0,0,0,0c-0.6,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6C10.3,6.1,11.3,7.1,12.5,7.1z M11.3,4.8c0-0.6,0.5-1.1,1.1-1.2
		c0,0,0,0,0,0c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.6-0.5,1.1-1.1,1.1c0,0,0,0,0,0c-0.3,0-0.6-0.1-0.8-0.3
		C11.5,5.4,11.3,5.1,11.3,4.8z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SpaceTypeMeetSvg',
    props: {
        isSelected: {
            type: Boolean,
            default: false,
            required: true
        },
        isChipBtn: {
            type: Boolean,
            default: true
        }
    }
}
</script>

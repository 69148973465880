<template>
    <div>
        <div class="home-column">
            <HeaderSection class="d-flex d-lg-none">
                <HeaderTitle>{{ $t('home.why') }}</HeaderTitle>
            </HeaderSection>
            <!--    Desktop Spacing    -->
            <div class="d-none d-lg-block pt-4 pb-2 desktop-spacing" />
            <div class="d-flex flex-column flex-lg-row">
                <div
                    class="col-12 col-lg-5 px-0 round-image-swiper-wrapper mb-4 mb-lg-0"
                    style="border-radius: 20px; aspect-ratio: 1; background-color: #e1e1e1;"
                >
                    <SwiperContainer
                        v-if="carouselImages.length > 0 && !isLoading"
                        :option="swiperOption"
                        :swiper-pagination="true"
                        :isSendGAEvent="false"
                        name="carousel-images"
                        type="desktop"
                        class="h-100"
                    >
                        <div
                            v-for="(item, index) in carouselImages"
                            :key="index"
                            class="swiper-slide"
                        >
                            <ImageContainer
                                :src="item"
                                alt="modern hotel carousel image"
                                height="440"
                                width="440"
                                class="carousel-image"
                            />
                        </div>
                    </SwiperContainer>
                    <b-skeleton-wrapper v-else :loading="true" class="h-100">
                        <template #loading>
                            <b-skeleton-img class="h-100"></b-skeleton-img>
                        </template>
                    </b-skeleton-wrapper>
                </div>
                <div class="d-block d-lg-none col-12 py-4 py-lg-0">
                    <div class="container">
                        <WhyFlowItem item-type="checkIn" screen-type="mobile" />
                        <WhyFlowItem
                            item-type="discount"
                            screen-type="mobile"
                        />
                        <WhyFlowItem
                            item-type="amenities"
                            screen-type="mobile"
                        />
                        <WhyFlowItem
                            item-type="freeCancel"
                            screen-type="mobile"
                        />
                        <WhyFlowItem
                            v-if="isPriceGuaranteeRegion"
                            item-type="priceGuarantee"
                            screen-type="mobile"
                        />
                    </div>
                </div>
                <div
                    class="d-none d-lg-block why-flow-desktop-container col-7 px-5 ml-3"
                    style="flex: 1;"
                >
                    <div class="justify-content-center">
                        <p class="text-24 text-bold text-1e1e1e text-center">
                            {{ $t('home.why') }}
                        </p>
                    </div>
                    <div class="container px-0">
                        <div
                            :class="
                                isPriceGuaranteeRegion
                                    ? 'row-cols-lg-3 justify-content-lg-center'
                                    : 'row-cols-lg-2 justify-content-lg-between'
                            "
                            class="row row-cols-2 justify-content-start"
                        >
                            <WhyFlowItem
                                item-type="checkIn"
                                screen-type="desktop"
                            />
                            <WhyFlowItem
                                item-type="discount"
                                screen-type="desktop"
                            />
                            <WhyFlowItem
                                item-type="amenities"
                                screen-type="desktop"
                            />
                            <WhyFlowItem
                                item-type="freeCancel"
                                screen-type="desktop"
                            />
                            <WhyFlowItem
                                v-if="isPriceGuaranteeRegion"
                                item-type="priceGuarantee"
                                screen-type="desktop"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderSection from '@/components/home/HeaderSection'
import HeaderTitle from '@/components/home/HeaderTitle'
import SwiperContainer from '@/components/ui/SwiperContainer'
import WhyFlowItem from '@/components/ui/WhyFlowItem'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'WhyFlow',
    components: {
        ImageContainer,
        HeaderTitle,
        HeaderSection,
        SwiperContainer,
        WhyFlowItem
    },
    data() {
        return {
            carouselImages: [
                'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5e28cfcc-370a-4013-4816-78db76476b00/public',
                'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/e86094e1-7208-4645-3a76-231fbbe0ed00/public',
                'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/548ebb30-0af2-4103-8428-298eb289a000/public',
                'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/4b3278da-ade8-4e99-732e-b0dd13d82300/public'
            ],
            swiperOption: {
                slidesPerView: 1,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                }
            },
            desktopIconWidth: 115,
            desktopIconHeight: 103,
            isLoading: true
        }
    },
    computed: {
        subLandingData() {
            // console.log(this.$store.getters['api/subLandingData'])
            return this.$store.getters['api/subLandingData']
        }
    },
    mounted() {
        // Simulate loading delay
        setTimeout(() => {
            this.isLoading = false
        }, 2000)
    },
    methods: {}
}
</script>
<style scoped>
.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.why-flow-desktop-container {
    background: #afafaf26;
    border-radius: 20px;
    padding-bottom: 4rem;
    padding-top: 4rem;
}
.desktop-spacing {
    padding-top: 62px !important;
}
.b-skeleton-wrapper > div {
    height: 100%;
}
</style>

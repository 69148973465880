<template>
    <div class="position-relative overflow-hidden">
        <div v-show="!isVNTestEnv" class="download-app-bg">
            <ImageContainer
                class="position-absolute vector"
                width="3741"
                height="767"
                src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/14cbea29-592c-445d-8fa5-54a495cde900/public"
                alt="download app vector"
            />
            <div class="main-container">
                <div class="d-flex flew-wrap justify-content-center">
                    <b-row
                        class="py-4 px-4 d-flex flew-wrap w-100 justify-content-center"
                    >
                        <div
                            class="text-light align-content-center align-items-center justify-content-center align-self-center col-lg-6 col-12 p-0"
                        >
                            <slot name="header"></slot>
                            <p
                                class="text-34 text-lg-left text-center download-app-title text-bold py-2 px-4 px-lg-0"
                            >
                                <slot name="title">{{
                                    $t('home.download_app_title', {
                                        price
                                    })
                                }}</slot>
                            </p>
                            <div
                                class="text-reg text-16 text-center px-0 pb-3 m-lg-0 download-app-title d-block d-lg-none"
                                style="margin: auto; white-space: pre-line;"
                            >
                                <slot name="content">{{
                                    $t('home.download_app_sub_title')
                                }}</slot>
                            </div>
                            <div
                                class="m-0"
                            >
                                <div
                                    class="text-reg text-17 text-lg-left text-center download-app-title download-app-des d-lg-block d-none px-0 py-2 m-lg-0"
                                    style="margin: auto; white-space: pre-line;"
                                >
                                    <slot name="content">{{
                                        $t('home.download_app_sub_title')
                                    }}</slot>
                                </div>
                                <div class="d-flex justify-content-center justify-content-lg-start">
                                    <GetAppQRCode
                                        class="mr-3 w-auto d-lg-block d-none"
                                        style="height: 100px;"
                                    />
                                    <div
                                        class="d-flex align-items-center flex-lg-column justify-content-between"
                                    >
                                        <GooglePlayIcon :height="45" :width="145" class="mx-2" />
                                        <AppleStoreIcon :height="45" :width="145" class="mx-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-center align-items-center col-lg-4 col-12 my-lg-0 pt-3"
                        >
                            <div class="phone-img-container">
                                <DownloadAppImage />
                            </div>
                        </div>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import memberBenefits from '@/services/memberBenefits'
import DownloadAppImage from '@/components/item/DowloadAppImage'
import GetAppQRCode from '@/components/item/GetAppQRCode.vue'
import GooglePlayIcon from "@/components/item/GooglePlayIcon.vue";
import AppleStoreIcon from "@/components/item/AppleStoreIcon.vue";
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'DownloadFlowApp',
    components: {ImageContainer, AppleStoreIcon, GooglePlayIcon, GetAppQRCode, DownloadAppImage },
    data() {
        return {
            googleDlUrl: {
                hk:
                    'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp',
                sg:
                    'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp&gl=SG',
                mo:
                    'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp',
                my:
                    'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp',
                vn:
                    'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp'
            },
            appleDlUrl: {
                hk:
                    'https://itunes.apple.com/hk/app/flow-last-minute-hotel-booking/id1257818009',
                sg:
                    'https://apps.apple.com/sg/app/flow-hotel-workspace-by-hour/id1257818009',
                mo:
                    'https://itunes.apple.com/hk/app/flow-last-minute-hotel-booking/id1257818009',
                my:
                    'https://apps.apple.com/my/app/flow-last-minute-hotel-booking/id1257818009',
                vn:
                    'https://apps.apple.com/my/app/flow-last-minute-hotel-booking/id1257818009'
            },
            price: ''
        }
    },
    computed: {
        region() {
            return this.$route.params.region
        }
    },
    created() {
        this.price = memberBenefits.getFirstBookingPriceReward(this.region)
    },
    methods: {
        sendOutboundClickTrack(url) {
            this.$gtag('event', 'click', {
                event_category: 'outbound',
                event_label: url,
                transport_type: 'beacon'
            })
        }
    }
}
</script>

<style scoped>
.store-img {
    object-fit: cover;
    border-radius: 8px;
}
.download-app-bg {
    width: 100%;
    display: flex;
    align-items: center;
}
.main-container {
    margin: auto;
}
@media (min-width: 992px) {
    .main-container {
        width: 1200px;
    }
    .store-img {
        height: 45px;
        width: 145px;
    }
    .phone-img-container {
        position: absolute;
    }
    .download-app-bg {
        background: linear-gradient(90.12deg, #fdcd6b -6.38%, #fee541 102.8%);
        height: 467px;
        margin: 120px 0;
    }
    .vector {
        width: 127%;
        height: 89%;
        right: 0;
    }
}
@media (max-width: 992px) {
    .store-img {
        height: 45px;
        width: 145px;
    }
    .phone-img-container {
        position: relative;
        height: 300px;
    }
    .download-app-title {
        width: 100%;
    }
    .download-app-bg {
        background: linear-gradient(90.12deg, #fece68 -6.38%, #fee541 102.8%);
    }
    .vector {
        left: -8px;
        top: 0;
        height: 97%;
    }
    .phone-img-container img {
        position: relative;
    }
}
.download-app-title {
    color: #313131;
}
</style>

<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        height="22px"
        width="22px"
        viewBox="0 0 25 25"
        style="enable-background:new 0 0 25 25; 22px; min-width: 22px;"
        xml:space="preserve"
    >
        <style type="text/css">
            .st0 {
                fill: #f2bc1f;
            }
            .st1 {
                fill: #ffffff;
            }
            .st2 {
                fill: #a9a9a9;
            }
        </style>
        <g id="hWyu3J_00000113349211407212566440000006729197401851230619_">
            <g>
                <path
                    :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
                    d="M2.2,20c0-4.6,0-9.1,0-13.7C2.3,5.9,2.6,5.8,3,5.8c1.7,0,3.4,0,5.1,0c0.2,0,0.2,0,0.2-0.2c0-0.1,0-0.3,0-0.4
			c0-1,0.8-1.8,1.8-1.8c1.6,0,3.2,0,4.8,0c0.7,0,1.3,0.4,1.6,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.2-0.1,0.4,0,0.5c0.1,0.1,0.3,0,0.5,0
			c1.6,0,3.3,0,4.9,0c0.5,0,0.7,0.2,0.7,0.7c0,4.4,0,8.8,0,13.2c0,0.3,0,0.6-0.2,0.8c-0.4,0.7-1,1-1.7,1c-3.7,0-7.5,0-11.2,0
			c-1.9,0-3.8,0-5.6,0c-0.6,0-1.2-0.2-1.6-0.7C2.3,20.6,2.2,20.3,2.2,20z M21.6,10.3C21.6,10.3,21.6,10.3,21.6,10.3
			c-0.1,0-0.1,0.1-0.1,0.1c-0.3,0.8-0.6,1.7-0.8,2.5c-0.2,0.6-0.5,1-1.1,1.2c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0-2.1,0-3.1,0
			c-0.1,0-0.2,0-0.2,0.2c0,0.1,0,0.3,0,0.4c0,0.4-0.3,0.6-0.7,0.7c-0.3,0-0.6,0-0.9,0c-1.3,0-2.5,0-3.8,0c-0.5,0-0.7-0.2-0.7-0.7
			c0-0.1,0-0.2,0-0.3c0-0.2,0-0.2-0.2-0.2c-1,0-2.1,0-3.1,0c-0.9,0-1.6-0.5-1.9-1.4c-0.3-0.8-0.6-1.7-0.8-2.5c0-0.1,0-0.1-0.1-0.2
			c0,0.1,0,0.1,0,0.2c0,3,0,6.1,0,9.1c0,0.5,0.2,0.8,0.8,0.8c5.6,0,11.2,0,16.7,0c0.5,0,0.8-0.2,0.8-0.8c0-3,0-6.1,0-9.1
			C21.6,10.4,21.6,10.4,21.6,10.3z M12.5,7.1c-0.4,0-0.8,0-1.2,0c-2.5,0-5,0-7.5,0C3.7,7.1,3.6,7,3.7,7.2c0.6,1.8,1.2,3.6,1.8,5.4
			c0.1,0.3,0.3,0.5,0.6,0.5c1.1,0,2.1,0,3.2,0c0.1,0,0.2,0,0.2-0.2c0-0.1,0-0.3,0-0.4c0-0.4,0.3-0.6,0.6-0.6c0.2,0,0.5,0,0.7,0
			c1.3,0,2.7,0,4,0c0.4,0,0.7,0.2,0.7,0.7c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0.2,0.2c1,0,2,0,3.1,0c0.5,0,0.6-0.1,0.8-0.6
			c0.6-1.8,1.2-3.5,1.8-5.3c0.1-0.2,0.1-0.2-0.2-0.2C18.3,7.1,15.4,7.1,12.5,7.1z M12.5,5.8c0.8,0,1.7,0,2.5,0c0.2,0,0.4,0.1,0.5,0
			c0.1-0.1,0-0.3,0-0.5c0-0.4-0.2-0.7-0.7-0.7c-0.7,0-1.4,0-2.1,0c-0.9,0-1.7,0-2.6,0c-0.4,0-0.6,0.2-0.6,0.6c0,0.1,0,0.3,0,0.4
			c0,0.2,0,0.2,0.2,0.2C10.6,5.8,11.6,5.8,12.5,5.8z M12.5,14.3c0.6,0,1.1,0,1.7,0c0.1,0,0.2,0,0.2-0.2c0-0.3,0-0.6,0-0.9
			c0-0.1,0-0.2-0.2-0.2c-1.1,0-2.2,0-3.3,0c-0.1,0-0.2,0-0.2,0.2c0,0.3,0,0.6,0,0.9c0,0.1,0,0.2,0.2,0.2
			C11.4,14.3,12,14.3,12.5,14.3z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SpaceTypeWorkSvg',
    props: {
        isSelected: {
            type: Boolean,
            default: false,
            required: true
        },
        isChipBtn: {
            type: Boolean,
            default: true
        }
    }
}
</script>

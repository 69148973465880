<template>
    <div id="home" class="home">
        <div class="landing-bg-image">
            <div class="home-column px-0">
                <b-container fluid class="services-container px-xl-0 px-3 py-4">
                    <div class="text-light">
                        <div
                            class="d-flex justify-content-center justify-content-lg-start home-content"
                        >
                            <h1
                                class="text-reg text-14 text-uppercase"
                                style="letter-spacing: 1px;"
                            >
                                {{ $t('home.sub_title') }}
                            </h1>
                        </div>
                        <div class="d-lg-none d-flex justify-content-center">
                            <div class="text-bold text-26">
                                {{ $t('home.title_mobile') }}
                            </div>
                        </div>
                        <div class="d-lg-block d-none home-content">
                            <div class="home-title-fir text-bold text-28">
                                {{ $t('home.title_fir_line') }}
                            </div>
                        </div>
                    </div>
                    <SearchingForm
                        :map-loading="mapLoading"
                        :map-location="mapLocation"
                        @getCurrentLocation="getCurrentLocation"
                        @setService="setService"
                    />
                </b-container>
            </div>
        </div>
        <div class="mb-5">
            <div class="info-container">
                <div v-for="(data, dataIndex) in homeData" :key="dataIndex">
                    <div v-if="data.type === 1" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>
                                {{ $t('home.place_nearby') }}
                            </HeaderTitle>
                        </HeaderSection>
                        <div
                            v-if="mapLoading"
                            class="d-flex justify-content-center my-1 map-spinner-container"
                            style="position: absolute"
                        >
                            <b-spinner class="map-spinner" variant="primary" />
                        </div>
                        <PlaceNearby
                            :home-map="data.image"
                            @getCurrentLocation="getCurrentLocation"
                        />
                    </div>
                    <div v-if="data.type === 2" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>
                                {{ $t('home.upcoming_bookings') }}
                            </HeaderTitle>
                        </HeaderSection>
                        <UpcomingBooking :upcoming-bookings="data" />
                    </div>
                    <div v-if="data.type === 3" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>
                                {{ $t('home.book_again') }}
                            </HeaderTitle>
                        </HeaderSection>
                        <BookAgain
                            :book-again="data.list"
                            @navigateToDetails="navigateToDetails"
                        />
                    </div>
                    <div v-if="data.type === 4" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>
                                {{ $t('home.explore_with_flow') }}
                            </HeaderTitle>
                        </HeaderSection>
                        <ExploreFlow :explore-flow="data.explore_flow" />
                    </div>
                    <div v-if="data.type === 5" class="home-column">
                        <DealsSection @navigateToDetails="navigateToDetails" />
                    </div>
                    <!--                    <div v-if="data.type === 6">Explore places [disabled]</div>-->
                    <!--                    <div v-if="data.type === 7">Places you might like [disabled]</div>-->
                    <div v-if="data.type === 8" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>{{
                                $t('home.get_inspired')
                            }}</HeaderTitle>
                            <NuxtLink
                                :to="computeSeeAllLink('BLOG', 'blog')"
                                variant="transparent"
                                class="text-16 text-b5b3ab text-reg text-decoration-none"
                                style="letter-spacing: .5px;"
                                aria-label="get blog post"
                            >
                                {{ $t('details.see_all') }}
                            </NuxtLink>
                        </HeaderSection>
                        <Blogs />
                    </div>
                    <div v-if="data.type === 9" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>
                                {{ $t('home.current_offers') }}
                            </HeaderTitle>
                            <NuxtLink
                                :to="computeSeeAllLink('PROMOTIONS', 'promotions')"
                                variant="transparent"
                                class="text-16 text-b5b3ab text-reg text-decoration-none"
                                style="letter-spacing: .5px;"
                                aria-label="get amenities"
                            >
                                {{ $t('details.see_all') }}
                            </NuxtLink>
                        </HeaderSection>
                        <Promotions />
                    </div>
                    <DownloadFlowApp v-if="data.type === 10" class="mt-5" />
                    <div v-if="data.type === 11" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>
                                {{ $t('home.popular_destinations') }}
                            </HeaderTitle>
                        </HeaderSection>
                        <LocationCards :locations="data.destinations" />
                    </div>
                    <div v-if="data.type === 12" class="home-column">
                        <HeaderSection>
                            <HeaderTitle>
                                {{ $t('home.member_benefits') }}
                            </HeaderTitle>
                        </HeaderSection>
                        <FlowBenefits />
                    </div>
                    <WhyFlow v-if="data.type === 13" />
                    <MediaCoverage
                        v-if="data.type === 14"
                        class="home-column"
                    />
                    <PartneredHotels
                        v-if="data.type === 15"
                        class="home-column"
                    />
                    <div v-if="data.type === 16" class="home-column">
                        <ExploreFlexibleSpaces />
                    </div>
                    <!--                    <div v-if="data.type === 17">Space type buttons [app only]</div>-->
                    <div v-if="data.type === 18" class="home-column">
                        <FastSearchSection :buttons="data.buttons" />
                    </div>
                </div>
            </div>
            <!--            Pending UI Design-->
            <!--            <div class="home-column">-->
            <!--                <HotelAmenities v-if="false" />-->
            <!--            </div>-->
        </div>
        <p style="display:none;">2r1cl5phksggddln</p>
    </div>
</template>

<script>
import { computeSearchIndex } from '@/lib-flow-main-search/src/search'
import firebase from '@/services/firebase'
import PlaceNearby from '~/components/home/PlaceNearby'
import ExploreFlow from '~/components/home/ExploreFlow'
import Blogs from '~/components/home/Blogs'
import BookAgain from '~/components/home/BookAgain'
import UpcomingBooking from '~/components/home/UpcomingBooking'
import dateServices from '~/services/dateServices'
import SearchingForm from '~/components/home/SearchingForm'
import MediaCoverage from '@/components/ui/MediaCoverage'
import WhyFlow from '@/components/ui/WhyFlow'
// import HotelAmenities from '@/components/home/HotelAmenities'
import DownloadFlowApp from '@/components/home/DownloadFlowApp'
import FlowBenefits from '@/components/home/FlowBenefits'
import analysis from '@/services/analysis'
import HeaderTitle from '@/components/home/HeaderTitle'
import HeaderSection from '@/components/home/HeaderSection'
import {
    ALGOLIA_EVENT_NAME,
    getSpaceTypeId,
    sendObjectClickedEvent
} from '@/services/algoliaApi'
import Promotions from '~/components/home/Promotions'
import PartneredHotels from '~/components/home/PartneredHotels'
import ExploreFlexibleSpaces from '@/components/home/ExploreFlexibleSpaces'
import LocationCards from '@/components/home/LocationCards'
import DealsSection from '@/components/home/DealsSection'
import FastSearchSection from '@/components/home/FastSearchSection'
import { getGAEventName } from "@/utils/analysis";

export default {
    name: 'Index',
    components: {
        Blogs,
        DealsSection,
        LocationCards,
        ExploreFlexibleSpaces,
        PartneredHotels,
        Promotions,
        HeaderSection,
        HeaderTitle,
        FlowBenefits,
        DownloadFlowApp,
        WhyFlow,
        // HotelAmenities,
        MediaCoverage,
        UpcomingBooking,
        SearchingForm,
        BookAgain,
        ExploreFlow,
        PlaceNearby,
        FastSearchSection
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from.fullPath
            vm.sendPageTrack()
        })
    },
    async asyncData({ store, route, error, payload }) {
        try {
            const lang = route.params.locale
            const region = route.params.region
            if (payload) {
                const res = await firebase.getAnonymousIdToken()
                const idToken = res.user.accessToken
                const obj = {
                    id_token: idToken,
                    lang,
                    region,
                    all: 0
                }
                await store.dispatch('api/GET_HOME_RES', obj)
                await store.dispatch('api/GET_SPACE_TYPE_INFO', obj)
            } else {
                await store.dispatch('api/GET_SPACE_TYPE_INFO', {
                    lang,
                    region,
                    all: 0
                })
            }
        } catch (err) {
            error(err)
        }
    },
    data() {
        return {
            mapLocation: {
                latitude: '',
                longitude: ''
            },
            loadingCoordinate: false,
            mapLoading: false,
            services: 'rest'
        }
    },
    head() {
        const scriptData = [
            { type: 'application/ld+json', json: this.computeJsonldObject() },
            this.postAffiliateProTrackerObject
        ]
        return {
            title: this.$t('home.meta_title'),
            meta: [
                {
                    name: 'keywords',
                    content: this.$t('home.meta_keywords')
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t('home.meta_description', {
                        country: this.regionName()
                    })
                },
                // Open Graph
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: this.$t('home.meta_title')
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.$t('home.meta_description', {
                        country: this.regionName()
                    })
                },
                {
                    hid: 'og:url',
                    property: 'og:url',
                    content:
                        'https://app.flowtheroom.com' +
                        this.$router.currentRoute.fullPath
                },
                // Twitter Card
                {
                    hid: 'twitter:title',
                    name: 'twitter:title',
                    content: this.$t('home.meta_title')
                },
                {
                    hid: 'twitter:description',
                    name: 'twitter:description',
                    content: this.$t('home.meta_description', {
                        country: this.regionName()
                    })
                },
                {
                    hid: 'twitter:url',
                    name: 'twitter:url',
                    content: `https://app.flowtheroom.com${this.$route.path}`
                }
            ],
            script: scriptData
        }
    },
    computed: {
        homeData() {
            return this.$store.getters['api/homeData']
        },
        isSigned() {
            return this.$store.getters['member/isSigned']
        },
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        }
    },
    watch: {
        isSigned: {
            immediate: true,
            handler(newVal) {
                if (newVal !== null) {
                    this.getHomeDetails()
                }
            }
        }
    },
    destroyed() {
        document.documentElement.style.overflow = 'auto'
    },
    methods: {
        async getHomeDetails() {
            try {
                const idToken = await this.getIdToken()
                if (idToken) {
                    const obj = {
                        id_token: idToken,
                        lang: this.lang,
                        region: this.region,
                        all: 0
                    }
                    await this.$store.dispatch('api/GET_HOME_RES', obj)
                    await this.$store.dispatch('api/GET_SPACE_TYPE_INFO', obj)
                }
            } catch (err) {
                this.$nuxt.error(err)
                this.$sentry.captureException(err)
            }
        },
        getDate(date) {
            return dateServices.getDateByFormat(new Date(date))
        },
        navigateToDetails(params) {
            const region = params.countryCode.toLowerCase()
            let lang = this.lang
            lang = this.switchDefaultLang(lang, region)
            const position = params.position ?? ''
            const section = params.filter ?? 'book again'
            const source = getGAEventName(this.landingPageName, section, '', position)

            let obj = {
                region,
                locale: lang,
                spaceName: params.slug,
                space: this.globalSpace(params.services)
            }

            const query = {
                date: dateServices.getToday(),
                viewBtn: source
            }

            let eventName
            if (params.btn.includes("Today's Deals")) {
                eventName = ALGOLIA_EVENT_NAME.TODAY_DEAL_ITEM_CLICKED
            }
            if (eventName !== undefined) {
                sendObjectClickedEvent(
                    eventName,
                    undefined,
                    computeSearchIndex(
                        region,
                        getSpaceTypeId(params.services),
                        process.env.TYPESENSE_ENV
                    ),
                    params.objectID,
                    undefined
                )
            }
            this.$router.push({
                name: `region-locale-spaceName-space`,
                params: obj,
                query
            })
        },
        sendPageTrack() {
            const params = {
                page_title: this.$t('home.meta_title')
            }
            this.globalSendPageTrack(params, this.prevRoute)
        },
        getCurrentLocation() {
            this.mapLoading = true
            this.loadingCoordinate = true
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    this.setCurrentLocation,
                    this.handleGetLocationError,
                    { enableHighAccuracy: true, maximumAge: 5000 }
                )
            }
        },
        setCurrentLocation(position) {
            this.mapLocation = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }
            this.mapLoading = false
        },
        handleGetLocationError(error) {
            let msg = ''
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    msg = this.$t('err_gps_permission_denied')
                    break
                case error.POSITION_UNAVAILABLE:
                    msg = this.$t('err_gps_position_unavailable')
                    break
                case error.TIMEOUT:
                    msg = this.$t('err_gps_timeout')
                    break
                default:
                case error.UNKNOWN_ERROR:
                    msg = this.$t('err_gps_unknown')
                    break
            }
            alert(msg)
            this.mapLoading = false
        },
        setService(service) {
            this.services = service
        },
        computeJsonldObject() {
            return {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: 'Flow - Hotel Daycation App',
                alternateName: ['Flow', 'flowtheroom.com', 'app.flowtheroom.com'],
                url: `https://app.flowtheroom.com${this.$route.path}`,
                logo: 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/a6dd7fbf-e951-480c-7138-c419151bad00/public',
                contactPoint: {
                    '@type': 'ContactPoint',
                    contactType: 'reservations'
                },
                // potentialAction: {
                //     '@type': 'SearchAction',
                //     target: {
                //         '@type': 'EntryPoint',
                //         urlTemplate: '',
                //         contentType: 'application/ld+json'
                //     },
                //     'query-input': 'name=search_term_string',
                //     contactType: 'reservations'
                // },
                sameAs: [
                    'https://www.instagram.com/flowhk',
                    'https://www.facebook.com/flowhongkong',
                    'https://hk.linkedin.com/company/flowhk'
                ]
            }
        },
        computeSeeAllLink(section, link) {
            const source = getGAEventName(this.landingPageName, section, 'see all')
            return {
                name: `region-locale-${link}`,
                params: {
                    source
                }
            }
        }
    }
}
</script>

<style scoped>
.services-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.landing-bg-image {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    display: flex;
    background-image: url('https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/81e81a0e-4b55-418a-9933-c9546e576600/headbanner');
}

.landing-bg-image::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.map-spinner-container {
    left: 47%;
    top: 47%;
}
.map-spinner {
    height: 35px;
    width: 35px;
}
@media (max-width: 375px) {
    .home-title-fir {
        margin-right: 10px;
    }
}

@media (max-width: 991px) {
    .home-title-fir {
        margin-right: 10px;
    }
    .landing-bg-image {
        height: 450px;
        background-image: url('https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/fa073f12-f3cc-47cf-d0ec-be856a41bc00/public');
    }
}

@media (max-width: 320px) {
    .home-content {
        margin-bottom: 18px !important;
    }
}
@media (min-width: 992px) {
    .map-spinner-container {
        left: 47%;
        top: 47%;
    }
    .map-spinner {
        height: 40px;
        width: 40px;
    }
    .services-container {
        width: 1200px;
    }
    .landing-bg-image {
        height: 450px;
    }
}
</style>

<template>
    <div v-if="partnerHotels[paramsRegion]?.length > 0">
        <HeaderSection>
            <HeaderTitle>{{ $t('home.hotel_partners') }}</HeaderTitle>
        </HeaderSection>
        <div class="container">
            <div class="row row-cols-2 row-cols-lg-5">
                <div
                    v-for="(image, index) in partnerHotels[paramsRegion]"
                    :key="index"
                    class="col px-0"
                >
                    <ImageContainer
                        :src="image.icons"
                        :alt="image.name"
                        class="disable-link img-fluid"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderSection from '@/components/home/HeaderSection'
import HeaderTitle from '@/components/home/HeaderTitle'
import ImageContainer from "@/components/ui/ImageContainer.vue";
export default {
    name: 'PartneredHotels',
    components: {ImageContainer, HeaderTitle, HeaderSection },
    data() {
        return {
            partnerHotels: {
                hk: [
                    {
                        name: 'sino-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/e8169e72-2ad8-40a9-ad5e-6929566e3d00/public'
                    },
                    {
                        name: 'IHG',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5e33366f-2061-4f07-4b00-cdedae46a000/public'
                    },
                    {
                        name: 'dorsett',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/826c5d3b-aa80-4c09-8f9e-cbdde95a4700/public'
                    },
                    {
                        name: 'royal-hotels-hong-kong',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/499efbf2-43d0-4cb8-dc68-d052d20dbc00/public'
                    },
                    {
                        name: 'cordis',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/d9fdc344-eb03-4acc-b03f-66213d6b9f00/public'
                    },
                    {
                        name: 'the-park-lane-hong-kong',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3c1e646d-ebf5-4ce7-a6c8-b23741405300/public'
                    },
                    {
                        name: 'regal-hotels-internation',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5bb12d2a-a6f3-4ef9-92c0-4053b6374c00/public'
                    },
                    {
                        name: 'marco-polo',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5f9c6506-840c-4ad3-b29b-e20c72228300/public'
                    },
                    {
                        name: 'habour-plaza',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/0f5ca06d-460b-41c6-382c-ad50838f7000/public'
                    },

                    {
                        name: 'the-mira-hong-kong',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/86b7b96c-8675-4933-865a-4c70f62b8d00/public'
                    },
                    {
                        name: 'the-ascott-limited',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/64a5a818-16a9-494e-f57b-e1b57c893b00/public'
                    },
                    {
                        name: 'crowne-plaza',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b98873cc-ad2b-419a-f28b-851c03d86300/public'
                    },
                    {
                        name: 'travelodge-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/e5e61b43-004f-47bb-fbf1-e4fafc78fa00/public'
                    },
                    {
                        name: 'hotel-cozi',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f9e11052-4f5f-498d-7bbd-31617f554500/public'
                    },
                    {
                        name: 'accor',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/37d330b8-19d2-40af-6e0c-cece77e3f100/public'
                    },
                    {
                        name: 'best-western-plus',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/d0474b15-6cb9-4059-a1c3-1eb38a162e00/public'
                    },
                    {
                        name: 'park-hotel-group',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/d3cd50bf-8f24-4db1-52a5-3c5adebdeb00/public'
                    },
                    {
                        name: 'penta-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/298226d0-e5ae-4bd5-b1b2-8ed4973fa700/public'
                    }
                ],
                sg: [
                    {
                        name: 'holiday-inn',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5e82e2fd-a5f7-4812-dba0-19cb8ad2bb00/public'
                    },
                    {
                        name: 'dorsett',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3be40afd-80af-4141-0c7a-c0b4d80e0700/public'
                    },
                    {
                        name: 'the-ascott',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/eabe85fc-45b8-4d29-5f10-3a3558bf6000/public'
                    },

                    {
                        name: 'amara',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/bfbe087b-0b2b-4b75-7e15-9ec0fbbe3e00/public'
                    },
                    {
                        name: 'naumi',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/70394ae6-e509-42e0-32fc-05587cd97d00/public'
                    },
                    {
                        name: 'accor',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/1f1f28fe-11bb-41cb-e085-b53bd54bcc00/public'
                    },
                    {
                        name: 'travelodge-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c5e3a078-e323-4742-c1dd-95c3ee05c700/public'
                    },
                    {
                        name: 'the-mira-hong-kong',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b150180a-277e-4012-6a99-1457274b3f00/public'
                    },
                    {
                        name: 'hotel-telegraph',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/522a64c6-9bd8-4c7c-93da-09dfd6f13000/public'
                    },
                    {
                        name: 'grand-mercure',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/8a1e4b26-edae-4b43-0956-c7f60e734200/public'
                    },
                    {
                        name: 'sino-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/e8169e72-2ad8-40a9-ad5e-6929566e3d00/public'
                    },
                    {
                        name: 'pullman-hotels-and-resorts',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/307d7800-5e7f-463f-6c85-8a183921af00/public'
                    },
                    {
                        name: 'regal-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b6de71ee-a2a9-4e14-1630-02ef20dd2f00/public'
                    },
                    {
                        name: 'park-hotel-group',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/85919dd7-2e79-4ef5-424d-d5e7dd3ffd00/public'
                    },
                    {
                        name: 'marco-polo',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/915b5f22-12f8-4faf-d0a9-63ac58ce5200/public'
                    },
                    {
                        name: 'crowne-plaza',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/07293973-de53-4534-a8f0-1ace27ea0c00/public'
                    },
                    {
                        name: 'habour-plaza',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c01d3bd7-68c0-4534-5754-ce313fc13900/public'
                    },
                    {
                        name: 'novotel',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/21fb6d61-e337-4a73-2150-1340f9aa0800/public'
                    }
                ],
                my: [
                    {
                        name: 'dorsett',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3be40afd-80af-4141-0c7a-c0b4d80e0700/public'
                    },
                    {
                        name: 'sunway',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/2a4c34d7-fe66-4ad7-80d1-c7659a2cd000/public'
                    },
                    {
                        name: 'intercontinental',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c369f491-35f9-4df6-19bf-2f4b8d2f8a00/public'
                    },
                    {
                        name: 'hotel-maya',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5af15f3d-158e-4749-ed22-25c426c21800/public'
                    },
                    {
                        name: 'wyndham',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/0317b517-a956-4f6d-dcd1-b26885eaf500/public'
                    },
                    {
                        name: 'micasa-all-suite-hotel',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/9045860f-d4bc-4566-d003-1cf6b1f8fb00/public'
                    },
                    {
                        name: 'travelodge-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c5e3a078-e323-4742-c1dd-95c3ee05c700/public'
                    },
                    {
                        name: 'accor',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/1f1f28fe-11bb-41cb-e085-b53bd54bcc00/public'
                    },
                    {
                        name: 'sino-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/e8169e72-2ad8-40a9-ad5e-6929566e3d00/public'
                    },
                    {
                        name: 'best-western-plus',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c3fbba81-e62b-4670-8aac-9b80ce6a1600/public'
                    },
                    {
                        name: 'IHG',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5e33366f-2061-4f07-4b00-cdedae46a000/public'
                    },
                    {
                        name: 'pullman-hotels-and-resorts',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/307d7800-5e7f-463f-6c85-8a183921af00/public'
                    },
                    {
                        name: 'regal-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b6de71ee-a2a9-4e14-1630-02ef20dd2f00/public'
                    },
                    {
                        name: 'park-hotel-group',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/85919dd7-2e79-4ef5-424d-d5e7dd3ffd00/public'
                    },
                    {
                        name: 'marco-polo',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/915b5f22-12f8-4faf-d0a9-63ac58ce5200/public'
                    },
                    {
                        name: 'crowne-plaza',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/07293973-de53-4534-a8f0-1ace27ea0c00/public'
                    },
                    {
                        name: 'habour-plaza',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c01d3bd7-68c0-4534-5754-ce313fc13900/public'
                    },
                    {
                        name: 'penta-hotels',
                        icons:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/de39f0d9-7a42-4d60-c3ab-49b932e88000/public'
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>
.disable-link {
    pointer-events: none;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-btn',{staticClass:"space-type-btn text-14 button-active flex-center",class:_vm.isSelected && _vm.isChipBtn
            ? `chip-btn-border ${_vm.selectedColor}`
            : _vm.isSelected
            ? `${_vm.selectedColor}`
            : '',attrs:{"variant":"transparent"},on:{"click":_vm.handleButtonClick}},[_c('div',{staticClass:"mr-2",class:_vm.isHideMobileIcon ? 'd-none d-lg-block' : ''},[(_vm.spaceType === 'meet')?_c('SpaceTypeMeetSvg',{attrs:{"is-selected":_vm.isSelected,"is-chip-btn":_vm.isChipBtn}}):(_vm.spaceType === 'party')?_c('SpaceTypePartySvg',{attrs:{"is-selected":_vm.isSelected,"is-chip-btn":_vm.isChipBtn}}):(_vm.spaceType === 'rest')?_c('SpaceTypeRestSvg',{attrs:{"is-selected":_vm.isSelected,"is-chip-btn":_vm.isChipBtn}}):(_vm.spaceType === 'work')?_c('SpaceTypeWorkSvg',{attrs:{"is-selected":_vm.isSelected,"is-chip-btn":_vm.isChipBtn}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-16 text-mid",class:_vm.isSelected && _vm.isChipBtn
                ? `${_vm.selectedColor}`
                : _vm.isSelected && !_vm.isChipBtn
                ? `${_vm.selectedColor} nav-bar-btn`
                : `${_vm.textColor}`,staticStyle:{"white-space":"nowrap"}},[_vm._v("\n        "+_vm._s(_vm.spaceTypeTitle)+"\n    ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }
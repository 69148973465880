<template>
    <SwiperContainer
        v-if="posts?.length > 0"
        :is-set-slides-per-view="true"
        :name="'promotions'"
        :width-adjustment="-0.5"
        :is-free-mode="true"
    >
        <div
            v-for="(item, index) in posts"
            :id="`-promotions-swiper-slide`"
            :key="index"
            class="swiper-slide card-item mt-2"
        >
            <NuxtLink
                :to="computePathToPromotion(index, item.slug)"
                style="text-decoration: none"
            >
                <ImageContainer
                    :src="item.thumbnail?.medium_large"
                    :alt="item.title"
                    class="list-item-img"
                />
            </NuxtLink>
        </div>
    </SwiperContainer>
</template>

<script>
import SwiperContainer from '~/components/ui/SwiperContainer'
import ImageContainer from "@/components/ui/ImageContainer.vue";
import {getGAEventName} from "@/utils/analysis";
export default {
    name: 'Promotions',
    components: {ImageContainer, SwiperContainer },
    async fetch() {
        try {
            await this.setPromotionPosts('flowwebstatic')
        } catch (e) {
            // no-op
        }
    },
    computed: {
        posts() {
            return this.$store.getters['api/currentOffers']
        }
    },
    watch: {
        globalIsSigned(newVal) {
            if (newVal !== null) {
                this.setPromotionPosts()
            }
        },
    },
    mounted() {
        this.setPromotionPosts()
    },
    methods: {
        async setPromotionPosts(idToken) {
            try {
                if (!idToken){
                    idToken = await this.getIdToken()
                }
                if (idToken) {
                    const params = {
                        id_token: idToken,
                        type: 'current_offers',
                        lang: this.paramsLang,
                        region: this.paramsRegion
                    }
                    await this.$store.dispatch('api/GET_CURRENT_OFFERS', params)
                }
            } catch (err) {
                this.$sentry.captureException(err)
            }
        },
        computePathToPromotion(position, id) {
            const source = getGAEventName(this.landingPageName, 'PROMOTIONS', id, position)
            return {
                name: 'region-locale-promotions-id',
                params: {
                    source,
                    id
                }
            }
        }
    }
}
</script>

<style scoped>
.list-item-img {
    border-radius: 10px;
    object-fit: cover;
    aspect-ratio: 0.9;
}
.swiper-slide {
    width: auto !important;
    margin-right: 10px;
    aspect-ratio: 0.9;
}
@media (max-width: 414px) {
    .list-item-img,
    .swiper-slide {
        height: 165px;
    }
}
@media (min-width: 415px) {
    .list-item-img,
    .swiper-slide {
        height: 200px;
    }
}
</style>

<template>
    <div>
        <HeaderSection>
            <HeaderTitle>
                {{
                    $t('home.explore_flexible_title', {
                        country: regionName()
                    })
                }}
            </HeaderTitle>
        </HeaderSection>
        <SwiperContainer
            v-if="false"
            :option="swiperOption"
            :swiper-pagination="true"
            name="explore"
            class="col-12"
        >
            <div class="col-12 col-lg-4 swiper-slide">
                <IconTitle
                    :title="$t('home.space_by_region')"
                    icon-url="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/7607c2ac-2e92-4d6a-5d96-645e6a473500/icon"
                    icon-height="30"
                    icon-width="30"
                />
                <div
                    v-for="(item, index) in links"
                    :key="index"
                    :class="index === 0 ? 'mt-3' : ''"
                >
                    <InternalLinks
                        :links="item.link"
                        :keyword="item.keyword"
                        :is-display-btn="false"
                        class="d-flex"
                    />
                </div>
            </div>
            <div class="col-12 col-lg-4 swiper-slide">
                <IconTitle
                    :title="$t('home.space_by_city')"
                    icon-url="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f697248a-c1f1-4d40-41cc-3ba32c51db00/icon"
                    icon-height="30"
                    icon-width="30"
                />
                <div
                    v-for="(item, index) in links"
                    :key="index"
                    :class="index === 0 ? 'mt-3' : ''"
                >
                    <InternalLinks
                        :links="item.link"
                        :keyword="item.keyword"
                        :is-display-btn="false"
                        class="d-flex"
                    />
                </div>
            </div>
            <div class="col-12 col-lg-4 swiper-slide">
                <IconTitle
                    :title="$t('home.space_by_top_selling')"
                    icon-url="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5c524ed4-3f47-46e3-94b6-2bb4f0099a00/icon"
                    icon-height="30"
                    icon-width="30"
                />
                <div
                    v-for="(item, index) in links"
                    :key="index"
                    :class="index === 0 ? 'mt-3' : ''"
                >
                    <InternalLinks
                        :links="item.link"
                        :keyword="item.keyword"
                        :is-display-btn="false"
                        class="d-flex"
                    />
                </div>
            </div>
        </SwiperContainer>
        <div v-if="false" class="container">
            <div class="row row-cols-1 row-cols-lg-3">
                <div class="col d-flex flex-column align-items-start pl-0 pr-1">
                    <IconTitle
                        :title="$t('home.space_by_region')"
                        icon-url="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/7607c2ac-2e92-4d6a-5d96-645e6a473500/icon"
                        icon-height="30"
                        icon-width="30"
                    />
                    <div
                        v-for="(item, index) in links"
                        :key="index"
                        :class="index === 0 ? 'mt-2' : ''"
                    >
                        <InternalLinks
                            :links="item.link"
                            :keyword="item.keyword"
                            :is-display-btn="false"
                            class="d-flex"
                        />
                    </div>
                </div>
                <div class="col d-flex flex-column align-items-start pl-0 pr-1">
                    <IconTitle
                        :title="$t('home.space_by_city')"
                        icon-url="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f697248a-c1f1-4d40-41cc-3ba32c51db00/icon"
                        icon-height="30"
                        icon-width="30"
                    />
                    <div
                        v-for="(item, index) in links"
                        :key="index"
                        :class="index === 0 ? 'mt-2' : ''"
                    >
                        <InternalLinks
                            :links="item.link"
                            :keyword="item.keyword"
                            :is-display-btn="false"
                            class="d-flex"
                        />
                    </div>
                </div>
                <div class="col d-flex flex-column align-items-start pl-0 pr-1">
                    <IconTitle
                        :title="$t('home.space_by_top_selling')"
                        icon-url="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5c524ed4-3f47-46e3-94b6-2bb4f0099a00/icon"
                        icon-height="30"
                        icon-width="30"
                    />
                    <div
                        v-for="(item, index) in links"
                        :key="index"
                        :class="index === 0 ? 'mt-2' : ''"
                    >
                        <InternalLinks
                            :links="item.link"
                            :keyword="item.keyword"
                            :is-display-btn="false"
                            class="d-flex"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in links" :key="index">
            <InternalLinks
                :links="item.link"
                :keyword="item.keyword"
                :is-display-btn="false"
                class="d-flex"
            />
        </div>
    </div>
</template>
<script>
import HeaderTitle from '@/components/home/HeaderTitle'
import InternalLinks from '@/components/ui/InternalLinks'
import HeaderSection from '@/components/home/HeaderSection'
import IconTitle from '@/components/ui/IconTitle'
import SwiperContainer from '@/components/ui/SwiperContainer'

export default {
    name: 'ExploreFlexibleSpaces',
    components: {
        SwiperContainer,
        HeaderSection,
        InternalLinks,
        HeaderTitle,
        IconTitle
    },
    props: {
        keyword: {
            type: String,
            default() {
                return 'maps'
            }
        },
        space: {
            type: String,
            default() {
                return 'hotel'
            }
        },
        locationSlug: {
            type: String,
            default() {
                return 'hong-kong'
            }
        }
    },
    data() {
        return {
            links: [
                {
                    keyword: 'maps'
                },
                {
                    keyword: 'hourly-hotels'
                },
                {
                    keyword: 'day-use-hotels'
                },
                {
                    keyword: 'cheap-hotels'
                },
                {
                    keyword: 'staycation'
                }
            ],
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: -40,
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    fetch() {
        this.setLinks()
    },
    computed: {
        region() {
            return this.$route.params.region
        }
    },
    methods: {
        setLinks() {
            this.links = this.links.map((item) => {
                item.link = [
                    { slug: this.globalLocationSlug, name: this.regionName() }
                ]
                return item
            })
        }
    }
}
</script>

<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        :height="`${height}px`"
        :width="`${width}px`"
        viewBox="0 0 25 25"
        style="enable-background:new 0 0 25 25;"
        xml:space="preserve"
    >
        <style type="text/css">
            .st0 {
                fill: #f2bc1f;
            }
            .st1 {
                fill: #ffffff;
            }
            .st2 {
                fill: #a9a9a9;
            }
        </style>
        <path
            :class="isSelected ? 'st0' : isChipBtn ? 'st1' : 'st2'"
            d="M24.2,15.9c-0.2-1.8-1.5-3.4-3.2-4v-8c0-0.3-0.2-0.5-0.5-0.5S20,3.6,20,3.9v0.7H5V3.9c0-0.3-0.2-0.5-0.5-0.5
	S3.9,3.6,3.9,3.9v8c-1.7,0.7-2.9,2.2-3.2,4c0,0.1,0,0.1,0,0.2v5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V20h21.5v1.1
	c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-5C24.3,16.1,24.3,16,24.2,15.9z M13.9,10.6c0-0.6,0.5-1.1,1.1-1.1h2.2
	c0.6,0,1.1,0.5,1.1,1.1v0.8l-4.4,0.1c0,0,0-0.1,0-0.1V10.6z M8,9.5h2.2c0.6,0,1.1,0.5,1.1,1.1v0.8l-4.4,0.1c0,0,0-0.1,0-0.1v-0.9
	C6.9,10,7.4,9.5,8,9.5z M20,11.6c-0.2,0-0.4,0-0.6-0.1c0,0,0-0.1,0-0.1v-0.9c0-1.2-1-2.2-2.2-2.2H15c-1.2,0-2.2,1-2.2,2.2v0.9
	c0,0,0,0.1,0,0.1h-0.5c0,0,0-0.1,0-0.1v-0.9c0-1.2-1-2.2-2.2-2.2H8c-1.2,0-2.2,1-2.2,2.2v0.9c0,0,0,0.1,0,0.1H5.7
	c-0.3,0-0.5,0-0.8,0.1v-6H20V11.6z M13.4,12.6c0,0,0.1,0,0.1,0l5.7,0c2,0,3.7,1.5,4,3.6c0,0,0,0.1,0,0.1v2.6H1.8v-2.6
	c0,0,0-0.1,0-0.1c0.2-2,1.9-3.6,4-3.6l0.6,0c0,0,0.1,0,0.1,0L13.4,12.6z"
        />
    </svg>
</template>

<script>
export default {
    name: 'SpaceTypeRestSvg',
    props: {
        isSelected: {
            type: Boolean,
            default: false,
            required: true
        },
        isChipBtn: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '24'
        },
        height: {
            type: String,
            default: '24'
        }
    }
}
</script>

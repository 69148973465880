<template>
    <div>
        <div
            v-if="screenType === 'mobile'"
            class="d-flex align-items-center px-4"
        >
            <ImageContainer
                :src="items[itemType].imgSrc"
                :alt="items[itemType].title"
                class="img-amenities mr-2 mr-lg-0"
                width="240"
                height="240"
            />
            <div>
                <p class="text-15 text-bold text-ffc000">
                    {{ items[itemType].title }}
                </p>
                <pre class="text-13 text-868686 text-mid">
                    {{ items[itemType].description }}
                </pre>
                <pre
                    v-if="items[itemType].description2"
                    class="text-13 text-868686 text-mid"
                >
                    {{ items[itemType].description2 }}
                </pre>
            </div>
        </div>
        <div
            v-else
            class="col d-flex flex-column align-items-center px-0 pt-4 h-100"
        >
            <ImageContainer
                :src="items[itemType].imgSrc"
                :alt="items[itemType].title"
                class="img-amenities"
                width="240"
                height="240"
            />
            <div class="d-flex flex-column justify-content-between h-100">
                <p
                    :style="
                        items[itemType].altText === 'flexible-check-in' &&
                        paramsLang === 'ms'
                            ? 'max-width: 90%'
                            : ''
                    "
                    class="text-15 text-bold text-ffc000 text-center mb-2"
                >
                    {{ items[itemType].title }}
                </p>
                <pre
                    :style="
                        items[itemType].altText === 'flexible-check-in'
                            ? 'white-space: nowrap'
                            : ''
                    "
                    class="text-13 item-description text-868686"
                >
                {{ items[itemType].description }}
                </pre>
                <pre
                    v-if="items[itemType].description2"
                    class="text-13 item-description text-868686"
                >
                {{ items[itemType].description2 }}
                </pre>
            </div>
        </div>
    </div>
</template>

<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'WhyFlowItem',
    components: {ImageContainer},
    props: {
        screenType: {
            required: true,
            type: String
        },
        itemType: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            items: {
                checkIn: {
                    imgSrc:
                        'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/78232f16-faab-483e-d07f-c8e303eb0a00/mobile',
                    altText: 'flexible-check-in',
                    title: this.$t('home.why_flow_1'),
                    description: this.$t('home.why_flow_1_remark')
                },
                discount: {
                    imgSrc:
                        'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/2dd980d8-7e9d-41c3-3cc4-f85e81163800/mobile',
                    altText: 'up-to-75%',
                    title: this.$t('home.why_flow_2'),
                    description: this.$t('home.why_flow_2_remark')
                },
                amenities: {
                    imgSrc:
                        'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b9f31274-d50a-478a-1762-172201bfe100/mobile',
                    altText: 'full-access',
                    title: this.$t('home.why_flow_3'),
                    description: this.$t('home.why_flow_3_remark')
                },
                freeCancel: {
                    imgSrc:
                        'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f91a245f-56e9-4ea4-2145-e8da03c2cb00/mobile',
                    altText: 'free-cancellation',
                    title: this.$t('home.why_flow_4'),
                    description: this.$t('home.why_flow_4_remark_1'),
                    description2: this.$t('home.why_flow_4_remark_2')
                },
                priceGuarantee: {
                    imgSrc:
                        'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/9a85ef63-c914-4595-634f-9833c6b68400/mobile',
                    altText: 'best-price-guarantee',
                    title: this.$t('home.why_flow_5'),
                    description: this.$t('home.why_flow_5_remark')
                }
            }
        }
    }
}
</script>

<style scoped>
.item-description {
    text-align: center;
}

.img-amenities {
    object-fit: cover;
    height: auto;
    width: 115px;
}
@media (min-width: 415px) and (max-width: 991px) {
    .img-amenities {
        width: 90px;
    }
}

@media (max-width: 414px) {
    .img-amenities {
        width: 70px;
    }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.dealsListResultHits),function(item,dealsIndex){return _c('div',{key:dealsIndex},[(item.spaceTypes?.length > 0)?_c('div',[(
                    item.deal?.length > 0 && item.deal[0].tags?.length > 0
                )?_c('HeaderSection',[_c('HeaderTitle',[_vm._v("\n                    "+_vm._s(_vm.dealsTitle(item.deal[0].tags, item.tag)))])],1):_vm._e(),_vm._v(" "),(item.deal?.length > 0)?_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('NavigationBar',{attrs:{"nav-btn":_vm.spaceTypeList(item),"item-selected":_vm.spaceTypeSelected[item.tag],"deal":item,"date":item.date},on:{"handleBtnClick":_vm.setDealSpaceType}}),_vm._v(" "),_c('NuxtLink',{staticClass:"text-16 text-b5b3ab text-reg text-decoration-none",staticStyle:{"letter-spacing":".5px"},attrs:{"to":_vm.dealsListPath(item.tag, item.date),"variant":"transparent","aria-label":"get amenities"}},[_vm._v("\n                    "+_vm._s(_vm.$t('details.see_all'))+"\n                ")])],1):_vm._e(),_vm._v(" "),(
                    _vm.dealsList?.length > 0 &&
                    _vm.spaceTypeSelected[item.tag] === _vm.getSpaceTypeByNum(
                        item.uspTag?.space_types[0]
                    ))?_c('BestPriceGuaranteeButton',{staticClass:"px-2 mb-3",attrs:{"uspTag":item.uspTag}}):_vm._e(),_vm._v(" "),(item.deal?.length > 0)?_c('Deals',{attrs:{"deal":item.deal,"space-type":_vm.spaceTypeSelected[item.tag],"name":dealsIndex.toString(),"price-field":item.priceField,"date":item.date,"deals-btn-title":_vm.dealsTitle(item.deal[0].tags, item.tag, 'en'),"tag":item.tag},on:{"navigateToDetails":_vm.navigateToDetails}}):_vm._e()],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
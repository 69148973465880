<template>
    <div class="d-lg-flex flex-wrap justify-content-lg-around pt-3 pt-lg-0">
        <NuxtLink
            v-if="isShowRewards"
            :to="{ path: computeLinkToBenefits('rewards') }"
            class="benefits-card flex-center py-5 px-5 px-lg-4 m-lg-0"
            variant="transparent"
        >
            <div class="text-center">
                <ImageContainer
                    class="benefit-img"
                    width="130"
                    height="130"
                    src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/dcab1275-524c-4aea-f61e-55fb8e431f00/public"
                    style="object-fit: contain;"
                    :alt="$t('member.benefit_1')"
                />
                <div class="text-18 text-bold text-ffc000 mt-3">
                    {{ $t('member.benefit_1') }}
                </div>
                <pre
                    :class="paramsLang === 'ms' ? 'benefit-description' : ''"
                    class="text-14 text-868686 mt-4"
                >
                    {{
                        $t('member.benefit_1_description', {
                            price: priceReward.spend
                        })
                    }}
                </pre>
            </div>
        </NuxtLink>
        <NuxtLink
            v-if="isShowInviteFriends"
            :to="{ path: computeLinkToBenefits('invite-friends') }"
            class="benefits-card flex-center py-5 px-5 px-lg-4 m-lg-0 mt-5"
            variant="transparent"
        >
            <div class="text-center">
                <ImageContainer
                    class="benefit-img"
                    width="130"
                    height="130"
                    loading="lazy"
                    src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/8b7b70f8-2231-450e-c3cf-a6cbab795e00/public"
                    style="object-fit: contain;"
                    :alt="$t('member.benefit_2')"
                />
                <div class="text-18 text-bold text-ffc000 mt-3">
                    {{ $t('member.benefit_2') }}
                </div>
                <pre
                    :class="paramsLang === 'ms' ? 'benefit-description' : ''"
                    class="text-14 text-868686 mt-4"
                >
                    {{
                        $t('member.benefit_2_description', {
                            price: priceReward.inviteFriends
                        })
                    }}
                </pre>
            </div>
        </NuxtLink>
        <NuxtLink
            v-if="isShowTopUp"
            :to="{ path: computeLinkToBenefits('top-up') }"
            class="benefits-card flex-center py-5 px-5 px-lg-4 m-lg-0 mt-5"
            variant="transparent"
        >
            <div class="text-center">
                <ImageContainer
                    class="benefit-img"
                    width="130"
                    height="130"
                    loading="lazy"
                    src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/33c62a19-5efe-4510-3e76-7e8090858700/public"
                    style="object-fit: contain;"
                    :alt="$t('member.benefit_3')"
                />
                <div class="text-18 text-bold text-ffc000 mt-3">
                    {{ $t('member.benefit_3') }}
                </div>
                <pre
                    :class="paramsLang === 'ms' ? 'benefit-description' : ''"
                    class="text-14 text-868686 mt-4"
                >
                    {{ $t('member.benefit_3_description') }}
                </pre>
            </div>
        </NuxtLink>
    </div>
</template>
<script>
import memberBenefits from '~/services/memberBenefits'
import ImageContainer from "@/components/ui/ImageContainer.vue";
export default {
    name: 'FlowBenefits',
    components: {ImageContainer},
    data() {
        return {
            priceReward: {
                spend: '',
                inviteFriends: ''
            }
        }
    },
    computed: {
        isShowRewards() {
            return this.$route.name !== 'region-locale-rewards'
        },
        isShowInviteFriends() {
            return this.$route.name !== 'region-locale-invite-friends'
        },
        isShowTopUp() {
            return (
                this.$route.name !== 'region-locale-top-up' &&
                this.region !== 'vn'
            )
        },
        region() {
            return this.$route.params.region
        },
        lang() {
            return this.$route.params.locale
        }
    },
    created() {
        this.priceReward.spend = memberBenefits.getPricePerRewardPoint(
            this.region
        )
        this.priceReward.inviteFriends = memberBenefits.getInviteFriendPriceReward(
            this.region
        )
    },
    methods: {
        computeLinkToBenefits(page) {
            let path = ''
            switch (page) {
                case 'rewards':
                    path = `/${this.region}/${this.lang}/rewards`
                    break
                case 'invite-friends':
                    path = `/${this.region}/${this.lang}/invite-friends`
                    break
                case 'top-up':
                    path = `/${this.region}/${this.lang}/top-up`
                    break
            }
            return path
        }
    }
}
</script>

<style scoped>
.benefits-card {
    max-width: 346px;
    margin: auto;
    text-decoration: none;
}
@media (min-width: 992px) {
    .benefits-card {
        width: 346px;
        aspect-ratio: 346 / 369;
        border-radius: 15px;
        border: 1px solid #e6e6e6;
        transition: all 0.2s ease;
    }
    .benefits-card:hover {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
        transform: translateY(-4px);
        transform-style: preserve-3d;
        text-decoration: none;
    }
    .benefit-description {
        max-width: 80%;
        margin: auto;
    }
}
@media (max-width: 992px) {
    .benefits-card {
        border-radius: 15px;
        border: 1px solid #e6e6e6;
        width: 82%;
        box-shadow: 0 4px 25px 0 #0000000d;
    }
}
</style>

<template>
    <NuxtLink
        :to="{
            path: computeLinkToDetails({
                btn: dealsBtnTitle,
                services: spaceType,
                slug: listItem.slug,
                countryCode: listItem.location?.country?.code,
                spaceName: listItem?.name?.[algoliaLang],
                date: nextAvailableDate(listItem.next_available)
            })
        }"
        class="card-container btn-active-shrink card-shadow"
    >
        <div
            @click.prevent="
                onLinkClicked({
                    btn: eventBtnName(dealsBtnTitle),
                    services: spaceType,
                    slug: listItem.slug,
                    countryCode: listItem.location?.country?.code,
                    spaceName: listItem?.name?.[algoliaLang],
                    objectID: listItem?.id,
                    date: nextAvailableDate(listItem.next_available),
                    position: itemIdx,
                    filter: tag
                })
            "
            class="image-container card h-100"
        >
            <div
                :style="
                    getPriceFromFilter(listItem, priceField)
                        ? ''
                        : 'filter: opacity(0.6);'
                "
                class="position-relative"
            >
                <div class="image-placeholder">
                    <ImageContainer
                        :src="listItem.images[0]"
                        :alt="listItem?.name?.[algoliaLang]"
                        height="200"
                        width="356"
                        class="list-item-img"
                    />
                </div>
                <SpaceCardFavButton
                    :slug="listItem.slug"
                    :space-name="listItem?.name?.[algoliaLang]"
                    :space="globalSpace(spaceType)"
                    class="position-absolute"
                    style="right: 0; top: 3%; z-index: 1;"
                />
                <div
                    v-if="!getPriceFromFilter(listItem, priceField)"
                    class="position-absolute text-white text-s-bold bg-dark text-15"
                    style="top:0; border-radius: 10px 0 5px 0; background-color: #313131; padding: 0.4rem 0.6rem"
                >
                    {{ $t('list.sold_out') }}
                </div>
            </div>
            <div class="card-bottom-section py-2 py-lg-3 px-lg-3">
                <div>
                    <h3 class="space-name mb-0 text-404040 hide-overflow-text">
                        {{ listItem?.name?.[algoliaLang] }}
                    </h3>
                    <div>
                        <div>
                            <div class="d-flex justify-content-between align-items-start mt-1 mt-sm-2">
                                <div class="d-flex flex-column">
                                    <StarRating
                                        :star-rating="listItem.ranking?.star_rating"
                                        :type="type"
                                        class="mb-1"
                                    />
                                    <SpaceCardLocation
                                        :algolia-lang="algoliaLang"
                                        is-hide-location-link
                                        :list-item="listItem"
                                        :type="'HOME'"
                                        is-hide-icon
                                        class="ml-1"
                                    />
                                </div>
                                <div v-if="listItem?.ranking?.user_rating" class="d-flex align-items-center">
                                    <FeatureFrameIconSvg :fill-color="'#ffc000'" class="mr-1 d-none d-lg-block"/>
                                    <UserRating
                                        v-if="listItem?.ranking?.user_rating"
                                        :rating="listItem?.ranking?.user_rating"
                                        class="mr-1"
                                    />
                                    <TotalComment
                                        v-if="listItem.ranking?.total_comments"
                                        :comments="listItem.ranking?.total_comments"
                                    />
                                </div>
                            </div>

                        </div>
                        <SpaceCardDataTags v-if="false" />
                    </div>
                </div>
                <div>
                    <SpaceCardTopComment
                        :content="listItem.top_comments?.[0]"
                        class="d-none d-lg-block mt-3"
                    />
                    <div
                        v-if="
                            !hideNextAvailableDisplayDate(
                                listItem.next_available
                            )
                        "
                        class="earliest-available px-2 py-1 px-sm-3 py-sm-2 mt-2 mt-sm-3 d-sm-flex align-items-center justify-content-between"
                    >
                        <div class="text-13 mr-1 mr-lg-2">
                            {{ $t('list.earliest_available_date') }}
                        </div>
                        <div class="earliest-available-date text-s-bold">
                            {{
                                nextAvailableDisplayDate(
                                    listItem.next_available
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-end mt-2">
                        <div class="d-flex align-items-center justify-content-between mb-1">
                            <SessionSvg class="mr-1 d-none d-lg-block" />
                            <div class="text-s-bold timeslot-text mt-1"
                                 style="color: var(--palette-primary-yellow);"
                            >
                                {{ $t('home.session') }}
                            </div>
                        </div>
                        <MinPrice
                            :space-type="spaceType"
                            :price="getPriceFromFilter(listItem, priceField)"
                            :is-room-price="listItem?.is_room_price"
                            class="deal-price justify-content-end text-18"
                        />
                    </div>
                </div>
            </div>
        </div>
    </NuxtLink>
</template>

<script>
import { getAlgoliaLang } from '@/services/algoliaApi'
import dateServices from '@/services/dateServices'
import MinPrice from '@/components/item/MinPrice.vue'
import SpaceCardDataTags from '@/components/list/SpaceCardDataTags.vue'
import UserRating from '@/components/item/UserRating.vue'
import TotalComment from '@/components/item/TotalComment.vue'
import SpaceCardLocation from '@/components/list/SpaceCardLocation.vue'
import SpaceCardFavButton from '@/components/list/SpaceCardFavButton.vue'
import SpaceCardTopComment from '@/components/list/SpaceCardTopComment.vue'
import SessionSvg from "~/components/iconSvg/SessionSvg.vue"
import StarRating from "@/components/item/StarRating.vue";
import FeatureFrameIconSvg from "@/components/iconSvg/FeatureFrameIconSvg.vue";
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'HomeSpaceCard',
    components: {
        ImageContainer,
        FeatureFrameIconSvg,
        StarRating,
        SessionSvg,
        SpaceCardTopComment,
        SpaceCardFavButton,
        SpaceCardLocation,
        TotalComment,
        UserRating,
        SpaceCardDataTags,
        MinPrice
    },
    props: {
        spaceType: {
            type: String,
            default: ''
        },
        listItem: {
            type: Object,
            default() {
                return {}
            }
        },
        priceField: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        dealsBtnTitle: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        itemIdx: {
            type: Number,
            default: 0
        },
        tag: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    computed: {
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        }
    },
    methods: {
        computeLinkToDetails(params) {
            const region = params.countryCode.toLowerCase()
            let lang = this.lang
            lang = this.switchDefaultLang(lang, region)
            const space = this.globalSpace(params.services)
            return `/${region}/${lang}/${params.slug}/${space}`
        },
        onLinkClicked(params) {
            this.$emit('navigateToDetails', params)
        },
        getPriceFromFilter(item, priceFilter) {
            const filters = priceFilter.split('.')
            filters.map((filter) => {
                item = item[filter]
            })
            return item === '-' ? '' : this.getDisplayPrice(item)
        },
        nextAvailableDisplayDate(nextAvailable) {
            const date = this.nextAvailableDate(nextAvailable)
            return dateServices.getToday() === date
                ? this.$t('home.today')
                : dateServices.getTomorrow() === date
                ? this.$t('home.tomorrow')
                : dateServices.formatSearchBarDisplayDate(date)
        },
        hideNextAvailableDisplayDate(nextAvailable) {
            // const date = this.nextAvailableDate(nextAvailable)
            // return dateServices.getToday() === date
            return false
        },
        nextAvailableDate(nextAvailable) {
            return nextAvailable ? nextAvailable.split(' ')[0] : this.date
        }
    }
}
</script>

<style scoped>
.card-container {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.list-item-img {
    aspect-ratio: 16/9;
    height: auto;
    object-fit: cover;
    width: 100%;
}
.earliest-available {
    color: #404040;
    background: #f2f3f3;
    border-radius: 6px;
}
.image-container {
    /*fix Overflow hidden + round corners not working on Safari bug*/
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}
.card-container,
.card {
    border-radius: 10px;
}
.card-bottom-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.space-name {
    font-weight: 700;
}
@media (max-width: 991px) {
    .card-bottom-section {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
    .space-name {
        font-size: 13px;
        -webkit-line-clamp: 1;
    }
    .card {
        width: 100%;
    }
    .deal-price {
        font-size: 16px;
    }
    .earliest-available-date {
        font-size: 12px;
    }
    .timeslot-text {
        font-size: 11px;
    }
}
@media (min-width: 577px) and (max-width: 991px) {
    .space-name {
        font-size: 16px;
    }
}
@media (min-width: 992px) {
    .space-name {
        font-size: 18px;
    }
    .deal-price {
        font-size: 27px;
    }
    .earliest-available-date {
        font-size: 15px;
    }
    .timeslot-text {
        font-size: 15px;
    }
}
</style>

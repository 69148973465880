<template>
    <svg
        width="14"
        height="14"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.9996 8.84482V9.25491C14.9747 9.3235 14.9457 9.391 14.9255 9.46106C14.8138 9.85134 14.5685 10.1397 14.2332 10.3638C14.2644 10.4203 14.2949 10.4731 14.3236 10.527C14.4774 10.8182 14.5424 11.1333 14.4929 11.4536C14.4168 11.9443 14.1506 12.3247 13.725 12.5844C13.6442 12.6336 13.6508 12.6585 13.6846 12.7381C13.7555 12.9057 13.8326 13.0814 13.8506 13.2593C13.9064 13.8074 13.7316 14.2743 13.2968 14.6316C13.0247 14.8553 12.7166 14.9899 12.3622 14.9925C11.6733 14.9976 10.984 15.0013 10.2951 14.9932C9.24588 14.9811 8.19376 15.0519 7.14863 14.9059C6.68188 14.8407 6.21036 14.7937 5.75205 14.6895C5.20231 14.5644 4.66542 14.3832 4.12008 14.2369C4.01395 14.2083 4.00073 14.1595 4.0011 14.0682C4.00294 11.4715 4.00331 8.8749 4 6.27827C4 6.1774 4.04113 6.11981 4.11311 6.06259C4.41607 5.82086 4.72969 5.58977 5.01392 5.32751C5.31872 5.0458 5.60296 4.74025 5.88169 4.43213C6.16115 4.12328 6.4105 3.78692 6.56473 3.39737C6.88202 2.59553 7.18094 1.78635 7.49529 0.983043C7.64439 0.601564 7.86326 0.272905 8.26794 0.11371C8.36159 0.0766627 8.45523 0.0377811 8.54888 0C8.68585 0 8.82246 0 8.95944 0C8.97376 0.00953699 8.98698 0.0242093 9.0024 0.0275105C9.4809 0.124348 9.83859 0.372309 9.97483 0.858329C10.1122 1.34875 10.1713 1.84797 10.1067 2.36077C10.0519 2.79324 10.0332 3.2301 9.98364 3.66367C9.95169 3.94134 9.89477 4.21608 9.84556 4.49155C9.79929 4.75089 9.74861 5.00949 9.69647 5.28496H9.87825C10.8745 5.28496 11.8708 5.28239 12.8671 5.28642C13.252 5.28789 13.6078 5.37996 13.9141 5.63489C14.4187 6.05452 14.6012 6.57942 14.4462 7.21363C14.4033 7.3886 14.3177 7.55293 14.249 7.72973C14.574 7.92964 14.8072 8.21575 14.9188 8.59613C14.9434 8.67976 14.9728 8.76229 15 8.84519L14.9996 8.84482Z"
            :fill="fillColor"
        />
        <path
            d="M2.99965 7.00169V13.9885C2.95838 13.9913 2.9224 13.9955 2.88677 13.9955C2.08007 13.9958 1.27302 13.9889 0.466317 14C0.221873 14.0031 -0.00105445 13.7848 3.7522e-06 13.5422C0.00882208 11.5053 0.00600022 9.46833 0.00423655 7.43139C0.00423655 7.18535 0.159086 7.00793 0.411643 7.00515C1.26984 6.99614 2.12804 7.00169 3 7.00169H2.99965Z"
            :fill="fillColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'FeatureFrameIconSvg',
    props: {
        fillColor: {
            type: String,
            required: false,
            default: '#765821'
        }
    }
}
</script>

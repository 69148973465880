var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.bookAgain.length > 0)?_c('SwiperContainer',{attrs:{"option":_vm.swiperOption,"name":'book-again',"is-free-mode":true}},_vm._l((_vm.bookAgain),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide card-item mt-2",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"d-flex w-100"},[_c('NuxtLink',{staticClass:"p-0 m-0 list-item w-100",staticStyle:{"text-decoration":"none"},attrs:{"to":{
                    path: _vm.computeLinkToDetails({
                        btn: 'Landing Book again',
                        services: item.space.space_type,
                        slug: item.space.slug,
                        countryCode: item.location.region,
                        spaceName: item.space.space_name
                    })
                }}},[_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.onLinkClicked({
                            btn: 'Landing Book again',
                            services: item.space.space_type,
                            slug: item.space.slug,
                            countryCode: item.location.region,
                            spaceName: item.space.space_name,
                            position: index
                        })}}},[_c('div',{staticClass:"w-100 position-relative"},[(item.space)?_c('ImageContainer',{staticClass:"w-100 list-item-img",attrs:{"src":item.space.thumbnail,"alt":item.space.space_name,"loading":index !== 0 && index !== 1 ? 'lazy' : ''}}):_vm._e(),_vm._v(" "),(item.par_rate)?_c('div',{staticClass:"text-16 text-mid border-1 px-4 py-2 button-box-shadow position-absolute bg-primary",staticStyle:{"color":"white","margin-right":"5px","border-radius":"20%/50%","top":"3%","left":"3%","font-weight":"600"}},[_vm._v("\n                            "+_vm._s(item.par_rate)+"\n                        ")]):_vm._e(),_vm._v(" "),_c('HeartButton',{staticClass:"position-absolute",staticStyle:{"right":"0","top":"3%"},attrs:{"slug":item.space.slug,"space-name":item.space.space_name,"space":_vm.globalSpace(item.space.space_type)}})],1),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap mb-1"},[(item.booking)?_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-17 text-404040",staticStyle:{"font-weight":"bolder"}},[_vm._v("\n                                    "+_vm._s(_vm.getDisplayPrice(
                                            item.booking.charge_price,
                                            item.location.region
                                        ))+"\n                                ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-14 text-primary",staticStyle:{"font-weight":"600","white-space":"nowrap"}},[_vm._v("\n                                    "+_vm._s(item.space.space_type_string)+"\n                                ")])])]),_vm._v(" "),_c('div',[_c('h3',{staticClass:"text-14 text-9d9d9d align-self-center align-items-baseline mb-0 font-weight-normal"},[_vm._v("\n                                "+_vm._s(item.space.space_name)+"\n                            ")])]),_vm._v(" "),_c('div',{staticClass:"text-13",staticStyle:{"color":"rgb(199 199 199)"}},[_vm._v("\n                            "+_vm._s(item.booking.from_now)+"\n                        ")])])])])],1)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
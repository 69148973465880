<template>
    <div>
        <div class="d-flex row m-0">
            <div
                v-for="(link, index) in links"
                :key="index"
                class="px-0 pr-md-3 col-md-4"
            >
                <NuxtLink
                    :class="
                        index < 8
                            ? 'd-block'
                            : isHideSpace
                            ? 'd-none d-lg-block'
                            : ''
                    "
                    :to="computeLink(link.slug, space, keyword)"
                    class=""
                    style="border-radius: 10px; cursor: pointer; "
                >
                    <TextButton
                        class="text-14 mr-2 mb-2 text-1e1e1e internal-link-btn"
                    >
                        <div>
                            {{ getHeader('nearby_location', link.name, true) }}
                        </div>
                    </TextButton>
                </NuxtLink>
            </div>
            <div v-if="isDisplayBtn" class="mt-3 d-lg-none">
                <TextButton class="text-14">
                    <div @click="toggleText">
                        {{
                            isHideSpace
                                ? $t('details.see_all')
                                : $t('details.hide')
                        }}
                    </div>
                </TextButton>
            </div>
        </div>
    </div>
</template>
<script>
import TextButton from '~/components/item/TextButton'
import {getGAEventName} from "@/utils/analysis";
export default {
    name: 'InternalLinks',
    components: { TextButton },
    props: {
        keyword: {
            type: String,
            default() {
                return 'maps'
            }
        },
        space: {
            type: String,
            default() {
                return 'hotel'
            }
        },
        // locationSlug: {
        //     type: String,
        //     default() {
        //         return ''
        //     }
        // },
        links: {
            type: Array,
            default() {
                return []
            }
        },
        isDisplayBtn: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return { isHideSpace: true }
    },
    computed: {
        locationDetails() {
            // console.log(this.$store.getters['api/locationDetails'])
            return this.$store.getters['api/locationDetails']
        },
        localeKeyword() {
            return this.keyword ? this.keyword?.replaceAll('-', '_') : ''
        }
    },
    // async fetch() {
    //     await this.getLocationDetails()
    // },
    methods: {
        // async getLocationDetails() {
        //     if (this.locationSlug) {
        //         await this.$store.dispatch('api/GET_LOCATION_DETAILS', {
        //             spaceType: this.globalSpaceType(this.space),
        //             slug: this.locationSlug,
        //             lang: this.paramsLang,
        //             region: this.paramsRegion
        //         })
        //     }
        // },
        getHeader(meta, location = this.locationDetails.name, isCommonKeyword) {
            const keywordTail =
                this.keyword === 'maps' || isCommonKeyword
                    ? ''
                    : `_${this.localeKeyword}`
            const keyword = this.$t(`keywords.${this.localeKeyword}`)
            // handle the duplicate country name later
            const obj = {
                location,
                country: this.regionName(),
                keyword
            }
            const spaceTypeNum = this.globalSpaceTypeNum(this.space)
            return this.$t(
                `location${keywordTail}.${meta}_${spaceTypeNum}`,
                obj
            )
        },
        toggleText() {
            this.isHideSpace = !this.isHideSpace
        },
        computeLink(slug, space, keyword) {
            const source = getGAEventName(this.landingPageName, 'EXPLORE_FLEXIBLE_SPACES', keyword, undefined, slug)
            return {
                name: 'region-locale-space-location-keyword',
                params: {
                    location: slug,
                    space,
                    source,
                    keyword
                }
            }
        }
    }
}
</script>
<style scoped>
.internal-link-btn {
    /*text-decoration: none;*/
    color: #f2bc1f;
}
@media (min-width: 992px) {
    .internal-link-btn:hover {
        text-decoration: underline;
        /*color: #ffc000 !important;*/
        color: #9d9d9d !important;
    }
}
</style>

<template>
    <div>
        <div
            v-for="(item, dealsIndex) in dealsListResultHits"
            :key="dealsIndex"
        >
            <div v-if="item.spaceTypes?.length > 0">
                <HeaderSection
                    v-if="
                        item.deal?.length > 0 && item.deal[0].tags?.length > 0
                    "
                >
                    <HeaderTitle>
                        {{
                            dealsTitle(item.deal[0].tags, item.tag)
                        }}</HeaderTitle
                    >
                </HeaderSection>
                <div
                    v-if="item.deal?.length > 0"
                    class="d-flex justify-content-between align-items-center"
                >
                    <NavigationBar
                        :nav-btn="spaceTypeList(item)"
                        :item-selected="spaceTypeSelected[item.tag]"
                        :deal="item"
                        :date="item.date"
                        @handleBtnClick="setDealSpaceType"
                    />
                    <NuxtLink
                        :to="dealsListPath(item.tag, item.date)"
                        variant="transparent"
                        class="text-16 text-b5b3ab text-reg text-decoration-none"
                        style="letter-spacing: .5px;"
                        aria-label="get amenities"
                    >
                        {{ $t('details.see_all') }}
                    </NuxtLink>
                </div>
                <BestPriceGuaranteeButton
                    v-if="
                        dealsList?.length > 0 &&
                        spaceTypeSelected[item.tag] === getSpaceTypeByNum(
                            item.uspTag?.space_types[0]
                        )"
                    class="px-2 mb-3"
                    :uspTag="item.uspTag"
                />
                <Deals
                    v-if="item.deal?.length > 0"
                    :deal="item.deal"
                    :space-type="spaceTypeSelected[item.tag]"
                    :name="dealsIndex.toString()"
                    :price-field="item.priceField"
                    :date="item.date"
                    :deals-btn-title="
                        dealsTitle(item.deal[0].tags, item.tag, 'en')
                    "
                    :tag="item.tag"
                    @navigateToDetails="navigateToDetails"
                />
            </div>
        </div>
    </div>
</template>
<script>
import NavigationBar from '@/components/item/NavigationBar.vue'
import HeaderTitle from '@/components/home/HeaderTitle.vue'
import Deals from '@/components/home/Deals.vue'
import HeaderSection from '@/components/home/HeaderSection.vue'
import dateServices from '@/services/dateServices'
import { computeSearchIndex } from '@/lib-flow-main-search/src/search'
import { getAlgoliaLang, getSpaceTypeId } from '@/services/algoliaApi'
import analytics from "@/services/analytics";
import BestPriceGuaranteeButton from "@/components/item/BestPriceGuaranteeButton.vue";
import _isEmpty from 'lodash/isEmpty'
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'DealsSection',
    components: {BestPriceGuaranteeButton, HeaderSection, Deals, HeaderTitle, NavigationBar },
    props: {},
    data() {
        return {
            spaceTypeSelected: {},
            isFetchCompleted: false,
            uspTag: {}
        }
    },
    async fetch() {
        await this.getDealList()
    },
    computed: {
        dealsListResultHits() {
            return this.$store.getters['algoliaSearch/dealsListResultHits']
        },
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        },
        dealsList() {
            return this.$store.getters['api/dealsList']
        },
        isLanding() {
            return this.$route.name === 'region-locale'
        }
    },
    watch: {
        paramsGroup: {
            immediate: false,
            async handler(newVal, oldVal) {
                if (newVal !== oldVal && this.isLocationGroupValid) {
                    await this.getDealList()
                }
            }
        }
    },
    async mounted() {
        if (!this.isBlockGooglebotFromButton) {
            await this.getDealList()
        }
    },
    methods: {
        _isEmpty,
        dealsListPath(filter, date) {
            const query = {
                date,
                time: 'any_time',
                f_theme: filter
            }
            const space = this.globalSpace(this.spaceTypeSelected[filter])
            const source = getGAEventName(this.landingPageName, filter, 'see all')
            return {
                name: 'region-locale-space',
                params: {
                    space,
                    source
                },
                query
            }
        },
        setDealSpaceType(type, deal) {
            this.spaceTypeSelected[deal.tag] = type.value
            this.getDeals(
                deal.tag,
                deal.priceField,
                deal.priceFilterValue,
                deal.spaceTypes,
                deal.date,
                deal.override_tags,
                deal.uspTag,
            )
        },
        async getDeals(tag, priceField, priceFilterValue, spaceTypes, date, override_tags, uspTag) {
            switch (date) {
                case null:
                case 'today':
                    date = dateServices.getToday()
                    break
                case 'tmr':
                    date = dateServices.getTomorrow()
                    break
            }
            const params = {
                lang: this.paramsLang,
                region: this.paramsRegion,
                algoliaIndex: computeSearchIndex(
                    this.paramsRegion,
                    getSpaceTypeId(this.spaceTypeSelected[tag]),
                    process.env.TYPESENSE_ENV
                ),
                algoliaLang: this.algoliaLang,
                space_type: this.spaceTypeSelected[tag],
                tag,
                priceField,
                priceFilterValue,
                spaceTypes,
                totalDeals: this.dealsList?.length,
                isSkipAnalytics: true,
                date,
                uspTag,
                override_tags,
            }
            if (this.paramsRegion === 'my' && this.paramsGroup) {
                params.group = this.paramsGroup
            }
            await this.$store.dispatch('algoliaSearch/SEARCH_DEALS', params)
        },
        spaceTypeList(item) {
            const spaceTypeList = []
            if (item.spaceTypes.length > 0) {
                item.spaceTypes.map((spaceTypeNum) => {
                    let type = ''
                    switch (spaceTypeNum) {
                        case 1:
                            type = 'rest'
                            break
                        case 2:
                            type = 'work'
                            break
                        case 3:
                            type = 'meet'
                            break
                        case 4:
                            type = 'staycation'
                            break
                        case 5:
                            type = 'party'
                            break
                    }
                    const obj = { text: this.$t(`home.${type}`), value: type }
                    spaceTypeList.push(obj)
                })
            }
            return spaceTypeList
        },
        async getDealList() {
            const dealList = await this.$store.dispatch('api/GET_DEALS_LIST', {
                lang: this.paramsLang,
                region: this.paramsRegion
            })
            if (dealList.length > 0) {
                for (const deal of dealList) {
                    // set default deal space type
                    let num = 1
                    if (deal.space_types.length > 0) {
                        num = deal.space_types[0]
                    }
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    this.spaceTypeSelected[deal.tag] = this.getSpaceTypeByNum(
                        num
                    )
                    await this.getDeals(
                        deal.tag,
                        deal.price_field,
                        deal.price_filter_value,
                        deal.space_types,
                        deal.date,
                        deal.override_tags,
                        deal.usp_tag,
                    )
                }
            }
        },
        dealsTitle(tags, slug, lang) {
            const tagLang = lang ?? this.algoliaLang
            let title = ''
            if (this.dealsListResultHits.length > 0) {
                tags.map((tag) => {
                    if (tag.slug === slug) {
                        title = tag.content[tagLang]
                    }
                })
                return title
            }
            return ''
        },
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        }
    }
}
</script>
<style scoped></style>

<template>
    <svg
        width="24"
        height="10"
        viewBox="0 0 24 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.503157 0.63341C0.50345 0.632792 0.503769 0.632147 0.504116 0.631474C0.508488 0.623008 0.520149 0.60499 0.548851 0.582614C0.692448 0.470669 0.961966 0.47067 1.10556 0.582615L1.41049 0.19147L1.10556 0.582615L11.6924 8.83597L11.9998 9.07563L12.3073 8.83597L22.8922 0.584138C22.8924 0.583947 22.8927 0.583755 22.8929 0.583564C23.0368 0.473253 23.3062 0.472936 23.4508 0.582613C23.4782 0.604533 23.4899 0.622446 23.4946 0.631479C23.4991 0.640217 23.499 0.644375 23.499 0.644908L23.499 0.644942L23.499 0.644976C23.499 0.645509 23.4991 0.649667 23.4946 0.658405C23.4898 0.667569 23.4778 0.685871 23.4496 0.708225C23.4494 0.708418 23.4491 0.70861 23.4489 0.708803L12.2782 9.41728C12.1346 9.52922 11.8651 9.52922 11.7215 9.41728L0.552217 0.707311L0.552162 0.707268C0.523457 0.68489 0.511793 0.66687 0.507421 0.658401C0.503178 0.65018 0.503157 0.64611 0.503157 0.644941L0.503157 0.63341Z"
            fill="#AFAFAF"
            stroke="#AFAFAF"
        />
    </svg>
</template>
<script>
export default {
    name: 'ExpandMore'
}
</script>

<template>
    <div v-if="!_isEmpty(uspTag)">
        <b-button
            @click="setModalVisible"
            variant="transparent"
            class="p-0 d-flex align-items-center"
        >
            <ImageContainer
                v-show="!isInfo"
                :src="uspTag?.icon"
                :alt="uspTag?.text"
                class="best-price-guarantee-image"
            />
            <div :class="`px-1 best-price-guarantee-text ${isInfo ? '' : 'text-bold'}`">{{ uspTag?.text }}</div>
            <InfoSvg />
        </b-button>
        <BestPriceGuaranteeModal
            v-if="isModalVisible"
            :uspTagInfoButton="uspTag?.info_button"
            @close="setModalVisible"
        />
    </div>
</template>
<script>


import InfoSvg from "@/components/iconSvg/InfoSvg.vue";
import ImageContainer from "@/components/ui/ImageContainer.vue";
import BestPriceGuaranteeModal from "@/components/modal/BestPriceGuaranteeModal.vue";
import _isEmpty from "lodash/isEmpty";

export default {
    name: 'BestPriceGuaranteeButton',
    components: {BestPriceGuaranteeModal, ImageContainer, InfoSvg},
    props: {
        uspTag: {
            type: Object,
            required: false,
            default() {
                return {}
            }
        },
        isInfo: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isModalVisible: false
        }
    },
    methods: {
        _isEmpty,
        setModalVisible() {
            this.isModalVisible = !this.isModalVisible
        }
    }
}
</script>
<style>
.best-price-guarantee-text {
    font-size: 14px;
}
.best-price-guarantee-image {
    width: 24px;
}

@media (max-width: 767.98px) {
    .best-price-guarantee-text {
        font-size: 12px;
    }
    .best-price-guarantee-image {
        width: 22px;
    }
}
</style>

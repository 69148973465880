<template>
    <div>
        <div class="d-xl-none d-block">
            <div class="explore-flow-column">
                <div
                    v-for="(item, index) in spaceTypeInfo"
                    :key="`rest-${index}`"
                    class="btn-active-shrink margin-bottom-10"
                >
                    <NuxtLink :to="computePathToSpace(item.space_type)">
                        <div
                            class="w-100 position-relative d-flex align-items-center"
                        >
                            <ImageContainer
                                :src="item.banner"
                                :alt="item.description"
                                class="explore-list-item-img"
                            />
                            <div
                                class="d-flex explore-list-item-description align-items-center mx-4 px-3"
                            >
                                <ImageContainer
                                    :alt="item.space_type_string"
                                    :src="item.icon"
                                    class="explore-space-icon mr-3"
                                />
                                <div>
                                    <div class="text-19 text-s-bold">
                                        {{ item.space_type_string }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
            </div>
        </div>
        <div class="d-xl-block d-none">
            <div class="d-flex justify-content-between">
                <div
                    class="explore-flow-column w-50"
                    style="margin-right: 20px;"
                >
                    <div
                        v-for="(item, index) in restSpaceInfo"
                        :key="`rest-${index}`"
                    >
                        <NuxtLink
                            :to="computePathToSpace(item.space_type)"
                            class="margin-bottom-10 card-item round-image-swiper-wrapper explore-flow-link"
                        >
                            <ImageContainer
                                :src="item.banner"
                                :alt="item.description"
                                class="explore-list-item-img rest-img"
                            />
                            <div
                                class="explore-list-item-description"
                                style="top: 62px"
                            >
                                <div class="d-flex align-items-center">
                                    <ImageContainer
                                        :alt="item.space_type_string"
                                        :src="item.icon"
                                        class="explore-space-icon mr-3"
                                    />
                                    <div class="text-24 text-s-bold">
                                        {{ item.space_type_string }}
                                    </div>
                                </div>
                                <div class="text-17 text-s-thin">
                                    {{ item.remark }}
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-between w-50">
                    <div v-for="(item, index) in spaceTypeInfo" :key="index">
                        <NuxtLink
                            v-if="item.space_type !== 'rest'"
                            :to="computePathToSpace(item.space_type)"
                            class="btn-active-shrink margin-bottom-10 w-100 align-items-center card-item round-image-swiper-wrapper explore-flow-link"
                            style="margin-bottom: 20px;"
                        >
                            <ImageContainer
                                :src="item.banner"
                                :alt="item.description"
                                class="explore-list-item-img other-space-img"
                            />
                            <div class="explore-list-item-description">
                                <div class="d-flex align-items-center mb-1">
                                    <ImageContainer
                                        :alt="item.space_type_string"
                                        :src="item.icon"
                                        class="explore-space-icon mr-3"
                                    />
                                    <div class="text-24 text-s-bold">
                                        {{ item.space_type_string }}
                                    </div>
                                </div>
                                <div class="text-17 text-s-thin">
                                    {{ item.remark }}
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _filter from 'lodash/filter'
import dateServices from '@/services/dateServices'
import ImageContainer from "@/components/ui/ImageContainer.vue";
import analytics from "@/services/analytics";
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'ExploreFlow',
    components: {ImageContainer},
    props: {
        exploreFlow: {
            type: Array,
            require: true,
            default() {
                return []
            }
        }
    },
    computed: {
        spaceTypeInfo() {
            return this.$store.getters['api/spaceTypeInfo']
        },
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        restSpaceInfo() {
            return _filter(this.spaceTypeInfo, (i) => {
                return i.space_type === 'rest'
            })
        }
    },
    methods: {
        computePathToSpace(service) {
            const position = this.getPositionFromSpaceType(service);
            const date = dateServices.getToday()
            const time = 'any_time'
            const space = this.globalSpace(service)
            const source = getGAEventName(this.landingPageName, 'EXPLORE_WITH_FLOW', service, position)
            return {
                name: 'region-locale-space',
                params: {
                    space,
                    source
                },
                query: {
                    date,
                    time,
                    f_book_type: 'session,hour'
                }
            }
        },
        getPositionFromSpaceType(spaceType) {
            return this.spaceTypeInfo.findIndex(item => item?.space_type === spaceType);
        }
    }
}
</script>

<style scoped>
.explore-list-item-img {
    border-radius: 10px;
    width: 100%;
    filter: brightness(0.9);
    object-fit: cover;
}
.explore-space-icon {
    filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(213deg)
        brightness(500%) contrast(106%);
    height: 26px;
    width: 26px;
}
.explore-list-item-description {
    color: #ffffff;
    position: absolute;
}

@media (min-width: 1200px) {
    .explore-flow-link {
        border-radius: 20px;
        display: flex;
        position: relative;
    }
    .explore-flow-link:hover {
        box-shadow: 17px 20px 40px rgba(0, 0, 0, 0.21);
    }
    .rest-img {
        height: auto;
        width: 100%;
        aspect-ratio: 1;
    }
    .other-space-img {
        width: 100%;
        height: auto;
        aspect-ratio: 3.214/1;
    }
    .explore-list-item-description {
        left: 35px;
    }
}
@media (max-width: 1199px) {
    .explore-flow-column {
        flex-direction: column;
    }
    .margin-bottom-10 {
        margin-bottom: 10px;
    }
    .explore-list-item-img {
        height: 17vw;
        min-height: 90px;
    }
}
@media (max-width: 799px) {
    .explore-list-item-img {
        margin-right: 0;
    }
}
</style>

<template>
    <div id="search-form" @click.stop>
        <div v-if="isLanding" class="mt-4 mb-2 mb-lg-4">
            <div class="d-flex d-lg-block pb-4">
                <ChangeRegionButton class="w-auto" />
            </div>
            <div class="space-type-column">
                <div v-if="isLoading">
                    <b-spinner variant="primary" />
                </div>
                <div v-else class="d-flex flex-wrap mt-2 mb-2">
                    <div
                        v-for="(item, index) in spaceTypeInfo"
                        :key="index"
                        :class="
                            region === 'hk'
                                ? 'justify-content-center'
                                : 'justify-content-start'
                        "
                        class="d-flex justify-content-start my-2 space-type-chip"
                    >
                        <SpaceTypeChipButton
                            :space-type="item.space_type"
                            :space-type-title="item.space_type_string"
                            :is-selected="services === item.space_type"
                            @onButtonClicked="setService(item.space_type)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="input-container search-bar-container d-flex align-items-center justify-content-between px-2 px-lg-0 py-1 py-lg-0"
        >
            <SearchSvg class="d-lg-none mx-1 search-icon" />
            <div class="row m-0" style="width: 99.7%">
                <client-only>
                    <div
                        @click="showSearchModal"
                        class="d-lg-none d-block w-100"
                        style="height: 24px;"
                    >
                        <p
                            v-if="input.key.length > 0"
                            class="mt-2 text-15"
                            style="margin-left: 8px;"
                        >
                            {{ input.key }}
                        </p>
                        <AnimatedText
                            v-else-if="animatedPlaceholders?.length > 0"
                            :input-words="input.key"
                            :is-absolute-position="false"
                            :is-show-immediate-placeholder="
                                isShowImmediatePlaceholder
                            "
                            @updateIndex="updateAnimateIndex"
                            class="text-mid text-16 animated-text-mobile mt-2"
                        />
                        <p
                            v-else
                            class="text-mid text-14 text-797979 animated-text-mobile mt-2"
                        >
                            {{ searchBarPlaceholder }}
                        </p>
                    </div>
                </client-only>
                <div class="d-lg-block d-none col-lg-5 px-0">
                    <LocationSearchPanel
                        ref="autocompleteDesktopRef"
                        :key="updateAutoCompleteKey"
                        :input-words="input.key"
                        :type="'desktop'"
                        :space-type-name="services"
                        :is-show-immediate-placeholder="
                            isShowImmediatePlaceholder
                        "
                        @getCurrentLocation="() => getCurrentLocation(true)"
                        @setInput="setInput"
                        @onLocationSearchEnter="onLocationSearchEnter"
                        @setSuggestionSelected="setSuggestionSelected"
                        class="px-0 pb-2 pb-md-0"
                        style="border-radius: 22px"
                    />
                </div>
                <div
                    class="d-flex justify-content-lg-between w-100 col-10 col-lg-5 px-0 mx-0 my-lg-2"
                >
                    <div
                        :class="
                            services === 'party'
                                ? 'col-11 col-lg-10'
                                : 'col-11 col-lg-12'
                        "
                        style="border-left: 1px solid #d8d8d8;"
                        class="align-items-center d-none d-lg-flex"
                    >
                        <client-only>
                            <b-dropdown
                                :key="isDisablePrice"
                                ref="dateTimeDropdownRef"
                                @show="openCheckInTimeSearch"
                                toggle-class="dropdown-field-btn"
                                class="dropdown-field px-0 w-100 d-none d-lg-flex"
                                variant="transparent"
                                no-caret
                                center
                                no-flip
                            >
                                <template #button-content>
                                    <div
                                        class="d-flex align-items-center justify-content-between date-time-picker-container w-100"
                                    >
                                        <div class="d-flex align-items-center">
                                            <CalendarSvg class="mr-2 dropdown-text field-icon" />
                                            <span
                                                class="text-15 text-797979 dropdown-text"
                                            >
                                                {{ selectedDisplayDate() }}
                                            </span>
                                            <ClockSvg
                                                class="ml-4 mr-2 dropdown-text field-icon"
                                            />
                                            <div style="max-height: 30px;">
                                                <p
                                                    :class="isEmptyPeriodArray ? 'text-danger' : ''"
                                                    class="text-15 text-797979 dropdown-text text-left multi-line-time"
                                                    style="max-width: 200px;"
                                                >
                                                    {{ selectedDisplayTime() }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <b-dropdown-item disabled>
                                    <div
                                        class="d-flex row date-time-picker-dropdown"
                                    >
                                        <div class="col-lg-6 col-12">
                                            <CalendarSearch
                                                :selected-date="selectedDate"
                                                :is-high-light-today="true"
                                                :disable-prices="isDisablePrice"
                                                @setDate="setDate"
                                            />
                                        </div>
                                        <div
                                            class="col-lg-6 col-12 overflow-auto"
                                            style="height: 452px;"
                                        >
                                            <CheckInTimeSearch
                                                ref="checkInTimeRef"
                                                :selected-date="selectedDate"
                                                :selected-service="services"
                                                :key="timeUpdateKey"
                                                @onCheckInTimeClicked="
                                                    setCheckInTime
                                                "
                                                @setCheckInPeriod="setCheckInPeriod"
                                            />
                                        </div>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </client-only>
                    </div>
                    <div
                        :class="isLanding ? 'col-7' : 'col-12'"
                        @click="showSearchModal"
                        class="d-lg-none align-items-center px-2 pb-2 w-100 justify-content-between button-active"
                        style="height: 24px;"
                    >
                        <div
                            class="d-flex align-items-center"
                            style="white-space: nowrap;"
                        >
                            <client-only>
                                <div class="button-active mr-1">
                                    <span
                                        class="text-14 text-797979 dropdown-text"
                                    >
                                        {{
                                            `${selectedDisplayDate('mobile')}, `
                                        }}
                                    </span>
                                </div>
                                <div class="button-active ml-1">
                                    <span
                                        class="text-15 text-797979 dropdown-text"
                                    >
                                        {{ selectedDisplayTime('mobile') }}
                                    </span>
                                </div>
                            </client-only>
                        </div>
                    </div>
                    <client-only>
                        <div
                            v-if="services === 'party'"
                            @click="showSearchModal"
                            class="d-flex justify-content-center d-lg-none col-2"
                            style="border-left: 1px solid #d8d8d8; cursor: pointer; height: 90%"
                        >
                            <OccupantCount :num-ppl="occupant.numPpl" />
                        </div>
                        <b-dropdown
                            ref="paxDropdownRef"
                            v-if="services === 'party'"
                            class="d-none d-lg-flex dropdown-field col-2 px-0"
                            style="border-left: 1px solid #d8d8d8;"
                            variant="transparent"
                            toggle-class="text-decoration-none dropdown-field-btn num-ppl-btn"
                            no-caret
                            right
                        >
                            <template #button-content>
                                <OccupantCount :num-ppl="occupant.numPpl" />
                            </template>
                            <b-dropdown-item
                                disabled
                                class="text-20 text-reg"
                                style="text-align: center;"
                            >
                                <div
                                    v-if="occupant.numPpl > 0"
                                    class="d-flex align-items-center justify-content-between"
                                >
                                    <span
                                        class="text-15 text-s-bold text-3c3c3c ml-3 mr-4"
                                        style="white-space: nowrap;"
                                    >
                                        {{ $t('details.guest') }}
                                    </span>
                                    <div
                                        class="py-3 d-flex align-items-center justify-content-center"
                                    >
                                        <div
                                            class="d-flex justify-content-between align-items-center"
                                        >
                                            <IncrementButton
                                                :is-disabled="
                                                    occupant.numPpl ===
                                                        occupant.minPax
                                                "
                                                @buttonClicked="setNumPpl(-1)"
                                                >-</IncrementButton
                                            >
                                            <div
                                                class="mx-3 text-18 font-weight-bold text-center"
                                                style="width: 40px"
                                            >
                                                {{ occupant.numPpl }}
                                            </div>
                                            <IncrementButton
                                                :is-disabled="
                                                    occupant.numPpl ===
                                                        occupant.maxPax
                                                "
                                                @buttonClicked="setNumPpl(1)"
                                                >+</IncrementButton
                                            >
                                        </div>
                                    </div>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </client-only>
                </div>
                <div class="d-flex align-items-center justify-content-end col-2 search-button">
                    <BookButton
                        :is-loading="isLoading"
                        :book-button-text="$t('search_bar.search_now')"
                        @buttonClicked="onSearchButtonClicked"
                        class="w-auto px-4"
                        style="height: 44px;border-radius: 22px; white-space: nowrap"
                    />
                </div>
                <client-only>
                    <SearchFieldModal
                        v-if="isDateTimeSearchVisible"
                        :is-display-cancel-btn="false"
                        @close="closeDateTimeSearchModal"
                        class="d-block d-lg-none"
                    >
                        <template #searchText>
                            <div
                                class="d-flex align-items-center search-box w-100 px-3"
                            >
                                <span
                                    class="text-ikonoMini-4 mr-3 dropdown-text field-icon"
                                >
                                    
                                </span>
                                <client-only>
                                    <div
                                        class="mr-3 dropdown-text dropdown-title"
                                    >
                                        {{ selectedDateTime }}
                                    </div></client-only
                                >
                            </div>
                        </template>
                        <template #content>
                            <CalendarSearch
                                :selected-date="selectedDate"
                                :disable-prices="isDisablePrice"
                                @setDate="setDate"
                                class="mb-4"
                            />
                            <CheckInTimeSearch
                                :selected-date="selectedDate"
                                :selected-check-in-time-mobile-view="
                                    selectedCheckInTime
                                "
                                :selected-service="services"
                                :key="timeUpdateKey"
                                @onCheckInTimeClicked="setCheckInTime"
                                @setCheckInPeriod="setCheckInPeriod"
                            />
                        </template>
                    </SearchFieldModal>
                    <Search
                        v-if="isShowSearchModal"
                        :num-ppl="occupant.numPpl"
                        :min-pax="occupant.minPax"
                        :max-pax="occupant.maxPax"
                        :selected-date="selectedDate"
                        :input-words="input.key"
                        :input-price-range="input.priceRange"
                        :input-time-frame="input.timeFrame"
                        :input-check-in-time="selectedCheckInTime"
                        :input-star-rating="input.starRating"
                        :input-theme="input.theme"
                        :is-show-theme="true"
                        :is-show-time-frame="true"
                        :selected-space-type="services"
                        :is-show-space-type-list="false"
                        :is-fetching-location="mapLoading"
                        @searchButtonClick="searchButtonClick"
                        @getCurrentLocation="getCurrentLocation"
                        @close="closeSearchModal"
                        @setQuerySourceFromSearch="setSuggestionSelected"
                        type="mobile"
                        class="d-lg-none"
                    />
                </client-only>
            </div>
        </div>
        <SearchPeriodModal
            v-if="isShowPeriodPrompt"
            @continueWithPeriodSet="continueWithPeriodSet"
            @closePeriodModal="isShowPeriodPrompt = false"
        />
        <MagicSearchSection
            v-if="isLanding"
            class="mt-3"
            :services="services"
        />
    </div>
</template>
<script>
import _times from 'lodash/times'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import dateServices from '../../services/dateServices'
import ChangeRegionButton from '@/components/home/ChangeRegionButton'
import SpaceTypeChipButton from '@/components/item/SpaceTypeChipButton'
import CalendarSearch from '@/components/item/CalendarSearch'
import CheckInTimeSearch from '@/components/ui/CheckInTimeSearch'
import IncrementButton from '@/components/item/IncrementButton'
import LocationSearchPanel from '@/components/ui/LocationSearchPanel'
import SearchFieldModal from '@/components/modal/SearchFieldModal'
import CalendarSvg from '@/components/iconSvg/CalendarSvg'
import ClockSvg from '@/components/iconSvg/ClockSvg'
import SearchSvg from '@/components/iconSvg/SearchSvg'
import Search from '@/components/modal/Search'
import AnimatedText from '@/components/ui/AnimatedText'
import OccupantCount from '@/components/ui/OccupantCount'
import MagicSearchSection from '@/components/home/MagicSearchSection'
import SearchPeriodModal from '@/components/modal/SearchPeriodModal'
import {
    QUERY_SOURCE_SUGGESTION,
    QUERY_SOURCE_POPULAR_SEARCH,
    getQuerySourceQueryParam,
    getExactSearchQueryParam,
    TYPE_EXACT_AREA_SEARCH,
    TYPE_EXACT_DISTRICT_SEARCH,
    TYPE_EXACT_META_DISTRICT_SEARCH,
    TYPE_EXACT_SPACE_SEARCH,
    QUERY_SOURCE_EMPTY_SEARCH
} from '@/services/algoliaApi'
import BookButton from '@/components/item/BookButton.vue'
import { DATE_TIME } from '@/lib-flow-main-search/src/constants'
import { isBefore8pm, isEvening } from '@/lib-flow-main-search/src/utils'
import analysis from '@/services/analysis'
import {getGAEventName} from "@/utils/analysis";
Vue.use(VueScrollTo)

export default {
    name: 'SearchingForm',
    components: {
        BookButton,
        SearchFieldModal,
        LocationSearchPanel,
        CalendarSearch,
        CheckInTimeSearch,
        IncrementButton,
        SpaceTypeChipButton,
        ChangeRegionButton,
        CalendarSvg,
        ClockSvg,
        SearchSvg,
        Search,
        AnimatedText,
        OccupantCount,
        MagicSearchSection,
        SearchPeriodModal
    },
    props: {
        mapLoading: {
            type: Boolean,
            default: false
        },
        mapLocation: {
            type: Object,
            default: undefined,
            required: true
        }
    },
    data() {
        return {
            selected: {
                date: dateServices.getToday(),
                displayChooseDate: '',
                isTodayOrTomorrow: true
            },
            input: {
                key: '',
                date: {},
                querySourceType: undefined,
                querySourceValue: undefined,
                suggestCategory: undefined,
                locationHierarchies: undefined,
                suggestionId: undefined,
                priceRange: [],
                starRating: [],
                theme: [],
                timeFrame: []
            },
            isLoadingSpaceTypeInfo: false,
            isDateVisible: false,
            selections: {
                date: {
                    showCalendar: false,
                    disabled: {}
                }
            },
            services: 'rest',
            // Number of people
            occupant: {
                numPpl: 0,
                minPax: 0,
                maxPax: 0
            },
            isSearchPanelExpanded: false,
            selectedDate: '',
            selectedCheckInTime: '',
            isDateTimeSearchVisible: false,
            updateAutoCompleteKey: 0,
            isShowSearchModal: false,
            animIndex: 0,
            isShowImmediatePlaceholder: false,
            isShowPeriodPrompt: false,
            isEmptyPeriodArray: false,
            timeUpdateKey: 0,
            filterObj: {}
        }
    },
    computed: {
        dateList() {
            return this.$store.getters['api/availableDateList']
        },
        spaceTypeInfo() {
            // console.log(this.$store.getters['api/spaceTypeInfo'])
            return this.$store.getters['api/spaceTypeInfo']
        },
        isSpaceTypeLoaded() {
            return this.spaceTypeInfo.length !== 0
        },
        getToday() {
            return dateServices.getToday()
        },
        getTomorrow() {
            return dateServices.getTomorrow()
        },
        region() {
            return this.$route.params.region
        },
        lang() {
            return this.$route.params.locale
        },
        isLoading() {
            return !this.isSpaceTypeLoaded || this.mapLoading
        },
        selectedDateTime() {
            return `${this.selectedDisplayDate(
                'mobile'
            )} ${this.selectedDisplayTime('mobile')}`
        },
        isLanding() {
            return this.$route.name === 'region-locale'
        },
        searchBarPriceRange() {
            return this.$store.getters['api/searchBarPriceRange']
        },
        isDisablePrice() {
            return this.input.key !== '' && this.input.key !== this.regionName()
        },
        animatedPlaceholders() {
            return this.$store.getters['api/animatedPlaceholders']
        },
        searchBarPlaceholder() {
            return (
                this.$store.getters['api/searchBarPlaceholder'] ??
                this.$t('search_bar.current_location')
            )
        },
        periodModalDate() {
            return this.$store.getters['general/periodModalDate']
        },
        checkInPeriodsTranslations() {
            const translations = {}
            this.$store.getters['api/checkInPeriods'].forEach(period => {
                translations[period.value] = period.text
            })
            return translations
        }
    },
    watch: {
        services(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.applyResources(newVal)
            }
        },
        mapLocation(newVal, oldVal) {
            if (newVal?.latitude && newVal?.longitude && newVal !== oldVal) {
                this.input.key = this.$t('search_bar.current_location')
                this.$refs.autocompleteDesktopRef.refreshAutoComplete(
                    this.services,
                    this.$t('search_bar.current_location')
                )
            }
        },
        searchBarPriceRange(newVal) {
            if (newVal.min !== undefined && newVal.max !== undefined) {
                this.input.priceRange = [newVal.min, newVal.max]
            }
        },
        isLoading(newVal) {
            if (!newVal) this.expandDropdownForAirport()
        }
    },
    created() {
        if (
            this.searchBarPriceRange.min !== undefined &&
            this.searchBarPriceRange.max !== undefined
        ) {
            this.input.priceRange = [
                this.searchBarPriceRange.min,
                this.searchBarPriceRange.max
            ]
        }
    },
    async mounted() {
        await this.$store.dispatch('general/UPDATE_PERIOD_MODAL_DATE', {
            date: dateServices.getToday()
        })
        await this.$store.dispatch('general/UPDATE_PERIOD_MODAL_SERVICE_TYPE', {
            type: 'rest'
        })
        this.applyResources(this.services)
        this.setDefaultInputKey()
        this.setDefaultPax()
        if (!this.isBlockGooglebotFromButton) {
            await this.getSearchBarSetting()
        }
        this.expandDropdownForAirport()
    },
    methods: {
        setDefaultPax() {
            this.spaceTypeInfo.map((info) => {
                if (info.space_type === 'party') {
                    this.occupant.minPax = info.min_pax
                    this.occupant.maxPax = info.max_pax
                    this.occupant.numPpl = info.default_pax
                }
            })
        },
        async getSearchBarSetting() {
            try {
                await this.$store.dispatch('api/GET_SEARCH_BAR_SETTING', {
                    lang: this.lang,
                    region: this.region,
                    group: this.paramsGroup,
                    space_type: this.services
                })
            } catch (err) {
                this.$nuxt.error(err)
                this.$sentry.captureException(err)
            }
        },
        setDate(date) {
            this.selectedDate = date
            this.selectedCheckInTime = ''
            this.timeUpdateKey += 1
        },
        // onTextInputClicked(event) {
        //     this.isDistrictVisible = true
        //     this.selections.keys.showPopup = true
        // },
        // onSearchKeywordFieldUpdated: _debounce(function() {
        //     this.startSearching()
        // }, 600),
        // onTextInputFinish(event) {
        //     setTimeout(() => {
        //         this.selections.keys.showPopup = false
        //     }, 200)
        // },
        applyResources(services) {
            switch (services) {
                case 'rest':
                    this.availableDate(this.dateList.hotel)
                    break
                case 'work':
                    this.availableDate(this.dateList.desk)
                    break
                case 'meet':
                    this.availableDate(this.dateList.meeting)
                    break
                case 'staycation':
                    this.availableDate(this.dateList.hotel)
                    break
                case 'party':
                    this.availableDate(this.dateList.party)
                    break
            }
        },
        availableDate(list) {
            if (list) {
                const disable = []
                _times(list.disable.length, (i) => {
                    disable.push(new Date(list.disable[i]))
                })
                this.selections.date.disabled = {
                    to: new Date(list.to),
                    from: new Date(list.from),
                    dates: disable
                }
            }
        },
        onSearchButtonClicked() {
            if (
                this.services === 'rest' &&
                this.selectedCheckInTime?.[0] === DATE_TIME.ANY_CHECK_IN_TIME
            ) {
                this.isEmptyPeriodArray = false
            } else if (
                this.services === 'rest' &&
                (
                    this.selectedCheckInTime === DATE_TIME.ANY_CHECK_IN_TIME ||
                    this.selectedCheckInTime?.length <= 0
                )
            ) {
                if (this.deviceType() === 'mobile') {
                    this.isShowPeriodPrompt = true
                } else {
                    this.$refs?.dateTimeDropdownRef?.show()
                }
                this.isEmptyPeriodArray = true
                return
            }
            if (this.isAirportHotelsPage && this.isMobileScreenSizeGlobal()) {
                this.showSearchModal()
            } else {
                if (!this.isBlockGooglebotFromButton){
                    this.navigate()
                }
            }
        },
        navigate() {
            const source = getGAEventName(this.landingPageName,'SEARCH_RESULT_BAR')
            const space = this.globalSpace(this.services)
            const params = {
                space,
                source,
                filter: this.filterObj
            }
            const query = {}
            /** SearchingForm, navigate, params,
            console.log('SearchingForm, navigate, params, ', params)
             */
            if (this.selectedDate !== '') {
                query.date = this.selectedDate
            }
            if (Array.isArray(this.selectedCheckInTime)) {
                query.time = this.selectedCheckInTime.join(',')
            } else if (this.selectedCheckInTime !== '') {
                query.time = this.selectedCheckInTime
            }
            const today = dateServices.getToday()
            const now = new Date().valueOf()
            if (
                this.selectedDate === today &&
                isEvening(now, today, this.paramsRegion) &&
                !isBefore8pm(now, today, this.paramsRegion) &&
                (
                    this.selectedCheckInTime.includes(DATE_TIME.MIDNIGHT_TIME) ||
                    this.selectedCheckInTime.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
                )
            ) {
                query.date = dateServices.getTomorrow()
            } else if (
                dateServices.isDayEnded(dateServices.getToday(), this.region)
            ) {
                query.date = dateServices.getTomorrow()
            }
            if (this.mapLocation?.latitude && this.mapLocation?.longitude) {
                query.user_lat_long = `${this.mapLocation.latitude},${this.mapLocation.longitude}`
            } else if (this.input.key !== '') {
                query.q = this.input.key
            }
            if (this.services === 'party') {
                query.numPpl = this.occupant.numPpl
            }
            if (this.input.priceRange[0] > this.searchBarPriceRange.min) {
                query.f_priceRange_min = this.input.priceRange[0]
            }

            if (this.input.priceRange[1] < this.searchBarPriceRange.max) {
                query.f_priceRange_max = this.input.priceRange[1]
            }
            if (this.input.timeFrame.length > 0) {
                query.f_book_type = this.input.timeFrame.join(',')
            }
            if (this.input.starRating.length > 0) {
                query.f_star_rating = this.input.starRating.join(',')
            }
            if (this.input.theme.length > 0) {
                query.f_theme = this.input.theme.join(',')
            }
            const querySource = getQuerySourceQueryParam(
                this.$t('search_bar.current_location'),
                this.input.key,
                this.input.querySourceValue,
                this.input.querySourceType
            )
            query.qs = querySource
            const exactSearchType = getExactSearchQueryParam(
                this.input.key,
                this.input.querySourceValue,
                this.input.querySourceType,
                this.input.suggestCategory
            )
            if (exactSearchType) {
                query.es_type = exactSearchType
            }
            if (
                exactSearchType === TYPE_EXACT_AREA_SEARCH ||
                exactSearchType === TYPE_EXACT_DISTRICT_SEARCH ||
                exactSearchType === TYPE_EXACT_META_DISTRICT_SEARCH
            ) {
                if (this.input.locationHierarchies?.[0]) {
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    query[
                        this.input.locationHierarchies[0].field
                    ] = this.input.locationHierarchies[0].value
                }
                if (
                    exactSearchType === TYPE_EXACT_AREA_SEARCH &&
                    this.input.locationHierarchies?.[1]
                ) {
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    query[
                        this.input.locationHierarchies[1].field
                    ] = this.input.locationHierarchies[1].value
                }
            } else if (exactSearchType === TYPE_EXACT_SPACE_SEARCH) {
                query.sId = this.input.suggestionId
            }
            /* Disabled book type filter
            if (
                querySource === QUERY_SOURCE_EMPTY_SEARCH &&
                this.input.timeFrame.length <= 0 &&
                space === 'hotel'
            ) {
                query.f_book_type = 'hour,session'
            }
            */
            if (querySource === 'es') {
                this.isShowImmediatePlaceholder = true
            }
            this.$router.push({
                name: 'region-locale-space',
                params,
                query
            })
        },
        async setService(service) {
            await this.$store.dispatch('general/UPDATE_PERIOD_MODAL_SERVICE_TYPE', {type: service})
            this.services = service
            this.selectedCheckInTime = DATE_TIME.ANY_CHECK_IN_TIME
            this.setDefaultPax(service)
            await this.getSearchBarSetting()
            this.$emit('setService', service)
            this.updateAutoCompleteKey += 1
        },
        setInput(input, forceResetLocation = false) {
            this.input.key = input
            if (forceResetLocation) {
                this.mapLocation = {}
            }
        },
        setSuggestionSelected(
            query,
            category,
            locationHierarchies,
            suggestionId,
            isQuerySuggestion,
            isPreselect = false
        ) {
            this.input.key = query
            this.input.querySourceValue = query
            this.input.querySourceType = isQuerySuggestion
                ? QUERY_SOURCE_SUGGESTION
                : QUERY_SOURCE_POPULAR_SEARCH
            this.input.suggestCategory = category
            this.input.locationHierarchies = locationHierarchies
            this.input.suggestionId = suggestionId
            if (this.isMobileScreenSizeGlobal() && !isPreselect) {
                this.openDateTimeSearchModal()
            } else {
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.dateTimeDropdownRef?.show()
            }
        },
        setNumPpl(numPpl) {
            this.occupant.numPpl = this.occupant.numPpl + numPpl
        },
        getCurrentLocation(promptDateTimePicker = false) {
            if (this.isSpaceTypeLoaded) {
                this.$emit('getCurrentLocation')
            }
            if (promptDateTimePicker) {
                if (this.isMobileScreenSizeGlobal()) {
                    this.openDateTimeSearchModal()
                } else {
                    // eslint-disable-next-line no-unused-expressions
                    this.$refs?.dateTimeDropdownRef?.show()
                }
            }
        },
        setCheckInTime(checkInTime, autoClose = true, promptPaxPicker = true) {
            /* Trigger when init */
            this.selectedCheckInTime = checkInTime
            if (this.selectedDate.length <= 0) {
                if (this.isAirportHotelsPage) {
                    this.setDate(this.getTomorrow)
                } else {
                    this.setDate(dateServices.getDateByFormat(new Date()))
                }
            }
            if (autoClose) {
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.dateTimeDropdownRef?.hide()
            }
            if (promptPaxPicker && this.services === 'party') {
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.paxDropdownRef?.show()
            }
        },
        openDateTimeSearchModal() {
            this.isDateTimeSearchVisible = true
            this.hiddenScrollBar()
        },
        closeDateTimeSearchModal() {
            this.isDateTimeSearchVisible = false
            this.addScrollBar()
        },
        setItemSelect(item) {
            this.input.key = item
        },
        openCheckInTimeSearch() {
            this.$refs.checkInTimeRef.calculateOtherCheckInTime()
        },
        onLocationSearchEnter() {
            this.$refs.dateTimeDropdownRef.show()
        },
        updateAnimateIndex(index) {
            this.animIndex = index
        },
        showSearchModal() {
            this.isShowSearchModal = true
        },
        closeSearchModal() {
            this.isShowSearchModal = false
        },
        setQuery(query, isGetCurrentLocation) {
            this.input.key = query
            if (isGetCurrentLocation) {
                this.getCurrentLocation()
            } else {
                this.mapLocation = {}
            }
        },
        searchButtonClick(selected) {
            if (selected?.date) {
                this.setDate(selected.date)
            }
            if (selected?.checkInTime) {
                this.setCheckInTime(selected.checkInTime, false, false)
            }
            if (selected?.spaceType) {
                this.setService(selected.spaceType)
            }
            if (selected?.priceRange) {
                this.input.priceRange = selected.priceRange
                this.filterObj.priceRange = selected.priceRange
            }
            if (selected?.timeFrame) {
                this.input.timeFrame = selected.timeFrame
            }
            if (selected?.theme) {
                this.input.theme = selected.theme
                this.filterObj.theme = selected.theme
            }
            if (selected?.starRating) {
                this.input.starRating = selected.starRating
                this.filterObj.starRating = selected.starRating
            }
            if (selected?.numPpl) {
                this.occupant.numPpl = selected.numPpl
            }
            this.setQuery(selected.words, selected.getCurrentLocation)
            this.navigate()
        },
        setDefaultInputKey() {
            if (this.isAirportHotelsPage) {
                let query = ''
                let locationHierarchies = []
                if (this.region === 'sg') {
                    query = this.$t(`sub_landing.${this.region}_airport`)
                    locationHierarchies = [
                        { field: 'area', value: 103 },
                        { field: 'district', value: 52 }
                    ]
                } else if (this.region === 'hk') {
                    query = this.$t(
                        `sub_landing.${this.region}_airport_input_key`
                    )
                    locationHierarchies = [
                        { field: 'area', value: 44 },
                        { field: 'district', value: 10 }
                    ]
                }
                this.setSuggestionSelected(
                    query,
                    'LOCATION-AREA',
                    locationHierarchies,
                    '',
                    true,
                    true
                )
            }
        },
        selectedDisplayDate(device) {
            if (this.isAirportHotelsPage && device === 'mobile') {
                return this.$t('retrieve_booking.check_in_date')
            } else if (this.selectedDate) {
                return dateServices.formatSearchBarDisplayDate(
                    this.selectedDate,
                    this.lang
                )
            } else {
                return ''
            }
        },
        selectedDisplayTime(device) {
            if (this.isAirportHotelsPage && device === 'mobile') {
                return this.$t('details.check_in_title')
            } else if (this.selectedCheckInTime?.[0] === DATE_TIME.ANY_CHECK_IN_TIME) {
                return this.services === 'rest'
                    ? this.$t('search_bar.any_time')
                    : this.$t('search_bar.any_start_time')
            } else if (this.selectedCheckInTime === DATE_TIME.ANY_CHECK_IN_TIME) {
                return this.services === 'rest'
                    ? this.isEmptyPeriodArray
                        ? this.$t('search_bar.select_one_period')
                        : this.$t('search_bar.select_time_or_period')
                    : this.$t('search_bar.any_start_time')
            } else if (
                this.selectedCheckInTime === DATE_TIME.ASAP_CHECK_IN_TIME
            ) {
                return this.services === 'rest'
                    ? this.$t('search_bar.asap_time')
                    : this.$t('search_bar.asap_time_non_hotel')
            } else if (Array.isArray(this.selectedCheckInTime) && this.selectedCheckInTime.length > 0) {
                return this.selectedCheckInTime.map(
                    time => this.mapToTimePeriodTranslations(time, this.checkInPeriodsTranslations)
                )
                    .join(', ')
            } else if (Array.isArray(this.selectedCheckInTime) && this.selectedCheckInTime.length <= 0) {
                return this.$t('search_bar.select_multiple_periods')
            } else {
                return this.selectedCheckInTime
            }
        },
        expandDropdownForAirport() {
            if (this.isAirportHotelsPage)
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.dateTimeDropdownRef?.show()
        },
        setCheckInPeriod(selectedPeriods, autoClose = false, promptPaxPicker = true) {
            if (selectedPeriods?.length <= 0) {
                // hacky fix for deselect all period buttons, the Search related components should be revamped
                // with reduced components nesting
                this.isEmptyPeriodArray = true
                this.timeUpdateKey += 1
            } else {
                this.isEmptyPeriodArray = false
                this.selectedCheckInTime = selectedPeriods
            }
            if (autoClose) {
                this.$refs?.dateTimeDropdownRef?.hide()
            }
            if (promptPaxPicker && this.services === 'party') {
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.paxDropdownRef?.show()
            }
        },
        // mapTimePeriodToTrackingValue(period) {
        //     if (period === DATE_TIME.MIDNIGHT_TIME) {
        //         return 'Midnight time'
        //     } else if (period === DATE_TIME.MORNING_TIME) {
        //         return 'Morning time'
        //     } else if (period === DATE_TIME.AFTERNOON_TIME) {
        //         return 'Afternoon time'
        //     } else if (period === DATE_TIME.EVENING_TIME) {
        //         return 'Evening time'
        //     } else if (period === DATE_TIME.AFTER_X_TIME) {
        //         return 'After X pm'
        //     } else if (period === DATE_TIME.ASAP_CHECK_IN_TIME_N) {
        //         return 'As soon as possible'
        //     } else if (period === DATE_TIME.AFTER_MIDNIGHT_TIME) {
        //         return 'After midnight'
        //     }
        //     return ''
        // },
        continueWithPeriodSet(periods) {
            this.isShowPeriodPrompt = false
            this.setDate(this.periodModalDate)
            this.setCheckInTime(periods, false, false)
            this.setService('rest')
            this.navigate()
        }
    }
}
</script>
<style scoped>
/*.input-container {*/
/*    !*https://getcssscan.com/css-box-shadow-examples #28*!*/
/*    !* DO NOT remove next line, for input container debug *!*/
/*    !*box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;*!*/
/*    width: 50vw;*/
/*}*/
.search-bar-container {
    background-color: white;
    height: 52px;
    border-radius: 24px;
    padding-right: 2px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}
.num-ppl-field >>> .dropdown-item,
.dropdown-field >>> .dropdown-item {
    white-space: normal !important;
    pointer-events: auto !important;
    color: black;
    cursor: auto !important;
    padding: 0.25rem 1.1rem;
}
.date-time-picker-dropdown {
    width: 715px;
}
@media (max-width: 768px) {
    .date-time-picker-dropdown {
        width: var(--vw);
    }
}
.search-button {
    position: static;
    padding-left: 0;
    padding-right: 0;
}
@media (min-width: 992px) {
    .space-type-column {
        height: 66px;
        display: flex;
        align-items: center;
    }
}
@media (max-width: 991px) {
    .search-bar-container {
        height: 100%;
        border-radius: 25px;
    }
    .date-time-picker-container {
        padding: 0;
    }
    .search-button {
        position: absolute;
        right: 0;
        top: 2px;
        padding-left: 0;
        padding-right: 2px;
    }
    .search-bar-container {
        height: 48px;
    }
    .space-type-chip {
        width: 50%;
    }
}
.dropdown-field >>> .dropdown-menu {
    margin-top: 17px;
    border-radius: 12px;
    padding-top: 14px;
    padding-bottom: 14px;
    border: none;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%) !important;
}
.field-icon {
    color: #b5b3ab;
    fill: #b5b3ab;
}
.dropdown-field >>> .dropdown-field-btn {
    padding: 0 0 0 7px;
}
.dropdown-field >>> .dropdown-field-btn:active {
    filter: opacity(0.6);
}
.dropdown-field >>> .num-ppl-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-box {
    border-radius: 8px;
    border-style: solid;
    border-width: thin;
    border-color: black;
    color: black !important;
    height: 45px;
}
.animated-text-mobile {
    margin-left: 8px;
}
.input-container {
    position: relative;
}
@media (min-width: 992px) {
    .input-container {
        width: 900px;
    }
}
.multi-line-time {
    white-space: initial;
    -webkit-line-clamp: 2;
    line-height: 15px;
    max-height: 30px;
    margin: 0;
    display: table-cell;
    vertical-align: middle;
}
</style>

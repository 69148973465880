<template>
    <div class="text-center d-flex justify-content-around">
        <div v-for="(item, index) in navBtn" :key="index">
            <SpaceTypeChipButton
                :space-type="item.value"
                :space-type-title="item.text"
                :is-selected="item.value === itemSelected"
                :selected-color="'text-ffc000'"
                :text-color="'text-a9a9a9'"
                :is-chip-btn="false"
                :is-hide-mobile-icon="true"
                @onButtonClicked="navBtnClicked(item)"
                class="px-2"
            />
        </div>
    </div>
</template>
<script>
import SpaceTypeChipButton from '@/components/item/SpaceTypeChipButton.vue'

export default {
    name: 'NavigationBar',
    components: { SpaceTypeChipButton },
    props: {
        navBtn: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        itemSelected: {
            type: String,
            default: 'rest'
        },
        deal: {
            type: Object,
            default: undefined
        },
        date: {
            type: String,
            default: ''
        }
    },
    methods: {
        navBtnClicked(item) {
            this.$emit('handleBtnClick', item, this.deal, this.date)
        }
    }
}
</script>

<template>
    <SwiperContainer
        v-if="bookAgain.length > 0"
        :option="swiperOption"
        :name="'book-again'"
        :is-free-mode="true"
    >
        <div
            v-for="(item, index) in bookAgain"
            :key="index"
            class="swiper-slide card-item mt-2"
            style="cursor: pointer;"
        >
            <div class="d-flex w-100">
                <NuxtLink
                    :to="{
                        path: computeLinkToDetails({
                            btn: 'Landing Book again',
                            services: item.space.space_type,
                            slug: item.space.slug,
                            countryCode: item.location.region,
                            spaceName: item.space.space_name
                        })
                    }"
                    class="p-0 m-0 list-item w-100"
                    style="text-decoration: none;"
                >
                    <div
                        @click.prevent="
                            onLinkClicked({
                                btn: 'Landing Book again',
                                services: item.space.space_type,
                                slug: item.space.slug,
                                countryCode: item.location.region,
                                spaceName: item.space.space_name,
                                position: index
                            })
                        "
                    >
                        <div class="w-100 position-relative">
                            <ImageContainer
                                v-if="item.space"
                                :src="item.space.thumbnail"
                                :alt="item.space.space_name"
                                :loading="
                                    index !== 0 && index !== 1 ? 'lazy' : ''
                                "
                                class="w-100 list-item-img"
                            />
                            <div
                                v-if="item.par_rate"
                                class="text-16 text-mid border-1 px-4 py-2 button-box-shadow position-absolute bg-primary"
                                style="color: white; margin-right: 5px; border-radius: 20%/50%; top:3%; left: 3%; font-weight: 600"
                            >
                                {{ item.par_rate }}
                            </div>
                            <HeartButton
                                :slug="item.space.slug"
                                :space-name="item.space.space_name"
                                :space="globalSpace(item.space.space_type)"
                                class="position-absolute"
                                style="right: 0; top: 3%;"
                            />
                        </div>
                        <div class="mt-2">
                            <div
                                class="d-flex justify-content-between align-items-center flex-wrap mb-1"
                            >
                                <div v-if="item.booking" class="d-flex">
                                    <p
                                        class="text-17 text-404040"
                                        style="font-weight: bolder;"
                                    >
                                        {{
                                            getDisplayPrice(
                                                item.booking.charge_price,
                                                item.location.region
                                            )
                                        }}
                                    </p>
                                </div>
                                <div class="d-flex">
                                    <div
                                        class="text-14 text-primary"
                                        style="font-weight: 600; white-space: nowrap;"
                                    >
                                        {{ item.space.space_type_string }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3
                                    class="text-14 text-9d9d9d align-self-center align-items-baseline mb-0 font-weight-normal"
                                >
                                    {{ item.space.space_name }}
                                </h3>
                            </div>
                            <div
                                class="text-13"
                                style="color: rgb(199 199 199)"
                            >
                                {{ item.booking.from_now }}
                            </div>
                        </div>
                    </div>
                </NuxtLink>
            </div>
        </div>
    </SwiperContainer>
</template>

<script>
import HeartButton from '@/components/item/HeartButton'
import SwiperContainer from '@/components/ui/SwiperContainer'
import dateServices from '@/services/dateServices'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'BookAgain',
    components: {ImageContainer, SwiperContainer, HeartButton },
    props: {
        bookAgain: {
            type: Array,
            require: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 1.6,
                spaceBetween: 10,
                breakpoints: {
                    1150: {
                        slidesPerView: 4.1,
                        spaceBetween: 10
                    },
                    1000: {
                        slidesPerView: 3.3,
                        spaceBetween: 10
                    },
                    800: {
                        slidesPerView: 2.9,
                        spaceBetween: 10
                    },
                    680: {
                        slidesPerView: 2.3,
                        spaceBetween: 10
                    },
                    579.98: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    methods: {
        computeLinkToDetails(params) {
            const region = params.countryCode.toLowerCase()
            let lang = this.lang
            lang = this.switchDefaultLang(lang, region)
            const space = this.globalSpace(params.services)
            return `/${region}/${lang}/${
                params.slug
            }/${space}`
        },
        onLinkClicked(params) {
            this.$emit('navigateToDetails', params)
        }
    }
}
</script>

<style scoped>
.list-item-img {
    border-radius: 10px;
    object-fit: cover;
    /*aspect-ratio: 1280/800;*/
    /*width: auto !important;*/
}
.swiper-slide {
    /*width: auto !important;*/
    margin-right: 10px;
    /*aspect-ratio: 1280/800;*/
}
@media (max-width: 414px) {
    .list-item-img {
        height: 140px;
    }
}
@media (min-width: 415px) {
    .list-item-img {
        height: 180px;
    }
}
</style>

<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" style="height: var(--vh);">
                <div class="modal-container">
                    <div
                        class="d-flex justify-content-center"
                        style="max-width: 500px; margin: auto"
                    >
                        <div
                            class="bg-light cal-container px-4 pt-5 pb-4"
                        >
                            <b-button
                                @click="$emit('closePeriodModal')"
                                class="close-btn bg-transparent border-0 text-292929 text-ikonoMini-4"
                            >
                                
                            </b-button>
                            <b-button
                                v-for="(item, index) in anyPeriods"
                                :key="`any_${index}`"
                                :class="selectedPeriods.includes(item.value)
                                    ? 'time-btn-selected'
                                    : 'any-time-btn'
                                "
                                class="mt-2 mx-2 px-3 time-btn"
                                variant="transparent"
                                @click="setAnyTimePeriod(item.value)"
                            >
                                <p class="text-13 text-mid">
                                    {{ item.text }}
                                </p>
                            </b-button>
                            <div class="d-flex text-14 text-s-bold mx-2 my-2">
                                <span>{{ $t('search_bar.daycation') }}</span>
<!--                                Disable daycation tooltip, as modal has enough space for showng text-->
<!--                                <InfoSvg-->
<!--                                    id="tooltip-target"-->
<!--                                    style="fill: #b5b3ab;"-->
<!--                                    class="d-none d-lg-block ml-2"-->
<!--                                />-->
<!--                                <b-tooltip target="tooltip-target" triggers="hover">-->
<!--                                    {{ $t('search_bar.daycation_desc') }}-->
<!--                                </b-tooltip>-->
                                <span class="text-primary ml-2">
                                    {{ $t('search_bar.select_multiple_periods') }}
                                </span>
                            </div>
                            <p class="text-12 text-reg mx-2">
                                {{ $t('search_bar.daycation_desc') }}
                            </p>
                            <b-button
                                v-for="(item, index) in daycationPeriods"
                                v-if="!checkPeriodButtonDisabled(item.value)"
                                :key="`daycation_${index}`"
                                :class="computeSelectStatusStyles(item)"
                                class="mt-2 mx-2 px-3 period-btn"
                                variant="transparent"
                                @click="setPeriod(item)"
                            >
                                <p class="text-13 text-mid">
                                    {{
                                        insertTmrDateIfNeeded(item.value) +
                                            item.text +
                                            `(${item.start_time}-${item.end_time})`
                                    }}
                                </p>
                            </b-button>
                            <p class="text-14 text-s-bold my-2 mx-2">{{ $t('search_bar.overnight') }}</p>
                            <b-button
                                v-for="(item, index) in overnightPeriods"
                                v-if="!checkPeriodButtonDisabled(item.value)"
                                :key="`overnight_${index}`"
                                :class="computeSelectStatusStyles(item)"
                                class="text-13 text-mid mx-2 px-3 period-btn"
                                variant="transparent"
                                @click="setPeriod(item)"
                            >
                                {{ insertTmrDateIfNeeded(item.value) + item.text }}
                            </b-button>
                            <b-button
                                class="text-13 text-mid text-white mx-2 px-3 mt-2 confirm-button"
                                @click="onConfirmClicked"
                                :disabled="selectedPeriods.length <= 0"
                            >
                                {{ $t('search_bar.search_now') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { DATE_TIME } from '@/lib-flow-main-search/src/constants'
import {
    computeConflictPeriodOptions,
    isPeriodButtonDisabled,
    insertTmrDateIfAfter8,
    sortedDaycationPeriods, mapTimePeriodToTrackingValue
} from '@/lib-flow-main-search/src/utils'
import dateServices from '@/services/dateServices'
import {getGAEventName} from "@/utils/analysis";
import analysis from "@/services/analysis";

export default {
    name: 'SearchPeriodModal',
    props: {
        clickFrom: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            DATE_TIME: DATE_TIME,
            selectedPeriods: [],
            disallowedPeriods: []
        }
    },
    computed: {
        periodModalDate() {
            return this.$store.getters['general/periodModalDate']
        },
        daycationPeriods() {
            return sortedDaycationPeriods(
                this.periodModalDate,
                this.paramsRegion,
                this.$store.getters['api/checkInPeriods']
            )
        },
        overnightPeriods() {
            return this.$store.getters['api/checkInPeriods'].filter(_ => _.type === 'overnight')
        },
        anyPeriods() {
            return this.$store.getters['api/checkInPeriods'].filter(_ => _.type === 'any')
        }
    },
    methods: {
        computeSelectStatusStyles(item) {
            return this.isPeriodDisallowed(item)
                ? 'period-btn-disallowed'
                : this.isPeriodSelected(item)
                    ? 'period-btn-selected'
                    : 'period-btn'
        },
        isPeriodSelected(item) {
            return this.selectedPeriods.includes(item.value)
        },
        isPeriodDisallowed(item) {
            if (this.disallowedPeriods.length > 0) {
                return this.disallowedPeriods.includes(item.value)
            }
            return false
        },
        setPeriod(item) {
            if (this.selectedPeriods.includes(DATE_TIME.ANY_CHECK_IN_TIME)) {
                this.selectedPeriods = []
            }
            const now = new Date().valueOf()
            const today = dateServices.getToday()
            if (this.isPeriodDisallowed(item)) {
                this.selectedPeriods = [item.value]
            } else if (this.isPeriodSelected(item)) {
                const removeIndex = this.selectedPeriods.indexOf(item.value)
                this.selectedPeriods = this.selectedPeriods.filter((_, i) => i !== removeIndex)
            } else {
                this.selectedPeriods = [
                    ...this.selectedPeriods,
                    item.value
                ]
                this.sendLogEventsItem('default', mapTimePeriodToTrackingValue(item.value))
            }
            this.disallowedPeriods = computeConflictPeriodOptions(
                this.selectedPeriods,
                now,
                today,
                this.periodModalDate,
                this.paramsRegion
            )
            this.$emit('setCheckInPeriod', this.selectedPeriods)
        },
        checkPeriodButtonDisabled(type) {
            return isPeriodButtonDisabled(type, this.paramsRegion, this.periodModalDate)
        },
        onConfirmClicked() {
            this.$emit('continueWithPeriodSet', this.selectedPeriods)
        },
        setAnyTimePeriod(value) {
            this.sendLogEventsItem('default', mapTimePeriodToTrackingValue(value))
            this.selectedPeriods = [value]
            this.disallowedPeriods = []
            this.$emit('setCheckInPeriod', [value])
        },
        insertTmrDateIfNeeded(period) {
            return insertTmrDateIfAfter8(period, this.periodModalDate, this.paramsRegion)
        },
        sendLogEventsItem(type, value) {
            const source = getGAEventName(this.landingPageName, this.clickFrom, value)
            analysis.sendPopularCheckInTimeButtonClicked(source, type, value, dateServices.getDateByFormat(new Date()))
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    transition: all 0.3s ease;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}

.cal-container {
    position: relative;
    border-radius: 10px;
}

.period-btn-disallowed {
    text-decoration: line-through;
}

.period-btn {
    background: white;
    border-radius: 18px;
    border: 1px solid #ffc000;
    color: #ffc000;
}

.period-btn-selected {
    background: #ffc000;
    border-radius: 18px;
    color: white;
}

.time-btn {
    border: 0;
    border-radius: 6px;
    height: 32px;
    margin-bottom: 8px;
}

.time-btn-selected {
    background: var(--primary);
}

.any-time-btn {
    background: #f3f2f0;
}

.close-btn {
    font-size: 18px;
    position: absolute;
    left: 9px;
    top: 13px;
    color: #505050;
    transform: rotate(45deg);
}

.confirm-button {
    border: 0;
    border-radius: 6px;
    height: 32px;
    margin-bottom: 8px;
}

@media (max-width: 639.98px) {
    .cal-container {
        position: relative;
        border-radius: 10px;
        width: 90vw;
    }

    .modal-wrapper {
        vertical-align: middle;
    }
}

@media (min-width: 640px) and (max-width: 991px) {
    .cal-container {
        width: 350px;
    }
}
</style>

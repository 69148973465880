<template>
    <ImageContainer
        :alt="'download-app-img-' + region"
        :src="downloadAppImg[`${region}-${lang}`]"
        width="349"
        height="700"
        class="download-app-img"
    />
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'DownloadAppImage',
    components: {ImageContainer},
    data() {
        return {
            link: '',
            downloadAppImg: {
                'my-ms':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5099ffc5-06d9-48b6-79dd-70fab992fc00/large',
                'my-zh':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/61b6eb24-bff8-4ec5-7af0-8c276bda8f00/large',
                'my-en':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/ddcd2fd8-23dc-4fa7-68a0-22dfebefbc00/large',
                // 'hk-en':
                //     'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/699139e1-8285-4ccd-de13-2cea6eb7da00/large',
                // 'hk-zh':
                //     'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b1b5710f-d405-42f0-0eec-253991af4200/large',
                'hk-en':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/28be0c0e-6010-48c1-4948-b3fa47dc9f00/large',
                'hk-zh':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/75f3bd6f-b48f-4000-1a96-993257d64e00/large',
                'mo-en':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f9dbe189-0ff9-4605-b052-af01a49e9000/large',
                'mo-zh':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/9ddc1c6b-bfde-4e64-4f3c-5322d7103400/large',
                'sg-en':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/2eda37db-74d7-4082-95cf-3f8946b80700/large',
                'sg-zh':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/86f5f863-8f22-4ee0-1f3c-2c851b646300/large',
                'vn-vi':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3354fa1f-9c73-4d0b-15fe-00f045301b00/large',
                'vn-en':
                    'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f79732df-8f02-49bb-8b55-c7a733455a00/large'
            }
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        }
    }
}
</script>
<style scoped>
.download-app-img {
    width: auto;
    aspect-ratio: 349/700;
}
@media (min-width: 992px) {
    .download-app-img {
        height: 600px;
    }
}
@media (max-width: 991px) {
    .download-app-img {
        height: 550px;
    }
}
</style>

<template>
    <div style="min-height: 33px;">
        <transition name="fade">
            <SwiperContainer
                v-if="magicButtons?.length > 0"
                :option="swiperOption"
                is-slides-offset
                :name="`magic-search-buttons-${services}`"
                :is-allow-touch-move="deviceType() === 'mobile'"
                class="button-container"
            >
                <div
                    v-for="(item, index) in magicButtons"
                    :key="`magic-search-button-${index}`"
                    class="magic-button-container swiper-slide pl-2 pr-3 py-1 button-active-filter"
                    :style="getGradColour(item)"
                >
                    <NuxtLink
                        :to="computeMagicButtonLink(item)"
                        style="text-decoration: none;"
                    >
                        <div
                            class="d-flex align-items-center"
                            @click="onButtonClicked(item, $event, index)"
                        >
                            <div class="space-type-icon-bg mr-2">
                                <SpaceTypeRestSvg
                                    v-if="item.space_type === 1"
                                    is-selected
                                    :width="'18'"
                                    :height="'18'"
                                />
                                <SpaceTypeWorkSvg
                                    v-else-if="item.space_type === 2"
                                    is-selected
                                    :width="'18'"
                                    :height="'18'"
                                />
                                <SpaceTypeMeetSvg
                                    v-else-if="item.space_type === 3"
                                    is-selected
                                    :width="'18'"
                                    :height="'18'"
                                />
                                <SpaceTypePartySvg
                                    v-else-if="item.space_type === 5"
                                    is-selected
                                    :width="'18'"
                                    :height="'18'"
                                />
                            </div>
                            <pre
                                class="text-13 text-mid remove-space"
                                :style="getFontColour(item)"
                            >
                                <span>{{ item.text }}</span>
                            </pre>
                        </div>
                    </NuxtLink>
                </div>
            </SwiperContainer>
        </transition>
        <SearchPeriodModal
            v-if="isShowPeriodPrompt"
            clickFrom="Magic button pop up"
            @continueWithPeriodSet="continueWithPeriodSet"
            @closePeriodModal="isShowPeriodPrompt = false"
        />
    </div>
</template>

<script>
import analysis from '@/services/analysis'
import SwiperContainer from '@/components/ui/SwiperContainer'
import { DATE_TIME, QUERY_SOURCE } from '@/lib-flow-main-search/src/constants'
import {
    getExactSearchQueryParam,
    TYPE_EXACT_AREA_SEARCH,
    TYPE_EXACT_DISTRICT_SEARCH,
    TYPE_EXACT_META_DISTRICT_SEARCH
} from '@/services/algoliaApi'
import SpaceTypeRestSvg from '@/components/iconSvg/SpaceTypeRestSvg.vue'
import SpaceTypeWorkSvg from '@/components/iconSvg/SpaceTypeWorkSvg.vue'
import SpaceTypePartySvg from '@/components/iconSvg/SpaceTypePartySvg.vue'
import SpaceTypeMeetSvg from '@/components/iconSvg/SpaceTypeMeetSvg.vue'
import SearchPeriodModal from '@/components/modal/SearchPeriodModal'
import dateServices from '@/services/dateServices'
import { isBefore8pm } from '@/lib-flow-main-search/src/utils'
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'MagicSearchSection',
    components: {
        SpaceTypeMeetSvg,
        SpaceTypePartySvg,
        SpaceTypeWorkSvg,
        SpaceTypeRestSvg,
        SwiperContainer,
        SearchPeriodModal
    },
    props: {
        services: {
            type: String,
            default: 'rest'
        }
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 5,
                slidesOffsetBefore: 14,
                slidesOffsetAfter: 14,
                breakpoints: {
                    660: {
                        spaceBetween: 10,
                        slidesOffsetBefore: 14,
                        slidesOffsetAfter: 14
                    },
                    1200: {
                        spaceBetween: 10,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0
                    }
                }
            },
            tempItem: undefined,
            isShowPeriodPrompt: false,
            selectedPeriods: [],
            btnPosition: 0
        }
    },
    computed: {
        magicButtons() {
            return this.$store.getters['api/magicButtonsData']
        },
        source() {
            return getGAEventName(this.landingPageName, 'magic button', '', this.btnPosition)
        }
    },
    watch: {
        globalIsSigned(newVal) {
            if (newVal !== null) {
                this.getMagicButtonDetails()
            }
        },
        services(newVal) {
            if (newVal) {
                this.getMagicButtonDetails()
            }
        }
    },
    mounted() {
        this.getMagicButtonDetails()
    },
    destroyed() {
        this.$store.dispatch('api/RESET_MAGIC_BUTTONS_DATA')
    },
    methods: {
        async getMagicButtonDetails() {
            try {
                const idToken = await this.getIdToken()
                if (idToken) {
                    await this.$store.dispatch('api/RESET_MAGIC_BUTTONS_DATA')
                    const obj = {
                        id_token: idToken,
                        lang: this.paramsLang,
                        region: this.paramsRegion,
                        space_type: this.services
                    }
                    await this.$store.dispatch('api/GET_MAGIC_BUTTON_RES', obj)
                }
            } catch (err) {
                this.$sentry.captureException(err)
            }
        },
        computeMagicButtonLink(item) {
            const spaceType = this.getGlobalSpaceTypeByNum(item.space_type)
            let query = {}
            if (item.date) {
                query.date = item.date
            }
            if (item.time) {
                query.time = item.time
            }
            if (item.search?.query) {
                query.q = item.search?.query
            }
            query.qs = QUERY_SOURCE.MAGIC_SEARCH_BUTTON
            if (item.filters) {
                for (const [key, value] of Object.entries(item.filters)) {
                    if (
                        (key === 'user_lat_long' ||
                            key === 'user_lat_long_optional') &&
                        value
                    ) {
                        // no-op
                    } else {
                        query[key] = value
                    }
                }
            }
            const exactSearchType = getExactSearchQueryParam(
                undefined,
                undefined,
                QUERY_SOURCE.MAGIC_SEARCH_BUTTON,
                item.search?.category
            )
            if (exactSearchType) {
                query.es_type = exactSearchType
            }
            const locationHierarchiesObj = this.getLocationHierarchiesObj(
                exactSearchType,
                item.search?.location_hierarchies
            )
            query = { ...query, ...locationHierarchiesObj }
            return {
                name: 'region-locale-space',
                params: {
                    space: spaceType,
                    source: this.source
                },
                query
            }
        },
        onButtonClicked(item, event, index) {
            this.btnPosition = index
            if (item.is_request_period) {
                this.$store.dispatch('general/UPDATE_PERIOD_MODAL_DATE', { date: item.date })
                this.$store.dispatch('general/UPDATE_PERIOD_MODAL_SERVICE_TYPE', { type: 'rest' })
                this.tempItem = item
                this.isShowPeriodPrompt = true
                event.preventDefault()
            }
            this.sendLogEventsItem(item.filters?.f_priceRange_max)
        },
        getGradColour(btn) {
            const gradColour = btn.grad_colour
            const initialColor = gradColour.split(',')[0]
            const finialColor = gradColour.split(',')[1]
            return `background: linear-gradient(180deg, ${initialColor} 0%, ${finialColor} 100%)`
        },
        getFontColour(btn) {
            return `color: ${btn.font_colour}`
        },
        getLocationHierarchiesObj(exactSearchType, locationHierarchies) {
            const obj = {}
            if (
                exactSearchType === TYPE_EXACT_AREA_SEARCH ||
                exactSearchType === TYPE_EXACT_DISTRICT_SEARCH ||
                exactSearchType === TYPE_EXACT_META_DISTRICT_SEARCH
            ) {
                if (locationHierarchies?.[0]) {
                    obj[locationHierarchies[0].field] =
                        locationHierarchies[0].value
                }
                if (
                    exactSearchType === TYPE_EXACT_AREA_SEARCH &&
                    locationHierarchies?.[1]
                ) {
                    obj[locationHierarchies[1].field] =
                        locationHierarchies[1].value
                }
            }
            return obj
        },
        continueWithPeriodSet(periods){
            this.isShowPeriodPrompt = false
            this.selectedPeriods = periods
            this.tempRouteObj = this.computeMagicButtonLink(this.tempItem)
            this.updateTimeQueryIfNeeded()
            this.$router.push(this.tempRouteObj)
        },
        updateTimeQueryIfNeeded() {
            if (this.selectedPeriods.length > 0) {
                this.tempRouteObj.query.time = this.selectedPeriods.join(',')
                const now = new Date().valueOf()
                const today = dateServices.getToday()
                if (
                    this.tempRouteObj.query.date === today &&
                    !isBefore8pm(now, today, this.paramsRegion) &&
                    this.selectedPeriods.includes(DATE_TIME.MIDNIGHT_TIME)
                ) {
                    this.tempRouteObj.query.date = dateServices.getTomorrow()
                }
            }
        },
        sendLogEventsItem(value) {
            analysis.sendMagicSearchButtonClicked(
                this.source,
                value
            )
        }
    }
}
</script>

<style scoped>
.magic-button-container {
    border-radius: 17px;
    height: 33px;
    display: flex;
    align-items: center;
    width: fit-content;
}
.button-container {
    position: absolute;
    left: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.space-type-icon-bg {
    background-color: #ffffff;
    border-radius: 50%;
    padding: 3.5px 5px;
}
.remove-space {
    display: flex;
    white-space: pre-wrap;
}
</style>

<template>
    <div>
        <div class="d-flex align-items-center w-100">
            <ImageContainer
                :src="iconUrl"
                :alt="altText"
                :height="iconHeight"
                :width="iconWidth"
            />
            <p class="text-15 text-center text-ffc000 ml-2">
                {{ title }}
            </p>
        </div>
        <div class="bottom-separator w-75" />
    </div>
</template>

<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'IconTitle',
    components: {ImageContainer},
    props: {
        iconUrl: {
            type: String,
            required: true
        },
        altText: {
            type: String,
            required: false,
            default: ''
        },
        iconHeight: {
            type: String,
            required: true
        },
        iconWidth: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.bottom-separator {
    height: 5px;
    background-color: #ffc000;
}
</style>

<template>
    <div v-if="mediaList.length > 0">
        <HeaderSection>
            <HeaderTitle>{{ $t('about.media_coverage') }}</HeaderTitle>
        </HeaderSection>
        <div class="content">
            <b-row class="d-flex justify-content-between w-100 m-0">
                <div
                    v-for="(image, index) in mediaList"
                    :key="index"
                    :class="region === 'sg' ? 'col-lg-2' : 'col-lg-3'"
                    class="col-sm-6 col-6 text-center align-self-center px-0"
                >
                    <div
                        v-if="image.name === 'vulcanPost14042022'"
                        class="mx-4 text-left"
                    >
                        14 Apr 2022
                    </div>
                    <div
                        v-if="image.name === 'vulcanPost21022022'"
                        class="mx-4 text-left"
                    >
                        21 Feb 2022
                    </div>
                    <ImageContainer
                        v-if="isDisabledLinks(image)"
                        :src="image.src"
                        :alt="image.name"
                        class="img-fluid"
                    />
                    <a
                        v-else
                        :key="index"
                        :href="image.url"
                        :aria-label="image.name"
                        @click="sendOutboundClickTrack(image.url)"
                        target="_blank"
                        rel="noreferrer noopener"
                        class="d-block h-100"
                    >
                        <ImageContainer
                            :src="image.src"
                            :alt="image.name"
                            class="img-fluid"
                        />
                    </a>
                </div>
            </b-row>
        </div>
    </div>
</template>
<script>
import HeaderSection from '@/components/home/HeaderSection'
import HeaderTitle from '@/components/home/HeaderTitle'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'MediaCoverage',
    components: {ImageContainer, HeaderTitle, HeaderSection },
    data() {
        return {
            mediaImg: {
                defaultMedia: [
                    {
                        name: 'insiderUrl',
                        url: '',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/5e751e7a-b00b-4f7f-feb3-f7f44161c300/public'
                    },
                    {
                        name: 'edigestUrl',
                        url:
                            'https://www.edigest.hk/%e5%89%b5%e6%a5%ad/%e5%89%b5%e6%a5%ad%e6%b7%98%e9%87%91-90%e5%be%8c-%e6%97%85%e5%ae%a2-%e6%87%89%e7%94%a8%e7%a8%8b%e5%bc%8f-app-%e9%85%92%e5%ba%97-%e7%81%a3%e4%bb%94-87647/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/cac030c8-c0c2-4c2a-b977-d17435212400/public'
                    },
                    {
                        name: 'jumpStartUrl',
                        url: '',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/64c80c02-5541-46c8-17a3-726d13137a00/public'
                    },
                    {
                        name: 'mingpaoUrl',
                        url:
                            'https://www.mpfinance.com/fin/daily2.php?node=1572807792351&issue=20191104',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f82a1ebe-de44-4877-af5b-250544184200/public'
                    },
                    {
                        name: 'unwireUrl',
                        url:
                            'https://unwire.pro/2019/11/28/flow-hotel/startups/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/4af15e6a-95be-42d2-caa9-3e60327dbb00/public'
                    },
                    {
                        name: 'hknetUrl',
                        url:
                            'https://inews.hket.com/article/2480710/%E3%80%90%E5%89%B5%E6%A5%AD%E5%85%B5%E5%9C%98%E3%80%91%E5%88%86%E6%99%82%E6%AE%B5%E8%A8%82%E9%85%92%E5%BA%97%E6%88%BF%E3%80%80%E9%A0%90%E7%B4%84%E5%B9%B3%E5%8F%B0%E4%B8%BB%E6%94%BB%E5%95%86%E5%8B%99%E5%AE%A2',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/45a81b10-d9fc-47ea-7285-92d09375ec00/public'
                    },
                    {
                        name: 'atHsbc',
                        url:
                            'https://www.visiongo.hsbc.com.hk/zh-HK/article/hotel-x-coworking-space-by-hours_flow',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/31262010-dd27-4e73-29b9-6c53bac3c200/public'
                    },
                    {
                        name: 'atStartupBeat',
                        url: 'https://startupbeat.hkej.com/?p=83167',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/6d51f5d3-278b-49fb-6dec-806a7445e900/public'
                    }
                ],
                vn: [
                    {
                        name: 'vnMedia',
                        url:
                            'https://vnmedia.vn/cong-nghe/202206/ung-dung-cho-phep-dat-khach-san-sang-trong-theo-gio-chinh-thuc-ra-mat-tai-viet-nam-04e65c5/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/d2c6e5e2-8e65-4518-b6d5-a5e13833bb00/public'
                    },
                    {
                        name: 'congThuong',
                        url:
                            'https://congthuong.vn/dat-khach-san-theo-gio-co-giup-thi-truong-khach-san-hoi-sinh-180435.html',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b63015fa-fa20-4c4f-b382-7c4d6285f000/public'
                    },
                    {
                        name: 'doanhNhanPlus',
                        url:
                            'https://doanhnhanplus.vn/flow-ung-dung-cho-phep-dat-khach-san-sang-trong-theo-gio-chinh-thuc-ra-mat-598394.html',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/2e0fc352-f6f8-4fd4-aa5d-84ee9f0a9c00/public'
                    },
                    {
                        name: 'her',
                        url:
                            'https://her.vn/article/flow-ung-dung-cho-phep-dat-khach-san-sang-trong-theo-gio',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/6f264600-4db6-40d1-f913-d241405c5900/public'
                    },
                    {
                        name: 'thiTruong365',
                        url:
                            'https://thitruong365.com/ra-mat-ung-dung-flow-dat-phong-khach-san-theo-gio',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/93d9efc2-2548-451d-61a1-9c846d5f0500/public'
                    },
                    {
                        name: 'pose',
                        url:
                            'https://pose.vn/sanh/flow-ung-dung-cho-phep-dat-khach-san-sang-trong-theo-gio-ra-mat-tai-viet-nam-1089422.html',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/883e6b82-193a-43da-bd85-6db956dd1c00/public'
                    },
                    {
                        name: 'theSaigonTimes',
                        url:
                            'https://english.thesaigontimes.vn/pr-newswire/?rkey=20220612AE84475&filter=23297',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/0a07b82c-4da0-4fb7-ea55-ed5296a15800/public'
                    }
                ],
                sg: [
                    {
                        name: 'asiaOne',
                        url:
                            'https://www.asiaone.com/business/hong-kong-tech-startup-flow-raises-over-us1-million-series-pre-funding-accelerate-0',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/baf8f8a9-e21c-4841-da93-9d007cb43000/public'
                    },
                    {
                        name: 'straitsTimes',
                        url:
                            'https://www.nst.com.my/lifestyle/jom/2022/02/770053/jom-stay-go-flow%C2%A0',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f03d8acd-d617-4a53-83d6-0e8620b85500/public'
                    },
                    {
                        name: 'techTrp',
                        url:
                            'https://techtrp.com/reviews/2022/03/18/flow-for-when-you-need-a-break-or-a-quick-escape-review/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3c555c60-8c68-45c7-4089-8e4a0785a200/public'
                    },
                    {
                        name: 'hype',
                        url:
                            'https://hype.my/2022/263410/daycations-hotel-rooms-flow-app/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/1bbf828e-4694-4751-31c3-b433b108d300/public'
                    },
                    {
                        name: 'hsbc',
                        url:
                            'https://www.visiongo.hsbc.com.hk/zh-HK/article/hotel-x-coworking-space-by-hours_flow',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/40fd90b1-6536-40f5-e2dd-b3409dcca600/public'
                    }
                ],
                my: [
                    {
                        name: 'glitz',
                        url:
                            'https://glitz.beautyinsider.my/malaysian-can-now-enjoy-a-daycation-with-flow-app/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3749af9e-062c-4bbc-1515-a3796a6db600/public'
                    },
                    {
                        name: 'vulcanPost14042022',
                        url:
                            'https://vulcanpost.com/785024/review-flow-app-book-hotel-room-malaysia-intraday-workcation/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/a379dab2-1e1c-4af2-054a-95a512242f00/public'
                    },
                    {
                        name: 'vulcanPost21022022',
                        url:
                            'https://vulcanpost.com/778535/flow-hourly-hotel-booking-app-kuala-lumpur-daycations-remote-work/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/a379dab2-1e1c-4af2-054a-95a512242f00/public'
                    },
                    {
                        name: 'top10Lifestyles',
                        url:
                            'https://www.top10lifestyles.com/flow-app-launches-in-malaysia-it-is-time-to-live-the-new-normal-with-daycation-at-the-best-hotels-in-town/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/fb3ac617-01d5-483f-003a-94f3ff2de600/public'
                    },
                    {
                        name: 'aseanFoodTravel',
                        url:
                            'https://aseanfoodtravel.com/2022/03/10/flow-app-launches-in-malaysia-it-is-time-to-live-the-new-normal-with-daycation-at-the-best-hotels-in-town/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c2748add-6bd1-42fe-63fa-240d6897ee00/public'
                    },
                    {
                        name: 'techTrp',
                        url:
                            'https://techtrp.com/reviews/2022/03/18/flow-for-when-you-need-a-break-or-a-quick-escape-review/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3c555c60-8c68-45c7-4089-8e4a0785a200/public'
                    },
                    {
                        name: 'hype',
                        url:
                            'https://hype.my/2022/263410/daycations-hotel-rooms-flow-app/',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/e0909a7f-dd38-4169-f7cb-402a1056a600/public'
                    },
                    {
                        name: 'straitsTimes',
                        url:
                            'https://www.nst.com.my/lifestyle/jom/2022/02/770053/jom-stay-go-flow%C2%A0',
                        src:
                            'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/f03d8acd-d617-4a53-83d6-0e8620b85500/public'
                    }
                ]
            }
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        mediaList() {
            switch (this.region) {
                case 'vn':
                case 'sg':
                case 'my':
                    return this.mediaImg[this.region]
                default:
                    return this.mediaImg.defaultMedia
            }
        }
    },
    methods: {
        sendOutboundClickTrack(url) {
            this.$gtag('event', 'click', {
                event_category: 'outbound',
                event_label: url,
                transport_type: 'beacon'
            })
        },
        isDisabledLinks(image) {
            return (
                image.name === 'theSaigonTimes' ||
                image.name === 'her' ||
                image.name === 'insiderUrl'
            )
        }
    }
}
</script>
<style scoped>
.content {
    max-width: 1200px;
    margin: 0 auto;
}
</style>

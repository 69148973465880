<template>
    <div
        class="text-mid border-0 bg-primary position-absolute icon-padding"
        style="color: white; border-radius: 50%; font-weight: 600; width: 18px; height: 18px; font-size: 11px;"
    >
        <slot />
    </div>
</template>
<script>
export default {
    name: 'NumberIcon'
}
</script>
<style scoped>
.icon-padding {
    padding: 1px;
}
@media (max-width: 992px) {
    .icon-padding {
        padding: 1px 1px 1px 5px;
    }
}
</style>

<template>
    <div @click="getCurrentLocation">
        <ImageContainer
            :src="homeMap.desktop"
            alt="desktop nearby map"
            class="d-none d-lg-block w-100 map"
            height="233"
            width="1300"
            style="min-height: 180px;"
        />
        <ImageContainer
            :src="homeMap.mobile"
            alt="mobile map icon"
            class="d-lg-none d-block w-100 map"
            height="280"
            width="658"
            style="height: 180px;"
        />
    </div>
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'PlaceNearby',
    components: {ImageContainer},
    props: {
        homeMap: {
            type: Object,
            default: undefined
        }
    },
    data() {
        return {
            mapLocation: {
                latitude: '',
                longitude: ''
            },
            loadingCoordinate: false
        }
    },
    methods: {
        getCurrentLocation() {
            this.$emit('getCurrentLocation')
        }
    }
}
</script>
<style scoped>
.map {
    cursor: pointer;
    border-radius: 8px;
    object-fit: cover;
}
</style>

<template>
    <svg width="18" height="18" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1287_1094)">
            <path d="M8.46 16.92C13.1323 16.92 16.92 13.1323 16.92 8.46C16.92 3.78767 13.1323 0 8.46 0C3.78767 0 0 3.78767 0 8.46C0 13.1323 3.78767 16.92 8.46 16.92Z" fill="#765821"/>
            <path d="M4.31006 8.45995H8.46006V2.94995" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1287_1094">
                <rect width="16.93" height="16.93" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'SessionSvg'
}
</script>

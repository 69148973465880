var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex row m-0"},[_vm._l((_vm.links),function(link,index){return _c('div',{key:index,staticClass:"px-0 pr-md-3 col-md-4"},[_c('NuxtLink',{class:index < 8
                        ? 'd-block'
                        : _vm.isHideSpace
                        ? 'd-none d-lg-block'
                        : '',staticStyle:{"border-radius":"10px","cursor":"pointer"},attrs:{"to":_vm.computeLink(link.slug, _vm.space, _vm.keyword)}},[_c('TextButton',{staticClass:"text-14 mr-2 mb-2 text-1e1e1e internal-link-btn"},[_c('div',[_vm._v("\n                        "+_vm._s(_vm.getHeader('nearby_location', link.name, true))+"\n                    ")])])],1)],1)}),_vm._v(" "),(_vm.isDisplayBtn)?_c('div',{staticClass:"mt-3 d-lg-none"},[_c('TextButton',{staticClass:"text-14"},[_c('div',{on:{"click":_vm.toggleText}},[_vm._v("\n                    "+_vm._s(_vm.isHideSpace
                            ? _vm.$t('details.see_all')
                            : _vm.$t('details.hide'))+"\n                ")])])],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <client-only>
        <div>
            <NuxtLink
                :to="computeLinkToBookingsOrSpaceDetails()"
                class="d-flex justify-content-between align-items-center p-3 upcoming-container"
            >
                <div class="d-flex">
                    <div class="d-none d-lg-block">
                        <ImageContainer
                            :src="upcomingBookings.space.thumbnail"
                            :alt="upcomingBookings.space.space_name"
                            class="d-md-none d-lg-block d-sm-none d-md-block ml-2 mr-5 space-img"
                            height="159"
                            style="border-radius: 14px; aspect-ratio: 1200/800;"
                        />
                    </div>
                    <div class="d-flex flex-column justify-content-around pl-2">
                        <div class="text-primary text-15 font-weight-bold mb-2">
                            {{ upcomingBookings.space.space_type_string }}
                        </div>
                        <div class="text-17 font-weight-bold">
                            {{ upcomingBookings.space.space_name }}
                        </div>
                        <div class="d-flex text-14 flex-wrap text-9d9d9d">
                            <div>{{ upcomingBookings.booking.date }}</div>
                            <div class="mx-2">|</div>
                            <div>{{ upcomingBookings.booking.time }}</div>
                        </div>
                        <div class="d-flex text-14 text-9d9d9d">
                            <div class="fas fa-map-marker-alt mr-2 mt-1"></div>
                            <div>{{ upcomingBookings.location.area }}</div>
                        </div>
                        <div class="d-flex font-weight-bold text-17">
                            {{
                                getDisplayPrice(
                                    upcomingBookings.booking.charge_price,
                                    upcomingBookings.location.region
                                )
                            }}
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column mt-3">
                    <div class="d-flex justify-content-end">
                        <div
                            v-if="upcomingBookings.to_now"
                            class="text-17 text-mid border-1 to-now px-4"
                            style="color: white; border-radius: 20px 0 0 20px; background-color: #F2BC1E; font-weight: 600"
                        >
                            {{ upcomingBookings.to_now }}
                        </div>
                    </div>
                    <div
                        v-if="upcomingBookings.booking_id"
                        class="text-18 text-primary text-center px-3 py-2"
                        style="background-color: white; border-radius: 5px; font-weight: 600"
                    >
                        {{ upcomingBookings.booking_id }}
                    </div>
                </div>
            </NuxtLink>
        </div>
    </client-only>
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue"
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'UpcomingBookings',
    components: {ImageContainer},
    props: {
        upcomingBookings: {
            type: Object,
            default: undefined
        }
    },
    computed: {
        isSigned() {
            return this.$store.getters['member/isSigned']
        },
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        }
    },
    methods: {
        computeLinkToBookingsOrSpaceDetails() {
            const source = getGAEventName(this.landingPageName, 'upcoming booking')
            if (this.isSigned) {
                return {
                    name: 'region-locale-user-past-bookings',
                    params: {
                        source
                    }
                }
            } else {
                const space = this.globalSpace(
                    this.upcomingBookings.space.space_type
                )
                return {
                    name: 'region-locale-spaceName-space',
                    params: {
                        spaceName: this.upcomingBookings.space.slug,
                        space,
                        source
                    }
                }
            }
        }
    }
}
</script>
<style scoped>
.upcoming-container {
    background-color: #f7f7f6;
    border-radius: 14px;
    cursor: pointer;
    text-decoration: none;
}
.space-img {
    object-fit: cover;
}
.to-now {
    padding: 10px 34px;
}
/*.confirmation-code {*/
/*    margin: 30px 30px 0px 0px;*/
/*    padding: 10px 20px;*/
/*}*/

@media (max-width: 900px) {
    .to-now {
        padding: 7px 14px;
    }
    /*.confirmation-code {*/
    /*    padding: 9px 18px;*/
    /*}*/
}
</style>

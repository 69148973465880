<template>
    <div :key="deal.toString()">
        <div class="d-block d-lg-none">
            <div class="row w-100 m-auto">
                <div
                    v-for="(listItem, index) in mobileUncollapsedDeals"
                    :key="index"
                    :class="
                        index % 2 === 0 ? 'deals-card-left' : 'deals-card-right'
                    "
                    class="card-item col-6 d-flex flex-column justify-content-between"
                >
                    <div class="my-2 px-sm-2 h-100">
                        <HomeSpaceCard
                            :list-item="listItem"
                            :space-type="spaceType"
                            :price-field="priceField"
                            :date="date"
                            :deals-btn-title="dealsBtnTitle"
                            :type="'mobile'"
                            :itemIdx="index"
                            :tag="tag"
                            @navigateToDetails="navigateToDetails"
                        />
                    </div>
                </div>
                <b-collapse :id="`expand-${name}`" class="row w-100 m-auto">
                    <div
                        v-for="(listItem, index) in mobileCollapsedDeals"
                        :key="index"
                        :class="
                            index % 2 === 0
                                ? 'deals-card-left'
                                : 'deals-card-right'
                        "
                        class="card-item col-6 d-flex flex-column justify-content-between"
                    >
                        <div class="my-2 px-sm-2 h-100">
                            <HomeSpaceCard
                                :list-item="listItem"
                                :space-type="spaceType"
                                :price-field="priceField"
                                :date="date"
                                :deals-btn-title="dealsBtnTitle"
                                :type="'mobile'"
                                :itemIdx="index"
                                :tag="tag"
                                @navigateToDetails="navigateToDetails"
                            />
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <div class="d-none d-lg-block">
            <div class="row w-100 m-auto">
                <div
                    v-for="(listItem, index) in sortedDeals"
                    :key="index"
                    class="card-item col-6 col-xl-4 d-flex flex-column align-items-center mt-2 mb-4 px-4"
                >
                    <div class="button-active h-100 w-100">
                        <HomeSpaceCard
                            :list-item="listItem"
                            :space-type="spaceType"
                            :price-field="priceField"
                            :date="date"
                            :deals-btn-title="dealsBtnTitle"
                            :type="'desktop'"
                            :itemIdx="index"
                            :tag="tag"
                            @navigateToDetails="navigateToDetails"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="!isHideBtn">
            <div
                class="d-block d-lg-none d-flex flex-column justify-content-center"
            >
                <b-btn
                    v-b-toggle="`expand-${name}`"
                    aria-label="Expand to see more"
                    variant="transparent"
                    @click="handleBtnClick"
                >
                    <ExpandMore />
                </b-btn>
                <NuxtLink
                    :to="searchDealsPath()"
                    aria-label="search your daycation"
                    class="text-decoration-none"
                >
                    <BookButton
                        :book-button-text="$t('home.search_your_daycation')"
                        :button-class="'btn-text-grey'"
                        class="my-2 text-15 w-100"
                /></NuxtLink>
            </div>
        </div>
    </div>
</template>

<script>
import ExpandMore from '@/components/iconSvg/ExpandMore.vue'
import HomeSpaceCard from '@/components/home/HomeSpaceCard.vue'
import BookButton from '@/components/item/BookButton.vue'
import analytics from '@/services/analytics'
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'Deals',
    components: { BookButton, HomeSpaceCard, ExpandMore },
    props: {
        spaceType: {
            type: String,
            default: ''
        },
        deal: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        name: {
            type: String,
            default: 'deal'
        },
        priceField: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        dealsBtnTitle: {
            type: String,
            default: ''
        },
        tag: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isExpandCard: false
        }
    },
    computed: {
        sortedDeals() {
            /* Move Overnight deals to end */
            const headArr = []
            const tailArr = []
            this.deal.map((item) => {
                const overnightDay2 =
                    item.status?.min_prices?.perBookType?.overnight?.day2?.total
                const minPricesDay2 = item.status?.min_prices?.day2?.total
                if (minPricesDay2 === overnightDay2) {
                    tailArr.push(item)
                } else {
                    headArr.push(item)
                }
            })
            return [...headArr, ...tailArr].slice(0, 6)
        },
        mobileUncollapsedDeals() {
            return this.sortedDeals.slice(0, 4)
        },
        mobileCollapsedDeals() {
            return this.sortedDeals.slice(4, 8)
        },
        isHideBtn() {
            return this.sortedDeals.length < 5 || this.isExpandCard
        }
    },
    methods: {
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        },
        handleBtnClick() {
            this.isExpandCard = true
        },
        searchDealsPath() {
            const query = {
                date: this.date,
                time: 'any_time',
                f_theme: this.tag
            }
            const source = getGAEventName(this.landingPageName, this.tag, 'SEARCH_YOUR_DAYCATION_BUTTON')
            return {
                name: 'region-locale-space',
                params: {
                    space: 'hotel',
                    source
                },
                query
            }
        }
    }
}
</script>

<style scoped>
@media (max-width: 576px) {
    .deals-card-left {
        padding-left: 0;
        padding-right: 5px;
    }
    .deals-card-right {
        padding-right: 0;
        padding-left: 5px;
    }
}
</style>

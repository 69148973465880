<template>
    <SwiperContainer
        v-if="posts.length > 0"
        :option="swiperOption"
        :name="'blog'"
    >
        <div
            v-for="(item, index) in posts"
            :key="index"
            class="swiper-slide card-item mt-2"
        >
            <NuxtLink
                :to="computePathToBlogPost(item.slug, index)"
                style="text-decoration: none"
            >
                <ImageContainer
                    :src="item.thumbnail.medium_large"
                    :alt="item.title"
                    class="list-item-img w-100"
                />
                <p class="text-mid text-9d9d9d text-15 mt-2">
                    {{
                        getDate(item.published_at) +
                        ' | ' +
                        item?.author?.primary?.name
                    }}
                </p>
                <p class="text-s-bold text-3c3c3c text-16">
                    {{ item.title }}
                </p>
            </NuxtLink>
        </div>
    </SwiperContainer>
</template>

<script>
import dateServices from '@/services/dateServices'
import SwiperContainer from '~/components/ui/SwiperContainer'
import wpBlogApi, { getIdBySlug } from '@/services/wpBlogApi'
import ImageContainer from "@/components/ui/ImageContainer.vue";
import {getGAEventName} from "@/utils/analysis";
export default {
    name: 'Blogs',
    components: {ImageContainer, SwiperContainer },
    props: {
        option: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            swiperOption: {},
            defaultOption: {
                slidesPerView: 1.59,
                spaceBetween: 10,
                breakpoints: {
                    1150: {
                        slidesPerView: 3.9032,
                        spaceBetween: 10
                    },
                    1000: {
                        slidesPerView: 3.3,
                        spaceBetween: 10
                    },
                    800: {
                        slidesPerView: 2.9,
                        spaceBetween: 10
                    },
                    680: {
                        slidesPerView: 2.3,
                        spaceBetween: 10
                    },
                    579.98: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    async fetch() {
        if (this.posts?.length <= 0) {
            await this.setBlogPosts('flowwebstatic')
        }
    },
    created() {
        this.setSwiperOption()
    },
    computed:{
        posts() {
            return this.$store.getters['api/CMSPosts']
        }
    },
    watch: {
        globalIsSigned(newVal) {
            if (newVal !== null) {
                this.setBlogPosts()
            }
        }
    },
    mounted() {
        this.setBlogPosts()
    },
    methods: {
        async setBlogPosts(idToken) {
            try {
                if (!idToken){
                    idToken = await this.getIdToken()
                }
                if (idToken) {
                    const params = {
                        id_token: idToken,
                        type: 'get_inspired',
                        lang: this.paramsLang,
                        region: this.paramsRegion
                    }
                    await this.$store.dispatch('api/GET_CMS_POSTS', params)
                }
            } catch (err) {
                this.$sentry.captureException(err)
            }
        },
        getDate(date) {
            return dateServices.getDateWithYearByFormat(new Date(date))
        },
        computePathToBlogPost(id, position) {
            const source = getGAEventName(this.landingPageName, 'BLOG', id, position)
            return {
                name: 'region-locale-blog-id',
                params: {
                    source,
                    id
                }
            }
        },
        setSwiperOption() {
            this.swiperOption = { ...this.defaultOption, ...this.option }
        }
    }
}
</script>

<style scoped>
.list-item-img {
    border-radius: 10px;
    object-fit: cover;
}
.swiper-slide {
    margin-right: 10px;
}
@media (max-width: 414px) {
    .list-item-img {
        height: 140px;
    }
}
@media (min-width: 415px) {
    .list-item-img {
        height: 187px;
    }
}
</style>

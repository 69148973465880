function getPricePerRewardPoint(region) {
    let price = []
    switch (region) {
        case 'hk':
            price = 'HKD50'
            break
        case 'mo':
            price = 'MOP$50'
            break
        case 'sg':
            price = 'S$8.5'
            break
        case 'vn':
            price = '30.000đ'
            break
        case 'my':
            price = 'RM20'
            break
        default:
            price = '$50'
    }
    return price
}

function getInviteFriendPriceReward(region) {
    let price = []
    switch (region) {
        case 'hk':
            price = 'HKD30'
            break
        case 'mo':
            price = 'MOP$30'
            break
        case 'sg':
            price = 'S$5'
            break
        case 'vn':
            price = '30.000đ'
            break
        case 'my':
            price = 'RM12'
            break
        default:
            price = '$30'
    }
    return price
}

function getFirstBookingPriceReward(region) {
    let price = []
    switch (region) {
        case 'hk':
            price = 'HKD25'
            break
        case 'mo':
            price = 'MOP$25'
            break
        case 'sg':
            price = 'S$4'
            break
        case 'vn':
            price = '60.000đ'
            break
        case 'my':
            price = 'RM10'
            break
        default:
            price = '$25'
    }
    return price
}

function getCreateAccountReward(region) {
    let price = []
    switch (region) {
        case 'hk':
            price = 'HKD25'
            break
        case 'mo':
            price = 'MOP$25'
            break
        case 'sg':
            price = 'S$4'
            break
        case 'vn':
            price = '60.000đ'
            break
        case 'my':
            price = 'RM10'
            break
        default:
            price = '$25'
    }
    return price
}

function getCurrencyUnit(region, lang) {
    const currentLang = lang + '-' + region
    let unit = ''
    switch (currentLang) {
        case 'zh-hk':
            unit = '港幣 $1'
            break
        case 'en-hk':
            unit = '1 Hong Kong Dollar'
            break
        case 'zh-mo':
            unit = '葡幣 $1'
            break
        case 'en-mo':
            unit = '1 Macau Pataca'
            break
        case 'zh-my':
            unit = '1 令吉'
            break
        case 'en-my':
        case 'ms-my':
            unit = '1 Ringgit'
            break
        case 'en-sg':
            unit = '1 Singapore Dollar'
            break
        case 'zh-sg':
            unit = '1 新币'
            break
        default:
            unit = '1 Hong Kong Dollar'
    }
    return unit
}
function getTopUpPriceString(lang, flowDollarPackage) {
    let stringList = ''
    flowDollarPackage.map((item, index) => {
        if (
            lang === 'zh' &&
            index !== 0 &&
            index !== flowDollarPackage.length - 1
        ) {
            stringList += '、'
        } else if (lang !== 'zh' && index !== 0) {
            stringList += ', '
        }
        if (index === flowDollarPackage.length - 1) {
            switch (lang) {
                case 'zh':
                    stringList += '及 '
                    break
                case 'en':
                    stringList += 'and '
                    break
                case 'ms':
                    stringList += 'dan '
                    break
                default:
                    stringList += 'and '
            }
        }
        stringList += item.currency + item.charge_price
    })
    return stringList
}

export default {
    getPricePerRewardPoint,
    getInviteFriendPriceReward,
    getFirstBookingPriceReward,
    getCreateAccountReward,
    getCurrencyUnit,
    getTopUpPriceString
}

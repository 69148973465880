<template>
    <div>
        <NuxtLink
            :to="computePathToLocation"
            class="position-relative text-decoration-none"
        >
            <div :class="isFirstCard ? 'first-card' : 'non-first-card'">
                <ImageContainer
                    :src="image"
                    :alt="name"
                    class="list-item-img w-100 h-100"
                />
                <div
                    class="text-white text-s-bold text-15 position-absolute mx-3 my-2"
                    style="top: 0; left: 0; white-space: nowrap;"
                >
                    {{ name }}
                </div>
            </div>
        </NuxtLink>
    </div>
</template>

<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";
import analytics from "@/services/analytics"
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'LocationCard',
    components: {ImageContainer},
    props: {
        isFirstCard: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        slug: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: 0
        },
        source: {
            type: String,
            default: 'homepage'
        }
    },
    computed: {
        computePathToLocation() {
            const source = getGAEventName(this.landingPageName, 'POPULAR_DESTINATIONS', this.slug, this.index)
            return {
                name: 'region-locale-space-location-keyword',
                params: {
                    space: 'hotel',
                    source,
                    location: this.slug,
                    keyword: 'maps',
                    position: this.index + 1,
                    destinations: this.name
                }
            }
        }
    }
}
</script>

<style scoped>
.first-card {
    height: 230px;
}
.non-first-card {
    height: 110px;
}
.list-item-img {
    border-radius: 8px;
    object-fit: cover;
}
</style>

<template>
    <b-button
        @click="onButtonClicked"
        class="container bg-1e1e1e dark-button-active text-uppercase"
        variant="transparent"
    >
        <div class="d-flex justify-content-center align-items-center text-mid">
            <p class="text-16 ml-3 mr-2 text-light text-s-bold">
                {{ groupDisplayText + regionName() }}
            </p>
            <ChevronRightSvg class="chevron d-none d-lg-block" />
            <ChevronDownSvg class="chevron d-block d-lg-none" />
        </div>
    </b-button>
</template>

<script>
import _filter from 'lodash/filter'
import ChevronRightSvg from '@/components/iconSvg/ChevronRightSvg'
import ChevronDownSvg from '@/components/iconSvg/ChevronDownSvg'
import { MODAL_MODE } from '@/store/general'

export default {
    name: 'ChangeRegionButton',
    components: {
        ChevronDownSvg,
        ChevronRightSvg
    },
    computed: {
        currentGroupText() {
            const selectedRegionObj = this.currentRegionObj
            if (selectedRegionObj && selectedRegionObj.groups?.length > 0) {
                const currentArea = _filter(selectedRegionObj.groups, [
                    'slug',
                    this.paramsGroup
                ])
                return currentArea?.[0]?.name ?? undefined
            } else {
                return undefined
            }
        },
        groupDisplayText() {
            if (this.currentGroupText) {
                return `${this.currentGroupText}, `
            }
            return ''
        }
    },
    methods: {
        onButtonClicked() {
            this.$store.dispatch('general/SET_LOCALE_MODAL_VISIBLE', {
                isVisible: true,
                mode: MODAL_MODE.GROUP
            })
        }
    }
}
</script>

<style scoped>
.container {
    border-radius: 22px;
    border: 1px solid white;
}
.chevron {
    fill: #ffffff;
}
</style>
